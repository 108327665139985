@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

.clearfix{
	@include clearfix;
}
/* link
------------------------------------------------------------*/
a { outline: none; color: $baseLinkColor; text-decoration: underline;
	&:hover { text-decoration: none; color: $baseLinkColor;
}
}

/* Fonts
------------------------------------------------------------*/
html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word; 
}
body>div,input,button,textarea,select
{
	@include fz;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
}

/* form
------------------------------------------------------------*/
input::placeholder {
  color: #ccc;
}

/* IE */
input:-ms-input-placeholder {
  color: #ccc;
}

/* Edge */
input::-ms-input-placeholder {
  color: #ccc;
}
.inputTxt,textarea,select {
	padding: 3px 10px; border: 1px solid #CCC; border-radius:5px; @include box-sizing;
	@include tabletP {
		border-radius:3px;
		padding: 3px 5px;
	}

}

.inputTxt,textarea {
	box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.2) inset;
	background-color:#fafafa;
	width:100%;
	-webkit-appearance: none;
	vertical-align:top;
	
	&.error{
		background-color:#FFF8F9;
		border-color:$mainColorR;
		border-width:2px;
	}

}

.inputTxt{
	max-width:400px;
	height:44px;
	line-height:44px;
	
	@include tabletP {
		height:33px;
		line-height:33px;
	}
	
	&-small{
	}
	&-large{
	}
}
select{
	box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.2) inset;
	background-color:#F9F9F9;
	@include fz(14);
	/*
	padding: 8px 15px;
	@include tabletP {
		width:100%;
	}
	*/
}
input[type="radio"] , input[type="checkbox"]{
   -webkit-transform: scale(1.5);
   transform: scale(1.5);
   margin-right: 0.5em;
}
