@charset "utf-8";

/* modForm
------------------------------------------------------------*/

.modFormBlock {
	border:15px solid $mainColorG;
	border-radius:10px;
	padding:30px;

	@include sphoneP{
		border:10px solid $mainColorG;
		padding:15px;
	}
	
	.modTitle01 {
		margin-bottom:0;
	}
}

.modFormErr{
	margin:5px 0 0;
	@include fz(14);
	color:$mainColorR;
	font-weight:bold;
	@include tabletP {
		@include fz(13);
	}
}

.modFormErrTop {
	margin-bottom: 30px;
	border: 3px solid $mainColorR;
	border-radius: 10px;
	.title {
		display: block;
		background: $mainColorR;
		font-weight: bold;
		@include fz(16);
		color: #fff;
		text-align: center;
		&:before {
			content: "\f071";
			display: inline-block;
			font-family: FontAwesome;
			margin-right: 5px;
			@include fz(20);
		}
	}
	.list {
		padding: 10px 20px;
		a {
			&:before {
				content: "\f0da";
				display: inline-block;
				font-family: FontAwesome;
				margin-right: 5px;
				@include fz(16);
			}
		}
	}
}

.modFormRequired{
	display: inline-block;
	background-color:$mainColorR;
	border-radius:2px;
	padding:0 8px;
	margin:0 0 0 8px;
	color:#fff;
	@include fz(10);
	vertical-align:middle;
	@include tabletP{
		padding:1px 8px;
		@include inline-block;
	}
}

.modFormName{
	@include clearfix;
	
	li{
		float:left;
		width:40%;
		max-width:210px;
		margin:0 20px 0 0;
		@include tabletP{
			width:48%;
			min-width:160px;
			margin:0 4% 0 0;
			
			&:last-child{
				margin:0;
			}
		}
	}
	.input{
		@include tabletP{
		}	
	}
	.txt{
		width:2em;
		margin:0 10px 0 0;
		height:44px;
		line-height:44px;
		
		@include tabletP{
			min-width:0;
			margin:0 10px 10px 0;
			height:33px;
			line-height:33px;
			clear:both;
		}
	}
}
.modFormDay{
	@include clearfix;
	
	li{
		float:left;
		margin:0 10px 0 0;
		@include tabletP{
			margin:0 1% 0 0;
			
			&:last-child{
				margin:0;
			}
		}
		selest {
			width:100%;
		}
	}
	.input{
		@include tabletP{
		}	
	}
	.title{
		width:4em;
		margin:0 10px 0 0;
		height:44px;
		line-height:44px;
		font-weight:bold;
		
		@include tabletP{
			min-width:0;
			margin:0 10px 10px 0;
			height:33px;
			line-height:33px;
			clear:both;
		}
		@include sphoneP{
			float:none;
			height:auto;
			line-height:1;
			margin-bottom:5px;
	
		}
	}
	.txt{
		margin:0 10px 0 0;
		height:44px;
		line-height:44px;
		
		@include tabletP{
			margin:0 5px 10px 0;
			height:33px;
			line-height:33px;
		}
	}
}

.modFormChk {
	@include clearfix;
	li {
		display:inline-block;
		margin-right:15px;
		input {
			margin-right:5px;
		}
		@include tabletP{
			display:block;
			margin: 3px 0;
		}
	}
	&_block {
		li {
			display: block;
			&:not(:last-child){
				margin-bottom: 5px;
			}
		}
	}
}

.modFormZip{
	@include clearfix;
	
	li{
		float:left;
	}
	.input{
		width:20%;
		@include tabletP{
			width:25%;
		}
	}
	.txt{
		padding:0.5em 8px 0;
		width:1em;
		text-align:center;
		@include tabletP{
			padding-right:4px;
			padding-left:4px;
		}
	}
	.btn{
		width:20%;
		padding:0 0 0 10px;
		@include tabletP{
			width:35%;
		}
	}
}

.modFormAddr{
	li{
		margin-bottom:12px;
		height:44px;
		line-height:44px;
		@include tabletP{
			height:33px;
			line-height:33px;
		}
	}
	
	.zip{
		span{
			margin:0 10px 0 0;
		}
		input{
			max-width:120px;
		}
	}
}

.modFormTel{
	@include clearfix;
	
	li{
		float:left;
	}
	.input{
		width:20%;
		max-width:100px;
		@include tabletP{
			width:27%;
		}
	}
	.txt{
		padding:0 4px 0;
		width:1em;
		height:44px;
		line-height:44px;
		text-align:center;
		@include tabletP{
			height:33px;
			line-height:33px;
			padding-left:4px;
			padding-right:4px;
		}
	}
}

.modFormPolicy{
	margin-bottom:40px;
	padding:0 0 0 32px;
	
	a{
		color:#333;	
	}

	input{
		margin:0 5px 0 0;
		display:none;
	}
	label{
		position:relative;
		
		&:before{
			content:"";
			position:absolute;
			top:0;
			left:-32px;
			margin:auto;
			background-color:#F9F9F9;
			border:solid 1px #CCCCCC;
			width:20px;
			height:20px;
			border-radius:2px;
			vertical-align:middle;
		}
	}
	input:checked + label {
		&:before{
		}
		&:after{
			content: "\f00c";
			font-family: FontAwesome;
			color:$mainColorR;			
			position:absolute;
			left:-31px;
			top:0;
			bottom:0;
			margin: 0 auto;
			width:20px;
			height:20px;
			text-align:center;
			line-height:20px;
			@include fz(20);
		}
	}

}

.modFormTxtComp{
	margin-bottom:14px;
	@include fz(20);
	font-weight:bold;
	color:$mainColorR;
	@include tabletP{
		@include fz(18);
	}
}

.modFormTxtSub{
	margin:5px 0 0;
	color:#666;
	@include fz(12);
}
.modFormCheckList {
    margin: 20px 0;
    @include fz(14);
    .txt {
        font-weight: bold;
        margin-bottom: 10px;
        @include fz(14);
    }
    .list {
        @include fz(14);
        border-top: 1px solid #eee;
        li {
            padding: 15px 0 15px 30px;
            border-bottom: 1px solid #eee;
            input {
                margin-left: -25px;
            }
        }
    }
}
.modFormCopyBtn {
    margin: 15px 0 0px;
    padding-bottom: 15px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    color: #999;
    span {
        display: inline-block;
        width: 350px;
        margin-right: 10px;
        @include sphoneP{
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
}
.modFormSame {
    &_check {
        border: 1px solid #EAEAEA;
        background: rgba($mainColor,0.1);
        margin: 15px 0 0;
        @include fz(16);
        font-weight: bold;
        label {
            display: block;
            padding: 10px 15px;
            input {
                margin-right: 10px;
            }
        }
    }
    &_tbl {
        
    }
}
.mark_chusha {
	.img {
		text-align: center;
		margin-bottom: 30px;
	}
	.detail {
	}
	.selectList {
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
        @include sphoneP{
        	display: block;
        	.label {
        		width: 6em;
        		display: inline-block;
        	}
        	.list {
        		margin-bottom: 10px;
        		&:not(:last-child){
        			padding-bottom: 10px;
        			border-bottom: 1px dotted #ddd;
        		}
        	}
        }			
	}
}