@charset "utf-8";
/* CSS Document */

.container {
	width:auto;
	max-width:$baseWidth;
	
	@include widhtWide{
		max-width:$baseWidthWide;
	
	}
	@include tabletL{
		max-width:$bpTabletP;
	
	}
	@include tabletP{
		width:auto;
		padding-right: 10px;
		padding-left: 10px;
	
	}
}

.modPcOnly {
	@include tabletP {
		display:none !important;
	}
}
.modPcOnly_L {
	@include tabletL {
		display:none !important;
	}
}
.modPcOnly_S {
	@include sphoneP {
		display:none !important;
	}
}
.modPcOnly_tel {
	@include sphoneP {
		display:none !important;
	}
}

.modSpOnly {
	display:none !important;

	@include tabletP {
		display: block !important;
	}
}
.modSpOnly_L {
	display:none !important;

	@include tabletL {
		display: block !important;
	}
}
.modSpOnly_S {
	display:none !important;

	@include sphoneP {
		display: block !important;
	}
}
.modSpOnly_tel {
	display:none !important;

	@include sphoneP {
		display:inline !important;
	}
}
