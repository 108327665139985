@charset "utf-8";




@mixin widhtWide {
  //PC幅広の場合
  @media only screen and (min-width: $baseWidthWide) {
	  @content;
  }
}


@mixin wide {
  @media only screen and (min-width: $bpWide) {
    @content;
  }
}

@mixin tabletL {
  //タブレット横の場合
  @media only screen and (max-width: $bpTabletL) {
    @content;
  }
}

@mixin tabletP {
  //タブレット縦の場合
  @media only screen and (max-width: $bpTabletP) {
	  @content;
  }
}

@mixin sphoneP {
  //スマートフォン縦の場合
  @media only screen and (max-width: $bpSphoneP) {
	  @content;
  }
}
// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}


// clearfix
@mixin clearfix() {
 &:after {
 content:".";
 display:block;
 clear:both;
 height:0;
 visibility:hidden;
}
}

// inline-block
@mixin inline-block {
 display: inline-block;
 *display: inline;
 *zoom: 1;
}

// hidetext
@mixin hidetext {
 overflow: hidden;
 text-indent: -9999px;
 white-space: nowrap;
}

// margin-center
@mixin margin-center {
 margin-left:auto;
 margin-right:auto;
}

// font-size
@mixin fz($size: $baseFont,$important:false) {
 font-size: if($important, $size + px!important, $size + px);
 font-size: if($important, ($size / 10) + rem!important, ($size / 10) + rem);
}

// webkit ----------------------

// box-sizing @include box-sizing(content-box);
@mixin box-sizing($type: border-box) {
 -webkit-box-sizing: $type;
 box-sizing: $type;
}

// line-clamp
@mixin line-clamp($line) {
 display: -webkit-box;
 -webkit-box-orient: vertical;
 -webkit-line-clamp: $line;
}

// border-radius
 @mixin border-radius($radius) {
 -webkit-border-radius: $radius;
 -moz-border-radius: $radius;
 -ms-border-radius: $radius;
 border-radius: $radius;
}

// box-shadow@include box-shadow(0px 4px 5px #666, 2px 6px 10px #999);
@mixin box-shadow($shadows...) {
 -moz-box-shadow: $shadows;
 -webkit-box-shadow: $shadows;
 box-shadow: $shadows;
}

@mixin text-shadow($shadows...) {
text-shadow: $shadows;
}

@mixin background-size($size) {
 -webkit-background-size: $size;
 -moz-background-size: $size;
 -o-background-size: $size;
 background-size: $size;
}

//transition @include transition(all 0.1s linear);
@mixin transition($trans...) {
 -webkit-transition: $trans;
 -moz-transition: $trans;
 -o-transition: $trans;
 -ms-transition: $trans;
 transition: $trans;
}
