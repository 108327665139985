@charset "utf-8";

/* list
------------------------------------------------------------*/
.modList01 {
	width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
	li {
        width: 24%;
        margin-right: 1.3%;
        margin-bottom: 20px;
        &:nth-child(4n) {
            margin-right: 0%;
        }
        
		a {
			text-decoration:none;
			display:block;
			border:2px solid $mainColor;
			border-radius:5px;
			height: 100%;
		}
		.icon,
        .title {
			display:block;
			text-align:center;
			min-height: 48px;
		}
		.icon {
			padding:20px 0;
			background:$mainColor;
		}
		.title {
			padding:10px 5px;
			@include fz(16);
			font-weight:bold;
            margin-bottom: 0;
		}
        .sub {
            font-weight: normal;
            @include fz(14);
            @include tabletP{
            	display: block;
            }
        }
	}
	@include sphoneP{
		display:block;
		li {
			width:100%;
			margin-bottom:10px;
			padding:0;
			display:table;
			.icon,
            .title {
				display:table-cell;
				vertical-align:middle;
				br {
					display:none;
				}
			}
			.icon {
				padding:10px;
				width:50px;
				text-align:center;
				img {
					height:30px;
				}
			}
			.title {
				padding:10px;
				@include fz(14);
				text-align:left;
			}
		}
	}
}
.modList02 {
	margin-bottom:20px;
	li {
		margin-bottom:5px;
		background:#f8f8f5;
		padding:10px 15px;
		@include fz(16);
		@include sphoneP{
			@include fz(15);
			font-weight:bold;
			padding:7px 10px;
		}
		&:before {
			content: "\f046";
			font-family: FontAwesome;
			margin-right:10px;
			color:$mainColorR;
		}
		&:last-child {
			margin-bottom:0;
		}
	}
}
.modList03 {
	li {
		margin-bottom:5px;
		
		&:before {
			content: "\f0da";
			font-family: FontAwesome;
			margin-right:10px;
			color:$mainColor;
		}
		&:last-child {
			margin-bottom:0;
		}
	}
}


.modShopList {
	@include clearfix();
	width:100%;

	.unit {
		width:49%;
		border:2px solid #c9c9c9;
		border-radius:5px;
		margin-bottom:15px;
		padding:18px;
		box-sizing:border-box;

		&:nth-child(odd) {
			float:left;
		}
		&:nth-child(even) {
			float:right;
		}
		@include sphoneP{
			width:100%;
			padding:15px;
		}
	}
	
	.hl {
		margin-bottom:10px;
		color:$mainColorB;
		@include fz(17);
		font-weight:bold;
		@include sphoneP{
			@include fz(15);
			margin-bottom:5px;
		}
	}
	.address {
		th {
			font-weight:bold;
			padding:2px 0;
			width:5em;
		}
		td {
			padding:2px 0;
		}
	}
	
}


.modPager{
	margin-top:40px;
	
	ul{
		font-size:0;
		text-align:center;
	}
	li{
		@include inline-block;
		vertical-align:top;
		text-align:center;
		margin:0 10px 10px 0;
		&:last-child{
			margin-right:0;
		}
	}
	%frame{
		border:solid 1px #B4B4B4;
		background-color:#fff;
		height:30px;
		line-height:30px;
		box-sizing:border-box;
	}
	a{
		text-decoration:none;
	}
	
	a:hover{
		background-color:#F6F1E7;
	}
	
	.num{
		a{
			display:block;
			width:30px;
			@extend %frame;
			color:#929292;
			@include fz(16);
		}
		&.active{
			a{
				color:#fff;
				background-color:$mainColor;
				border-color:$mainColorB;				
			}
		}
	}
	
	.prev,.next{
		a{
			display:block;
			width:30px;
			background-position:center;
			background-repeat:no-repeat;
			@extend %frame;
			@include fz(16);
			color:$mainColor;
		}
	}
	
	
}


.newsList {
	
	.list {
		padding:10px 0;
		border-bottom:1px dotted #d2d2d2;
		@include clearfix;
	}
	
	dt {
		float:left;
		@include sphoneP{
			float:none;
		}
	}
	dd {
		@include sphoneP{
			margin-top:5px;
			margin-left:0;
		}
	}
	.day {
		display:inline-block;
		color:#999;
		width:8em;
		margin-right:10px;
	}
	.hl {
		font-weight:bold;
		@include fz(15);
		margin-bottom:10px;
	}
	.text {
		width:65%;
		float:right;
		@include sphoneP{
			width:100%;
			float:none;
			margin-bottom:10px;
		}
	}
	.photo {
		width:30%;
		float:left;
		@include sphoneP{
			width:100%;
			float:none;
			text-align:center;
		}
	}
	.category {
		@include fz(10);
		display:inline-block;
		width:8em;
		border-radius:3px;
		margin-right:10px;
		text-align:center;
		color:#fff;

		&_blue { background:#0088d8; }
		&_orange { background:#f29022; }
	}
	ul {
		margin:0 0 10px;
		
		.day {
			width:auto;
		}
		li {
			display:inline-block;
		}
	}
}

.staffList {
	@include clearfix();
	
	li {
		width:23.8%;
		margin:0 1.5% 10px 0;
		float:left;
		box-shadow: 0.618px 1.902px 2.97px 0.03px rgba(0, 0, 0, 0.1);

		&:nth-child(4n) {
			margin-right:0;
		}

		@include sphoneP{
			width:48.8%;
			&:nth-child(4n) {
				margin-right:1.5%;
			}
			&:nth-child(even) {
				margin-right:0;
			}
		}
		


		a {
			display:block;
			padding:25px 15px;
			text-align:center;
			img {
				border-radius:5px;
			}
			.photo,
			.shop,
			.name {
				display:block;
			}
			.photo {
				margin-bottom:10px;
			}
			.shop {
				background-color:$mainColorG;
				border-radius:5px;
				position:relative;
				margin:5px 0 10px;
				padding:2px 10px;
				display:inline-block;
			} 
			.shop:after {
				border:7px solid transparent;
				border-bottom-color:$mainColorG;
				border-top-width:0;
				top:-7px;
				content:"";
				display:block;
				left:50%;
				position:absolute;
				width:0;
				margin-left:-7px;
			}
			.name {
				font-weight:bold;
				@include fz(13);
			}			
		}
	}

}

.shopList {
	@include clearfix();
	
	li {
		counter-increment: num;
		width:48.6%;
		margin:0 1.5% 15px 0;
		float:left;
		border:2px solid #c9c9c9;
		border-radius:5px;
		box-sizing:border-box;
	
		&:nth-child(even) {
			margin-right:0;
			float:right;
		}

		@include sphoneP{
			width:100%;
			margin:0 0 15px 0;
		}		
	}
		a {
		text-decoration:none;
	}
	.title {
		background:#3e7ec2;
		color:#fff;
		padding:10px 15px;
		@include clearfix();
		
		h3 {
			@include fz(17);
			font-weight:bold;
			padding-top:5px;
			@include sphoneP{
				@include fz(15);
			}
		}
		h3:before {
			float:left;
			content: "0" counter(num);
			color: #FFF;
			padding-right:10px;
			margin-right:10px;
			margin-top:-4px;
			border-right:1px solid rgba(255,255,255,0.3);
			font-family: 'Lato', sans-serif;
			@include fz(22);
			@include sphoneP{
				@include fz(17);
			}
		}
	}
	.photo {
		height:170px;
		background-position:center center;
		background-size:100% auto;
		@include sphoneP{
			height:auto;
			float:left;
			width:45%;
		}		
	}
	.address {
		margin:10px 15px;
		
		th {
			font-weight:bold;
		}

		@include sphoneP{
			display:block;
			float:right;
			width:54%;
			margin:0;
			padding:10px;
			box-sizing:border-box;
			
			th,td {
				display:block;
			}
		}		

	}

}

.modContentsNavi {
	margin-bottom:40px;
	ul {
		display:table; width:100%;
		-moz-box-shadow:5px 1px 10px rgba(0,0,0,0.49);
		-webkit-box-shadow:5px 1px 10px rgba(0,0,0,0.49);
		box-shadow:5px 1px 10px rgba(0,0,0,0.49);
	}
	li {
		display:table-cell;
		text-align:center;
		border-right:1px solid #DDDDDD;
		&:last-child {
			border-right:none;
		}
	}
	a {
		display:block;
		background:url(../img/mainvisual/bg.jpg) repeat top left;
		padding:25px;
		@include fz(17);
		@include tabletP{
			padding:15px 2px;
			@include fz(15);
		}
		@include sphoneP{
			@include fz(13);
		}
		
		&:hover {
			background:url(../img/mainvisual/bg_hover.jpg) repeat top left;
		}
	}
	.on {
		position:relative;
		a {
			background:none;
			background-color:$mainColor;
			color:#fff;
			font-weight:bold;
		}
	}

}


.modListStep {
	text-align: center;
	margin-bottom: 30px;
	ul {
		letter-spacing: -.4em;
		display: inline-flex;
		justify-content: center;
		align-items:center;
		overflow: hidden;
		max-width: 100%;
		@include tabletL {
			display: flex;
		}
	}
	li {
		position: relative;
		letter-spacing: 0;
		background: #ddd;
		padding:10px 5px 10px 5px;
		min-width: 100px;
		//		padding:10px 15px 10px 30px;
		text-align: center;
		@include tabletL {
			width: 25%;
			min-width: 50px;
			padding: 10px 10px 10px 20px;
			@include fz(12);
		}
		&::before,
		&::after {
			content:" ";
			position: absolute;
			margin: 0 auto;
			top: -3px;
			bottom: 0;
			z-index: 1;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 23px 0 23px 15px;
			letter-spacing: 0;
			@include tabletL {
				border-width: 23.5px 0 23.5px 12px;
			}
		}
		&::before {
			right: -14px;
			border-color: transparent transparent transparent #fff;
		}
		&::after {
			right: -12px;
			border-color: transparent transparent transparent #ddd;
		}
		&[aria-selected="true"] {
			background: $mainColor;
			color: #FFF;
			&::after {
				border-color: transparent transparent transparent $mainColor;
			}
		}
		&:first-child {
			// @include radius(100px,0,100px,0);
			border-radius: 100px 0 0 100px;
			@include tabletL{
			}
		}
		&:last-child {
			// @include radius(0,100px,0,100px);
			border-radius: 0 100px 100px 0;
			min-width: 105px;
			@include tabletL{
				min-width: 50px;
			}
			&::before,
			&::after {
				display: none;
			}
		}
	}
}

.modList-btn {
	li {
		padding: 15px;
		margin-bottom: 20px;
	    border: 2px solid #c9c9c9;
	    border-radius: 5px;
	    box-sizing: border-box;
	    a {
	    	text-decoration: none;
	    }
	    
	}
	.title {
		margin-bottom: 10px;
		color: $mainColor;
		font-weight: bold;
		@include fz(18);
	}
	.txt {
		margin-bottom: 10px;
	}
}
.modList04{
	li{
		border-bottom: 1px solid #3e7ec2;
		padding: 20px 0;
		line-height: 1.8;
		padding-left:2em;
		text-indent:-2em;
		&:last-child{
			border-bottom: none;
			margin-bottom: 30px;
			@include sphoneP{
				margin-bottom:0;
			}
		}
	}
	&__num{
		color: #0069ce;
		font-weight: bold;
		padding-right: 5px;
	}
}

