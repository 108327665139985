
@charset "utf-8";
/* layout */
#header { }

#contents { @include clearfix; }


.container {
	position:relative;
	width:$baseWidth;
	@include margin-center;
}

#main {
	float: left;
	width: 73%;
	box-sizing:border-box;
	margin-bottom:70px;
	
	@include sphoneP{
		float:none;
		width:100%;
		box-sizing:border-box;
        margin-bottom: 40px;
		
		* { box-sizing:border-box; }
		
	}
}

#side {
	float: right;
	width: 23%;
	
	@include sphoneP{
		float:none;
		width:100%;
		box-sizing:border-box; 
		
		* { box-sizing:border-box; }

	}
	
	.sideBanner {
		text-align:center;

		li {
			margin-bottom:15px;

			@include sphoneP{
				display:inline-block;
				width:45%;
				text-align:center;
				margin:0 1% 10px;
			}
		}
	}
	.sideNavi {
		@include clearfix;

		background:#fff;		
		border:8px solid $mainColorG;
		border-top:11px solid $mainColorG;
		border-radius:8px;
		padding:10px;
		margin-bottom:20px;
		
		.title  {
			background-color:$mainColor;
			color:#fff;
			padding:8px;
			text-align:center;
			margin:-12px -10px 5px;
			border-radius:5px 5px 0 0;
			@include fz(15);		
		}		
		.title2  {
			border-bottom:1px solid $mainColor;
			font-weight:bold;
			color:$mainColorB;
			padding:5px 10px 2px;
			text-align:center;
			margin:0 -10px 10px;
			@include fz(15);		
			a {
				text-decoration:none;
			}
			a:hover {
				text-decoration:underline;
			}
		}
		.title3 {
			background:$mainColorG;
			border-bottom:1px solid #ddd;
			text-shadow:0 0 1px rgba(255,255,255,0.8);
			padding:10px;
			margin:15px -10px 0;
			text-align:center;
			font-weight:bold;
		}
        .txtDownload {
            display: block;
            position: relative;
            background: #f4a31a;
            font-weight: normal;
            border-radius: 5px;
            text-shadow: none;
            margin-top: 5px;
            padding: 5px 5px 5px 30px;
            text-align: left;
            &:before {
                position: absolute;
                height: 1em;
                line-height: 1;
                left: 8px;
                top: 0;
                bottom: 0;
                margin: auto;
                content: "\f06a";
                font-family: FontAwesome;
                color:#fff;
                @include fz(16);
            }
        }
		
		.naviList {
			li {
				padding:10px 0 10px 15px;
				border-bottom:1px dotted #d2d2d2;				
				&:before {
					content: "\f0da";
					font-family: FontAwesome;
					color:#0b5cb2;
					margin-left:-10px;
					margin-right:8px;
				}
				&:last-child {
					border-bottom:none;
					padding-bottom:0;
				}
			}
            .mark {
                display: inline-block;
                font-weight: normal;
                border-radius: 5px;
                text-shadow: none;
                padding: 3px 5px;
                text-align: left;
                font-weight: bold;
                text-decoration: none;
                &:before {
                    margin-right: 5px;
                }
                &_movie {
                    &:before {
                        display: inline-block;
                        content: "\f03d";
                        font-family: FontAwesome;
                        color:#333;
                        @include fz(12);
                    }
                }
                &_document {
                    &:before {
                        display: inline-block;
                        content: "\f15b";
                        font-family: FontAwesome;
                        color:#333;
                        @include fz(12);
                    }
                }
            }
			&_old {
				padding:0px 10px 10px;
				margin:0px -10px -10px;
				background:#f6f6f6;
			}
			&_tenants {
				margin:-10px;
				a {
					text-decoration:none;
				}
				.title_link {
					display:block;
					padding:10px;
					border-bottom:1px solid $mainColor;
					font-weight:bold;

					i {
						margin-left:-20px;
					}
				}

				.list_link {
					li {
						margin-bottom:0;
						border-bottom:1px solid $mainColorG;
	
						a {
							display:block;
							position:relative;
							padding:10px;
							&:after {
								content: "\f105";
								font-family: FontAwesome;
								position:absolute;
								top:-8px;
								right:10px;
								color:$mainColorG;
								font-size:36px;
							}
						}
						/*
						&:last-child {
							border-bottom:none;
						}
						*/
					}
				}
			}
		}
	
		.newsList {
			.list:last-child {
				border-bottom:none;
				padding-bottom:0;
			}
			
		}	
	}
}

#bottomBanner {
	border-top:2px solid $mainColorG;
	padding-top:20px;

	.ulBanner {
		margin-bottom:20px;
		text-align:center;
		
		li {
			display:inline-block;
			width:20%;
			text-align:center;
			margin:0 1%;
			@include sphoneP{
				width:45%;
				margin-bottom:10px;
			}
		}
	}

}


/*----------------------------------------*/

#header {
	height:190px;
	border-bottom:3px solid $mainColor;

	@include tabletP{
		height:80px;
	}		
		
	.siteTitle {
		position:absolute;
		top:45px;
		left:0;

		@include tabletL{
			top:10px;
		}		
		@include tabletP{
			left:10px;
            top: 15px;
		}		
		@include sphoneP{
			top:20px;
            margin-right: 110px;
            img {
                min-width:195px;
            }			
        }
	}
	.sitecatch {
		position:absolute;
		top:15px;
		color:#8c8c8c;
		@include fz(12);
	}
	.telAddress {
        @include clearfix;
		position:absolute;
		top:16px;
		right:0px;
        .bnrTel {
            float: left;
        }
        .bnrGyosha {
            float: right;
            margin-left:10px;
            @include sphoneP{
                display: none;
            }
        }
		@include tabletP{
            top: 10px;
			right:65px;
			img {
				height:55px;
			}			
		}		
		@include sphoneP{
			top:13px;
			right:50px;
			img {
				height:auto;
			}			
		}		
	}
	.bnrHps {
		position:absolute;
		top:16px;
		right:0px;
		@include tabletP{
			display: none;
		}
	}
}
#sitemapBtn {
	position:absolute;
	top:13px;
	right:0px;
}
#sitemapBtn.on {
	background:url(../img/navi/bgHover.png) repeat top left;
	border-radius:5px;
}

#gNav {
	position:absolute;
	top:120px;
	width:100%;
	ul {
		display:table;
		width:100%;
		li {
			display:table-cell;
			vertical-align:middle;
			text-align:center;
			padding:0px 10px;
			background:url(../img/navi/bgLine.png) no-repeat center left;
			
			@include tabletL{
				padding:0px 5px;
			}
			@include tabletP{
				padding:0;
				img { width:100%; }
			}
		}
		li:last-child {
			background:url(../img/navi/bgLine.png) no-repeat center left,
			url(../img/navi/bgLine.png) no-repeat center right;
		}
		a {
			display:block;
			padding:5px 10px;
			border-radius:5px;
			&:hover {
				background:url(../img/navi/bgHover.png) repeat top left;
			}
			@include tabletP{
				padding:5px;
			}
		}
	}
	@include tabletP{
		width:auto;
		padding-right:10px;
	}
	@include tabletP{
		display:none;
	}
}

/*----------------------------------------*/

#pankuzu_navi {
	padding:10px 0;
	margin-bottom:20px;
	background:url(../img/mainvisual/bg.jpg) repeat top left;

	@include sphoneP{
		margin-bottom:0;
	}
	
	ul {
		li {
			display:inline;
			a {
				text-decoration:none;
				&:after {
					content:"＞";
					margin: 0 5px;
				}
			}
		}
	}

}

/*----------------------------------------*/

$font-size-base_wide: 1.5vw;
$line-height-computed_wide: 1.5;
$lines-to-show_wide: 4;

$font-size-base: 2vw;
$line-height-computed: 1.5;
$lines-to-show: 4;

$font-size-base_tl: 2vw;
$line-height-computed_tl: 1.5;
$lines-to-show_tl: 3;

$font-size-base_tp: 4vw;
$line-height-computed_tp: 1.5;
$lines-to-show_tp: 2;

#mainvisual {
	padding:15px 0;
	background:url(../img/mainvisual/bg.jpg) repeat top left;
    
	.visual_value {
		.inner {
			position: relative;
			.img {

			}
			.txt {
				position: absolute;
				bottom: 30px;
				left: 165px;
				@include tabletP{
					bottom: 2vw;
					left: 17vw;
				}
				.value {
					display: block;
					letter-spacing: -0.05em;
					@include fz(68);
					color: #2c3792;
					font-weight: bold;
					line-height: 1;
					@include tabletP{
						font-size:7vw;
					}
					@include sphoneP{
						font-size:6vw;
						margin-bottom: -2.5vw;
					}
					span {
						@include fz(130);
						@include tabletP{
							font-size:13vw;
						}
						@include sphoneP{
							font-size:11.5vw;
						}
					}
				}
				.data {
					line-height: 1;
					@include fz(16);
					@include sphoneP{
						font-size: 1.5vw;
					}
				}
			}
		}
	}
	.visual_news {
		.inner {
			position: relative;
			.img {

			}
			.txt {
				position: absolute;
				top: 42%;
				left: 5%;
				width: 69%;
				@include tabletP{
					/*
					bottom: 2vw;
					left: 17vw;
					*/
				}
			}
			.head {
				margin-bottom: 10px;
				@include tabletP{
					margin-bottom: 0.5vw;
				}
				color: #f07100;
				.news {
					@include fz(24);
					@include tabletL {
						font-size: 2.3vw
					}
				}
				.data {
					@include fz(20);
					margin-left: 10px;
					@include tabletL {
						font-size: 2.3vw
					}
				}
			}
			.body {
				overflow: hidden;
				width: 100%;
				p {
					font-size: $font-size-base;
					height: $font-size-base * $line-height-computed * $lines-to-show;
					line-height: $line-height-computed;
					position: relative;
					@include wide {
						font-size: $font-size-base_wide;
						height: $font-size-base_wide * $line-height-computed_wide * $lines-to-show_wide;
						line-height: $line-height-computed_wide;
					}
					@include tabletL {
						font-size: $font-size-base_tl;
						height: $font-size-base_tl * $line-height-computed_tl * $lines-to-show_tl;
						line-height: $line-height-computed_tl;
					}
					@include tabletP {
						font-size: $font-size-base_tp;
						height: $font-size-base_tp * $line-height-computed_tp * $lines-to-show_tp;
						line-height: $line-height-computed_tp;
					}
					&:before,
					&:after {
						background: #fff;
						position: absolute;
					}
					&:before {
						content: "...";
						top: $font-size-base * $line-height-computed * ($lines-to-show - 1);
						right: 0;
						@include tabletL {
							top: $font-size-base_tl * $line-height-computed_tl * ($lines-to-show_tl - 1);
						}
						@include tabletP {
							top: $font-size-base_tp * $line-height-computed_tp * ($lines-to-show_tp - 1);
						}
					}
					&:after {
						content: "";
						height: 100%;
						width: 100%;
					}
				}
				.honbun {
					@include tabletP {
						display: none;
					}
				}
				.title {
					display: none;
					@include tabletP {
						display: block;
					}
				}
			}
			
			
			
		}
	}
}
.slider-center{
  opacity: 0;
  transition: opacity .3s linear;
}
.slider-center.slick-initialized{
  opacity: 1;
}
.mainvisual_slider {
	li {
		margin-left: 1vw;
		margin-right: 1vw;
		img {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.slick-prev,
	.slick-next {
		width: 100px;
		height: 100px;
		z-index: 100;
	}	
	.slick-prev:before,
	.slick-next:before {
		font-size: 50px;
	}	
	.slick-prev {
		left: 2vw;
	}
	.slick-next {
		right: 2vw;
	}
	.slick-dots {
		li {
			margin: 0 3px;
			button:before {
				font-size: 13px;
			}
		}
	}
}

/*----------------------------------------*/

#contents {
	padding:15px 0 70px;
	overflow:hidden;

	@include sphoneP{
		padding-bottom:30px;
	}		

	.topContentsArea1 {
		margin-bottom: 15px;
		display: flex;
		flex-wrap:wrap;
		justify-content:space-between;
		&-banner {
			width: 66%;
			@include sphoneP{
				width: 100%;
			}
		}
		&-column {
			width: 33%;
			@include sphoneP{
				width: 100%;
			}
		}
	}

	.topBanner {
		@include clearfix;

		li {
			float:left;
			margin:0 15px 15px 0;

			&.pc{
				@include sphoneP{
					display: none;
				}
			}
			&.sp{
				display: none;
				@include sphoneP{
					display: block;
				}
			}
		}
		&__storeinfo{
			margin-right: 0!important;
			@include sphoneP{
				width:100%!important;
			}
		}
		&_1 {
			width:100%;
			li:nth-child(3n) { margin-right:0; }
		}
		&_2 {
			width:100%;
			li:nth-child(2n) { margin-right:0; }
			li:last-child { margin-right:0; }
		}
		&_3 {
			float:left;
			width:67.4%;
			li:nth-child(2n) { margin-right:0; }

			@include sphoneP{
				float:none;
				width:100%;				
			}
		}
		&_4 {
			float:right;
			width:32.5%;

			@include sphoneP{
				float:none;
				width:100%;				
			}

			.column1 {
				li {
					margin-right:0;
					
					@include sphoneP{
						width:48%;
						margin:0 1.5% 10px 0;
						&:nth-child(2n) { margin-right:0; }
						&:last-child { margin-right:0; }
					}
				}				

				@include sphoneP{
					@include clearfix;
					margin-bottom:10px;
				}
			}
			.column2 {
				li:nth-child(2n) {
					margin-right:0;
					
					@include sphoneP{
						margin:0 1.5% 10px 0;
					}
				}

				@include sphoneP{
					li {
						width:23.5%;
						margin:0 1.5% 10px 0;
						
						&:last-child { margin-right:0; }
					}
				}
			}
		}
		&_5-1 {
			li {
				width: 48%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}

		
		@include tabletL{
			li { margin:0 1.5% 10px 0; }

			&_1 {
				li {
					width:32.3%;
				}
			}			
			&_2 {
				li {
					width:32.3%;
				}
				li.col2 {
					width:66%;
				}
			}			
			&_3 {
				li {
					width:48%;
				}
			}
			&_4 {
				li {
					width:49%;
				}
			}
		}		
		@include sphoneP{
			&_2 {
				li {
					width:49.2%;
				}
				li.col2 {
					width:100%;
					margin-right:0;
				}
				li:nth-child(2n) { margin-right:1.5%; }
				li:nth-child(3n) { margin-right:0; }
				li.coltempo { margin-right:1.5%; }
				li.colcompany { width: 100%; margin-right:0; }
			}
		}
		@media (max-width:400px) {
			&_1 {
				li {
					width:100%;
					text-align:center;
				}
			}			
		}

	}

	.topColumn {
		&_inner {
			border: 3px solid #EDEDED;
			position: relative;
			&:before,
			&:after {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				position: absolute;
				bottom: -3px;
				right: -3px;
			}
			&:before {
				border-width: 30px 30px 0 0;
				border-color: #EDEDED transparent transparent transparent;
			}
			&:after {
				border-width: 0 0 30px 30px;
				border-color: transparent transparent #fff transparent;
			}
		}
		&_head {
			background: #E4F2FA;
			padding: 10px;
			.en {
				display: inline-block;
				margin-right: 15px;
				color: $mainColor;
				@include fz(22);
				@include tabletL{
					display: block;
					@include fz(18);
				}
				@include sphoneP{
					display: inline-block;
				}
			}
			.txt {
				font-weight: bold;
				@include fz(16);
			}
		}
		&_contents {
			padding: 10px;
			.entry_inner {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			.entry_img {
				margin-bottom: 10px;
				text-align: center;
			}
			.entry_detail {
				
			}
			.entry_summary {
				margin-bottom: 10px;
				display: flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items: center;
			}
			.entry_date {
				width: 60px;
				text-align: center;
				.year {
				    display: block;
				    @include fz(14);
				    font-weight: bold;                      
				}
				.date {
				    display: block;
				    @include fz(18);
				    font-weight: bold;
				    color: #0088D8;
				}
			}
			.entry_title {
				width: calc(100% - 70px);
				.category {

				}
				.title {
				    font-weight: bold;
				    @include fz(16);
				}
			}
			&_btn {
				border-top: 1px dotted #ccc;
				margin-top: 10px;
				margin-bottom: 5px;
				padding-top: 20px;
			}
		}
	}
	
	.areaInfomation {
		@include clearfix;
		
		padding:15px;
		background:#ededed;
		border-radius:10px;
		margin-bottom:10px;
		.areaNewsBlog {
			float:left;
			width:66.5%;
			.areaEntry {
				background:#fff;
				border-radius:5px;
				padding:10px;
				margin-bottom:10px;
				box-sizing:border-box;
		
				&:last-child { margin-bottom:0; }
				
				.title {
					@include fz(25);
					color:#575757;
					border-bottom:2px solid $mainColor;
					margin-bottom:5px;
					@include sphoneP{
						@include fz(20);
					}
				}
				.cosTxtRight {
					margin-top:10px;
				}
			}		
			.areaBlog {
				.list {
					dd:before {
						content: "\f0da";
						font-family: FontAwesome;
						color:#0b5cb2;
					}
				}
			}
			@include sphoneP{
				float:none;
				width:100%;
			}
		}
		.areaFacebook {
			float:right;
			width:31.8%;
			@include sphoneP{
				margin:10px auto 0;
				text-align:center;
				float:none;
				width:100%;
			}
		}
	}
}

#topContents {
	@include tabletP{
		padding-left:10px;
		padding-right:10px;
	}
	.bnrHps {
		margin-bottom: 20px;
		text-align: center;
	}
}

/*----------------------------------------*/

#footer {
	background:#1b1b1b;
	overflow:hidden;
	color:#fff;
	a {
		color:#fff;
	}
	.container { 
		@include clearfix;

		.footer_sitemap {
			float:right;
			width:66%;
			display:table;
			table-layout:fixed;
			padding:35px 0;
            .bnrGyosha {
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: center;
                a {
                    position: relative;
                    display: block;
                    padding: 15px;
                    border: 2px solid #c9c9c9;
                    border-radius: 5px;
                    background: #fff;
                    color: #333;
                    text-decoration: none;
                    font-weight: bold;
                    @include fz(14);
                    &:before {
                        width: 50px;
                        height: 45px;
                        content: "";
                        background: url(../img/foot/logoGyosha.png) no-repeat;
                        background-size: 100% auto;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 10px;
                        margin: auto;
                    }
                }
                img {
                    height: 50px;
                }
            }
			.unit {
				display:table-cell;
				border-left:1px solid rgba(72, 72, 72, 1);
				padding:0 0 0 10px;
				
				@include sphoneP{
					border-left:none;
					display:block;
					padding:0;
					
					.first {
						margin-bottom:0;
					}
				}
				
				a {
					text-decoration:none;
				}
				ul {
					margin-bottom:25px;
					@include sphoneP{
						@include clearfix;
					}
					li {
						margin-bottom:15px;
						font-weight:bold;
						@include sphoneP{
							margin:0;
						}
						a {
							&:before {
							  content: "\f138";
							  font-family: FontAwesome;
							  color:#0b5cb2;
							  margin-right:5px;
							}
							@include sphoneP{
								width:100%;
								float:none;
								display:block;
								padding:10px;
								@include fz(14);
								border-bottom: 1px solid rgba(72, 72, 72, 0.56);
							}
						}
						ul {
							margin-top:5px;								
							@include sphoneP{
								border-bottom: 1px solid rgba(72, 72, 72, 0.56);
							}
							li {
								margin-left:20px;
								margin-bottom:5px;
								font-weight:normal;
								a {
									@include sphoneP{
										padding:5px;
										border-bottom:none;
									}
									&:before {
									  content: "\f0da";
									  font-family: FontAwesome;
									}
								}
							}
						}
					}
					&:last-child {
						margin-bottom:0;
					}
				}
			}
			@include tabletP{
				float:none;
				width:100%;
			}
			@include sphoneP{
				padding-top:0px;
			}
		}		
		.footer_address {
			float:left;
			width:30%;
			padding:15px 0;
			.sitelogo {
				padding:50px 0;
				@include sphoneP{
					padding:20px;
					text-align:center;
					img {
						height:25px;
					}
				}
			}
			@include tabletP{
				float:none;
				width:100%;
				.sitelogo {
					padding:20px 0;
				}
			}
		}
	}
	
	.footer_copyright {
		padding:30px 0;
		background:#000000;
		color:#fff;
		
	}	
	
	#pagetopBtn {
		position:fixed;
		z-index:10;
		bottom:30px;
		right:30px;
		@include sphoneP{
			position:static;
			text-align:center;
			padding-top:20px;
			img {
				height:60px;
			}
		}
	}
}