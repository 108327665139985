@charset "utf-8";

/* font
------------------------------------------------------------*/
.fontSt_1 { font-family: 'Lato', sans-serif; }



/* block
------------------------------------------------------------*/
.section {
	@include clearfix();

	margin-bottom:60px;
	@include sphoneP{
		margin-bottom:40px;
	}
	p {
		margin-bottom:30px;
		@include sphoneP{
			margin-bottom:20px;
		}
	}
}

.section_S {
	@include clearfix();

	margin-bottom:40px;
	@include sphoneP{
		margin-bottom:20px;
	}
	p {
		margin-bottom:30px;
		@include sphoneP{
			margin-bottom:20px;
		}
	}
}

.pageDetail {
	.section {
		@include clearfix();

	margin-bottom:60px;
	}
	.photo {
		
		img {
			border-radius: 5px;
		}
		
		@include sphoneP{
			width:100% !important;
			float:none !important;
			text-align:center;
			margin-bottom:10px;
		}
	}
	.text {
		@include sphoneP{
			width:100% !important;
			float:none !important;
		}
	}

	.area_photoText_1 {
		.photo {
			width:35%;
			float:left;
			img {
				border-radius:10px;
			}
		}
		.text {
			width:60%;
			float:right;

			@include sphoneP{
				text-align:center;
			}
		}
		.spec {
			li {
				margin-bottom:5px;
				font-weight:bold;
				
				strong {
					color:$mainColorB;
				}
			}
			.fa-facebook-square {
				color:#00479d;
			}
		}
	}
	.area_photoText_2 {
		.photo {
			width:30%;
			float:left;

		}
		.text {
			width:65%;
			float:right;

			@include sphoneP{
				text-align:center;
			}
		}
		.text_nophoto {
			width:100%;
			float:none;
		  p {
				margin-bottom:0;
			}
		}
	}
	.area_message {
		border:8px solid $mainColorG;
		border-radius:10px;
		padding:20px 50px;

		@include sphoneP{
			padding:10px;
		}		
		.hl {
			@include fz(20);
			text-align:center;
			color:$mainColor;
			margin-bottom:15px;
			padding:5px 10px;
			/*
			background:url(../img/layout/line.png) repeat-x bottom left;
			*/
			background:url(../img/layout/bg.png) repeat top left;
		}
		p {
			margin-bottom:0;
		}
	}
	.area_photoText_3 {
		.photo {
			float:left;
			width:60%;
			ul {
				margin:10px 0;
				@include clearfix();
				li:nth-child(odd) {
					float:left;
					width:48%;
				}
				li:nth-child(even) {
					float:right;
					width:48%;
				}
			}
		}
		.text {
			float:right;
			width:35%;
			dl {
				dt {
					font-weight:bold;
					color:$mainColor;
				}
				dd {
					margin-bottom:15px;
				}
			}
		}
	}
	.area_point {
		.modTitle03 {
			@include fz(18);
			text-align:center;
			padding:20px 10px;
			margin-bottom:10px;
			.fontSt_1 {
				@include fz(26);
				vertical-align:baseline;
			}
		}
		.content {
			display:table;
			table-layout:fixed;
			border-spacing:15px 0;
			margin:0 auto;
			
			@include tabletP{	
				border-spacing:15px 0;
			}
			
			@include sphoneP{
				display:block;
			}
		}
/*
		.point {
			display:table-cell;
			.photo {
				margin:0 auto 20px;
				text-align:center;
				img {
					border-radius:10px;
				}
				
				@include tabletP{
				}
				@include sphoneP{
				}
			}
			.text {
			}
			@include sphoneP{
				display:block;
				border-bottom:1px dotted #ccc;
				margin-bottom:20px;
				padding-bottom:10px;
				@include clearfix();
				li:last-child {
					
				}
			}

		}
*/
		.point {
			@include clearfix();
			margin-bottom:10px;
			
			.photo {
				float:left;
				width:40%;
			}
			.text {
				float:right;
				width:55%;
			}
		}
	}
}

.section_modCompany {	
	border:1px dotted #5b9ad6;
	padding:30px;
	margin-bottom:30px;

	@include sphoneP{
		padding:20px;
	}


	&:last-child {
		margin-bottom:50px;
	}	
	.modTitle01 {
		margin:-20px -20px 20px;
		text-align:center;
		border-top:none;
	}
	.modTitle02 {
		background:none;
		padding:0;
		color:$mainColorB;
		@include fz(18);
		@include sphoneP{
			@include fz(16);
		}
	}

	
	p {
		@include fz(16);
		@include sphoneP{
			@include fz(14);
		}
	}
	.modCompany_bold {
		@include fz(20);
		font-weight:bold;
		line-height:1.9;
		margin-bottom:0;
		@include sphoneP{
			@include fz(18);
		}
	}
	.modCompany_bold2 {
		@include fz(16);
		font-weight:bold;
		line-height:1.9;
		margin-bottom:0;
		@include sphoneP{
			@include fz(14);
		}
	}
	.modCompany_ul {
		li {
			@include fz(16);
			position:relative;
			font-weight:bold;
			margin-bottom:18px;
			padding-left:20px;
			@include sphoneP{
				@include fz(14);
			}
		}
		li:before {
		  content: "\f0da";
		  font-family: FontAwesome;
		  position:absolute;
		  top:-3px;
		  left:0px;
		  color:$mainColorB;
		}
		li:last-child {
			margin-bottom:0;
		}
	}
}
.section_modMessage {
	background:url(../img/contents/company/messageBack.png) repeat top left;
	
	.text {
		float:right;
		width:75%;
		@include sphoneP{
			float:none;
			width:100%;
		}
	}
	.photo {
		float:left;
		width:25%;
		@include sphoneP{
			float:none;
			width:50%;
			margin:0px auto 10px;
		}
	 }	
	.modTitle06 {
		@include fz(18);
	}
	.lineText {
		background: url(../img/contents/company/line.png) repeat bottom left;
		font-size: 15px;
		line-height: 35px;
		padding: 0 10px;
		margin-bottom:0;

		@include sphoneP{
			padding:5px;			
		}
	}
}

.boxContact {
	border:7px solid #3e7ec2;
	background:#fff;
	padding:20px;
	margin-bottom:50px;
	text-align:center;

	@include sphoneP{
		br { display:none; }
	}	
	.title {
		@include fz(16);
		font-weight:bold;
		margin-bottom:20px;
	}
	.add {
		@include fz(16);
		margin-bottom:20px;
		strong {
			font-size:22px;
			display:block;
		}
		color:$mainColorB;
	}
    &_side {
        border-radius: 3px;
    }
}
.recruitList {
	.title {
		font-size:24px;
		border-top: none;
		padding-left: 0;
		margin-bottom:10px;
        @include sphoneP{
            @include fz(18);  
        }
	}
    .modTxt {
        margin-bottom: 30px;
    }
}

.area_mainvisual {
	margin-bottom:40px;
	@include sphoneP{
		margin-bottom:20px;
	}	
	.visual {
		margin-bottom:20px;
	}
	.catch {
		text-align:center;
		margin:50px 0;
		@include fz(25);
		@include sphoneP{
			margin:20px 0;
			@include fz(18);
		}	
	}
}
.area_tenatsList {
	@include clearfix();
	
	li {
		counter-increment: num;
		width:31%;
		margin:0 3% 15px 0;
		float:left;
		border:2px solid #c9c9c9;
		border-radius:5px;
		box-sizing:border-box;
	
		&:nth-child(3n) {
			margin-right:0;
			float:right;
		}

		@include sphoneP{
			width:100%;
			margin:0 0 15px 0;
		}		
	}
		a {
		text-decoration:none;
	}
	dl { 
		dt {
			border-radius:3px 3px 0 0;
			background:$mainColor;
			text-align:center;
			@include fz(20);
			font-weight:bold;
			a {
				display:block;
				color:#fff;				
				padding:20px 5px;
				@include sphoneP{
					padding:10px 5px;
					@include fz(18);
				}
			}	
		}
		i:before {
			display:block;
			font-size:30px;
			font-weight:normal;
			@include sphoneP{
				font-size:20px;
				display:inline;
			}	
		}
		dd {
			position:relative;
			border-bottom:1px dotted #ccc;
			span {
				color:#ccc;
				font-weight:bold;
			} 
			img {
				float:left;
				margin-right:10px;
			}
			a {
				@include clearfix();
				display:block;
				padding:10px;
			}
			a:hover {
				text-decoration:underline;
			}
		}
		dd:after {
		  content: "\f105";
		  font-family: FontAwesome;
		  position:absolute;
		  top:-3px;
		  right:10px;
		  color:$mainColorG;
		  font-size:36px;
		}
		dd:last-child {
			border-bottom:none;
		}
	}	
}
.area_newSeminar {
	border:2px solid #ef7c00;
	padding:20px;
	
	.title {
		@include fz(18);
		text-align:center;
		font-weight:bold;
		margin-bottom:15px;
		i {
			margin-right:5px;
			color:#ef7c00;
			@include sphoneP{
				display:block;
			}	
		}
		i:before {
			font-size:25px;			
		}
	}
	.list {
		li {
			@include fz(16);
			margin-bottom:10px;
			padding-bottom:10px;
			border-bottom:1px solid #eee;
			&:last-child {
				border-bottom:none;
			}
		}
	}
}
.area_sunPoint {
	margin-bottom:40px;
	
	.modTitle06 {
		@include fz(30);
		@include sphoneP{
			@include fz(20);
		}	
	}
	.txt {
		@include fz(20);
		margin-bottom:30px;
		@include sphoneP{
			@include fz(18);
		}	
	}
	.list_point {
		@include clearfix();
		background:$mainColor;
		padding:20px 0;
		li {
			position:relative;
			float:left;
			width:22%;
			margin-right:3.5%;
			margin-bottom:20px;
			background:#fff;
			border-radius:5px;
			box-sizing:border-box;
			text-align:center;

			@include sphoneP{
				width:47%;
				margin-right:5%;
			}	
			a {
				display:block;
				padding:20px 20px 40px;
				text-decoration:none;
				
				.num {
					font-size:30px;
					display:inline-block;
					padding:0 10px 5px;
					margin-bottom:20px;
					color:$mainColorB;
					font-weight:bold;
					border-bottom:2px solid $mainColorB;
					@include sphoneP{
						font-size:20px;
					}	
				}
				.title {
					display:block;
					font-size:20px;
					font-weight:bold;
					line-height:1.4;
					@include sphoneP{
						font-size:15px;
					}	
				}
				i {
					position:absolute;
					bottom:10px;
					color:#d7e1f0;
					font-size:20px;
					margin-left:-10px;
				}
			}
		}
		li:nth-child(4n) {
			margin-right:0;
			@include sphoneP{
				margin-right:3.5%;
			}	
		}
		li:nth-child(2n) {
			@include sphoneP{
				margin-right:0;
			}
		}
	}
}
.area_sunPoint_section {
	section {
		margin-bottom:30px;
		border-bottom:2px solid #d7e1f0;
		padding-bottom:20px;
		@include fz(15);
		@include clearfix();
	}
	.title {
		font-weight:bold;
		margin-bottom:10px;

		@include sphoneP{
			text-align:center;
		}	
		
		.num {
			display:inline-block;
			border-bottom:2px solid $mainColorB;
			color:$mainColor;
			font-size:40px;
			text-align:center;
			margin:0 10px 0 0;
			@include sphoneP{
				margin-bottom:10px;
			}	
		}
		.hl {
			display:inline-block;
			text-align:center;
			margin-bottom:10px;
			@include fz(30);
			@include sphoneP{
				display:block;
				@include fz(20);
			}	
		}
	}
	.txt {
		width:60%;
		float:left;
		@include sphoneP{
			width:100%;
			float:none;
		}	
	}
	.photo {
		width:35%;
		float:right;
		@include sphoneP{
			width:100%;
			float:none;
			text-align:center;
		}	
	}
	.txt_center {
		text-align:center;
	}
	.point02_blue {
		display:inline-block;
		background:$mainColor;
		vertical-align:text-bottom;
		border-radius:5px;
		padding:10px;
		color:#fff;
		@include fz(25);
		@include sphoneP{
			vertical-align:bottom;
			@include fz(20);
		}	
	}
	.point02_percent {
		display:inline-block;
        @include fz(45);
        span {
            @include fz(75);
        }
		@include sphoneP{
			display:block;
			@include fz(70);
		}	
	}
    .point02_rate {
        position: relative;
        text-align: center;
        p {
            margin-bottom: 0;
        }
        .image {
        }
        .inner {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            width: 180px;
            @include tabletP{
            	line-height: 1.2;
            }
            @include sphoneP{
                line-height: 1.5;
            }
        }
        .label {
            font-weight: bold;
        }
        .value {
            color: #4a4a4a;
            @include fz(30);
            span {
                @include fz(50);
            }
        }
        .sunhome {
            border-bottom: 1px solid #959595;
            margin-bottom: 10px;
            padding-bottom: 5px;
            .label {
                @include fz(20);
                color: #3e7ec2;
            }
            .value {
            }
        }
        .okayama {
            .label {
                @include fz(15);
                color: #549040;
            }
            .value {
            }
        }
    }
}

#area_recruitTop {
	padding:50px 0;
	background:url(../img/contents/recruit/back.jpg) repeat top left;
	
	.section {
		border-radius:10px;
		background:#fff;
		padding:30px 20px;
	}
	.area_title {
		margin:0 -20px 30px;
		padding-bottom:20px;
		border-bottom:2px solid $mainColor;
		text-align:center;
		color:$mainColor;
		@include fz(30);
		@include sphoneP{
			@include fz(25);
		}	
		
		.hl { font-weight:bold; }
	}
	.modContentsNavi {
		margin:-30px -20px 20px;

		ul { 
			box-shadow:none;
			table-layout:fixed;			
		}
	}
}

.workList {
	margin:0 30px 60px;
	@include fz(16);

	@include tabletP{
		margin:0 0 30px;
	}	
	
	.title {
		text-align:center;
	  padding:5px;
		margin-bottom:20px;
		text-shadow:0 1px 1px rgba(255,255,255,0.58);
		@include fz(25);	
		border-bottom:3px solid;
		.hl {
			font-weight:bold;			
		}
	}
	.photo {
		float:left;
		width:30%;
		@include sphoneP{
			float:none;
			width:100%;
			text-align:center;
			margin-bottom:10px;
		}	
	}
	.text {
		/*
		float:right;
		width:65%;
		*/
		@include sphoneP{
			float:none;
			width:100%;
		}	
	}
	.back {
		@include clearfix();
		margin-bottom:20px;
	}
	.staff {
		@include clearfix();
		position:relative;
		padding:20px 20px 10px 20px;
		margin:0 auto;
		width:80%;
		border-radius:10px;
		border:3px solid;
		box-sizing:border-box;

		@include tabletP{
			width:100%;
		}	
		
		&:before {
			content: "\f0de";
			font-family: FontAwesome;
			position:absolute;
			top:3px;
			left:50%;
			font-size:50px;
			margin-left:-25px;
			line-height:0;
		}
		.photo {
			float:left;
			width:24%;
            text-align: center;
			@include sphoneP{
				float:none;
				width:50%;
				margin:0 auto 10px;
			}	

		}
		.text {
			float:right;
			width:73%;

			@include sphoneP{
				float:none;
				width:100%;
			}	

			.hl {
				font-weight:bold;
				@include fz(18);
				margin-bottom:10px;
				@include sphoneP{
					text-align:center;
				}	
			}
			p {
				margin-bottom:10px;
			}
			p.cosTxtRight {
				margin-bottom:0;
				font-weight:bold;
			}
		}
	}
	
	&_cat01 {
		.title { border-color:rgb(182, 202, 88); background:rgba(182, 202, 88,.3); }
		.staff { border-color:rgb(182, 202, 88); background:rgba(182, 202, 88,.4); }
		.staff:before { color:rgb(182, 202, 88); }
	}
	&_cat02 {
		.title { border-color:rgb(233, 174, 191); background:rgba(233, 174, 191,.3); }
		.staff { border-color:rgb(233, 174, 191); background:rgba(233, 174, 191,.4); }
		.staff:before { color:rgb(233, 174, 191); }
	}
	&_cat03 {
		.title { border-color:rgb(238, 212, 108); background:rgba(238, 212, 108,.3); }
		.staff { border-color:rgb(238, 212, 108); background:rgba(238, 212, 108,.4); }
		.staff:before { color:rgb(238, 212, 108); }
	}
	&_cat04 {
		.title { border-color:rgb(150, 203, 220); background:rgba(150, 203, 220,.3); }
		.staff { border-color:rgb(150, 203, 220); background:rgba(150, 203, 220,.4); }
		.staff:before { color:rgb(150, 203, 220); }
	}
	
}
.section_renotta {
	border:1px solid #000;
	.unit {
		padding:50px;
		border-bottom:1px dashed #666;
		@include sphoneP{
			padding:20px;
		}	

		&:last-child {
			border-bottom:none;
		}
	}
}
.akichi_block {
	border:2px solid $mainColor;
	padding:20px;
	margin-bottom:40px;
	h4 {
		text-align:center;
		margin:20px 0;
	}
	p {
		margin-bottom:20px;
	}
	.img {
		margin:-20px -20px 0;
	}
}
.modCaution {
	border:2px solid $mainColorR;
	border-radius:5px;
	padding:10px;
	text-align:center;
	color:$mainColorR;
	@include fz(16);
}
.modCaution2 {
	border:2px solid $mainColorB;
	border-radius:5px;
	padding:10px;
	text-align:center;
	color:$mainColorB;
	@include fz(16);
}

.modTxtBlock {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    @include fz(16);
    @include sphoneP{
        margin-top: 10px;
        margin-bottom: 10px;
        @include fz(14);
    }
}
.modTxtBlock2 {
    background: #eee;
    padding: 20px;
    margin: 20px 0;
    @include fz(14);
}

.modBlockTel {
    border:7px solid #3e7ec2;
    background:#fff;
    padding:20px;
    margin-bottom:50px;
    text-align:center;
    
    .title {
        @include fz(16);
        font-weight:bold;
        margin-bottom:15px;
    }
    .add {
        color:$mainColorB;
        @include fz(22);
        font-weight: bold;
        margin-bottom: 10px;
        .label {
            &:before {
                display: inline-block;
                @include fz(25);
                content: "\f095";
                font-family: FontAwesome;
                color:#0b5cb2;
                margin: 0 10px 0px 0;
            }
            &:after {
                content: "：";
            }
        }
        .number {
            @include fz(26);
        }
    }
    .txt {
        color:$mainColorB;
        @include fz(14);
    }
    &_bottom {
        margin-bottom: 0;
        @include sphoneP{
            display: none;
        }
    }
    &_side {
        padding: 15px 10px;
        border-radius: 5px;
        .title {
            @include fz(14);
            margin-bottom: 10px;
            span {
                @include sphoneP{
                    display: block;
                }
            }
        }
        .add {
            @include fz(16);
            .label {
                display: block;
                &:before {
                    @include fz(16);
                    margin-right: 5px;
                }
                &:after {
                    display: none;
                }
            }
            .number {
                @include fz(22);
            }
        }
        .txt {
            @include fz(12);
        }
    }
}

.recruitList + .modBlockTel_bottom {
	margin-top: 30px;
}

.modLoginBlock {
    form {
        max-width: 400px;
        margin: 0 auto;
    }
    .modTbl02 {
        margin-top: 20px;
        margin-bottom: 20px;
        th {
            width: 100px;
            vertical-align: middle;
        }
        tr {
            th,
            td {
                padding: 10px 10px;
                border-bottom: none;
            }
            th {
                @include sphoneP{
                    padding-bottom: 0;
                }
            }
        }
    }
    .txt {
        margin-top: 20px;
        text-align: center;
    }
}
.seminar_loginBlock {
    background: url(../img/contents/owners/seminar/loginBack.jpg) no-repeat bottom right;
    border-bottom: 3px solid #d1e7f4;
    margin-bottom: 20px;
    @include sphoneP{
        background: url(../img/contents/owners/seminar/loginBack02.jpg) repeat top left;
    }
    .inner {
        padding: 50px 10px;
        text-align: center;
        @include sphoneP{
            padding: 20px 20px 10px;
        }
    }
    .head {
        display: inline-block;
        position: relative;
        padding-left: 120px;
        text-align: left;
        margin-bottom: 20px;
        @include sphoneP{
            padding-left: 0;
            margin-bottom: 10px;
        }
        &:before {
            position: absolute;
            top: -10px;
            left: 0;
            content: "";
            display: block;
            background: url(../img/contents/owners/seminar/mark.png) no-repeat top left;
            width: 99px;
            height: 99px;
            @include sphoneP{
                top: -5px;
                width: 50px;
                height: 50px;
                background-size: 100% auto;
            }
        }
        font-weight: bold;
        .title {
            color: #ef5400;
            font-weight: bold;
            @include fz(22);
            margin-bottom: 5px;
            @include sphoneP{
                @include fz(16);
                padding-left: 60px;
            }
        }
        .txt {
            @include fz(16);
            @include sphoneP{
                @include fz(14);
                text-align: left;
            }
            span {
                display: block;
                @include sphoneP{
                    display: inline;
                }
            }
        }
    }
    .body {
        .btn {
            margin-bottom:20px;
        }
        .txt {
            @include fz(14);
            @include sphoneP{
                @include fz(12);  
            }
        }
    }
    .loginBtn {
        max-width: 250px;
        display: block;
        background: #ef5400;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
        padding: 20px;
        margin: auto;
        @include fz(16);
        font-weight: bold;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.7);
        &:hover{
            opacity: 0.8;
        }
        @include sphoneP{
            padding: 15px 10px;
        }
    }
}
.seminar_videoBlock {
    text-align: center;
    margin-bottom: 50px;
    position:relative;
    width:100%;
    height:0;
    padding-top:75%;
	@include sphoneP{
        margin-bottom: 30px;
    }
    video {
        margin: auto;
        @include tabletP{
            width: 100%;
            height: auto;
        }
	}
	video,
	iframe {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
}

.seminar_documentBlock {
    margin-bottom: 30px;
    border-top: 1px solid #bfbfbf;
    th,td {
        padding: 12px 0;
        border-bottom: 1px solid #bfbfbf;
        @include fz(14);
        @include sphoneP{
            display: block;
        }
    }
    th {
        .file {
            display: inline-block;
            font-weight: bold;
            margin-right: 10px;
            &:before {
                content: "";
                display: inline-block;
                height: 22px;
                width: 20px;
                margin-right: 5px;
                margin-bottom: -5px;
                background: url(../img/layout/file_default.png) no-repeat top left;
                background-size: 100% auto;
            }
            &_excel {
                &:before {
                    background: url(../img/layout/file_excel.png) no-repeat top left;
                    background-size: 100% auto;
                }
            }
            &_pdf {
                &:before {
                    background: url(../img/layout/file_pdf.png) no-repeat top left;
                    background-size: 100% auto;
                }
            }
            &_ppt {
                &:before {
                    background: url(../img/layout/file_ppt.png) no-repeat top left;
                    background-size: 100% auto;
                }
            }
            &_word {
                &:before {
                    background: url(../img/layout/file_word.png) no-repeat top left;
                    background-size: 100% auto;
                }
            }
            &_zip {
                &:before {
                    background: url(../img/layout/file_zip.png) no-repeat top left;
                    background-size: 100% auto;
                }
            }
        }
        @include sphoneP{
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .btn {
        width: 200px;
        text-align: right;
        @include sphoneP{
            width: 100%;
            padding-top: 10px;
            a {
                padding: 5px;
                display: block;
                text-align: center;
            }  
        }
    }
}
