@charset "utf-8";

// font
$baseFont: 12;
$baseLineHeight: 1.5;
$baseColor: #333;
$baseLinkColor: #333;
$mainColor: #3e7ec2;
$mainColorB: #0069ce;
$mainColorR: #e60012;
$mainColorG: #ededed;



// font-family
$baseFontFamily : "メイリオ",Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;


//decoration
$baseRadius: 3px;

// icon webfont
$arrowR : "\f105";
$arrowL : "\f104";
$iconHome : "\f015";


// width
$baseWidth: 1000px;
$baseWidthWide: 1000px;

// point
$bpWide: 1400px;
$bpTabletL: 979px;
$bpTabletP: 768px;
$bpSphoneP: 580px;

