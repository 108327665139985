@charset "utf-8";

/* #parts_CMS_css
------------------------------------------------------------*/
#parts_CMS_css {
	
    .modTitle03{
		font-weight:bold;
		color:$mainColorB;
		border-top:none;
		padding:0;
		margin-bottom:15px;
		@include fz(19);
		
		@include sphoneP{
			@include fz(16);
		}
	}
    .modTitle03_02 {
        background:#3e7ec2;
		padding:10px 15px;
		margin-bottom:15px;
		font-weight:bold;
		color:#fff;
		@include fz(17);
		@include sphoneP{
			@include fz(15);
		}
	}	
    .modTitle03_03{
        font-weight:bold;
		padding-bottom:5px;
		margin-bottom:15px;
		border-bottom:2px solid $mainColorB;
		@include fz(16);
		@include sphoneP{
			@include fz(14);
		}
	}
    
    #news_parts_CMS_css & {
        .modTitle03 {
            font-weight:bold;
            color:$mainColorB;
            border-top:none;
            padding:0;
            margin-bottom:15px;
            @include fz(25);
            @include sphoneP{
                @include fz(18);  
            }

        }
        .modTitle03_02 {
            background: none;
            font-weight:bold;
            border-top:none;
            padding:0;
            color: #333;
            margin-bottom:15px;
            @include fz(19);
            @include sphoneP{
                @include fz(16);
            }
        }
        .modTitle03_03{
            font-weight:bold;
            padding-bottom:5px;
            margin-bottom:15px;
            border-bottom:2px solid $mainColorB;
            @include fz(15);
            @include sphoneP{
                @include fz(14);
            }
        }
    }

	table{
	tbody,
	tr,
	th,
	td{
		@include tabletP {
			display:block;
		}
	}
	
	th,td{
		border:solid 1px #CCCCCC;
		padding:10px;
		@include tabletP {
			padding:10px 10px;
		}
	}
	th{
		width:100px;
		color:#fff;
		font-weight:bold;
		background:$mainColor;
		text-shadow:0 0 1px rgba(0,0,0,0.34);
		@include tabletP {
			width:auto;
		}
		@include sphoneP{
		}
	}
	td{
	}
	}

	iframe{
		width:100%;
		height:400px;
		@include tabletP{
			height:250px;
		}
	}

	ul {
		margin-left: 2em;
	
		li {
			list-style: disc;
            &:before {
                display: inline-block;
                content: "・";
                text-indent: -1em;
            }
			
		}
	}
	ol {
		margin-left: 2.2em;
        li {
            list-style: decimal;

        }
	}
	strong,
	th {
		font-weight:bold;
	}
	
	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

    .modItBlock_imgL,
    .modItBlock_imgR {
        @include clearfix;
        margin-bottom: 20px;
    }

    .modItBlock_imgL .imageBlock,
    .modItBlock_imgR .imageBlock {
        max-width: 400px;
    }

	.modItBlock_imgR .imageBlock img {
		padding: 4px;
		margin: 0 0 2px 7px;
		display: inline;
		@include tabletP{
			display:block;
		}		
	}
	.modItBlock_imgL .imageBlock img {
		padding: 4px;
		margin: 0 7px 2px 0;
		display: inline;
		@include tabletP{
			display:block;
		}		
	}
	.modItBlock_imgR .imageBlock {
		float: right;
        margin-left: 20px;
		@include tabletP{
			float:none;
			text-align:center;
		}		
	}
	.modItBlock_imgL .imageBlock {
		float: left;
        margin-right: 20px;
        @include tabletP{
			float:none;
			text-align:center;
		}		
	}

	strong{
		font-weight:bold;
	}
	
	.modTxt { margin-bottom:20px; }

    .modImg {
        margin-bottom:20px;
        text-align:center;
        max-width: 500px;
        @include margin-center;
    }

	.btn{
		display:block;
		width:100%;
		background-color:$mainColorB;
		border-radius:5px;
		padding:10px;
		border:none;
		box-sizing:border-box;
		box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.7) inset;
		color:#fff;
		text-decoration:none;
		font-weight:bold;
		text-align:center;
		text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.4);
		@include fz(15);
		@include tabletP {
			padding:10px;
		}
		&:hover{
			background-color: darken($mainColor, 5%);
			box-shadow: 0 0 0  rgba(0, 0, 0, 0.7) inset;
		}
		
		&_out{
			background-color:#999;
			&:hover{
				background-color: darken(#999, 5%);
			}
		}
	}
    .modBtn01 {
        margin: 10px auto;
        & + * {
          margin-top: 50px;
          @include tabletP {
            margin-top: 30px;
          }
        }
    }
}



/* wysiwyg
------------------------------------------------------------*/
.modParts{
	margin-bottom:20px;
}

.modPartsImage{
	text-align:center;
	color:#959595;
	@include fz(11);
	.caption{
		margin:5px 0 0;
	}
	a:hover{
		opacity:0.8;
	}
}

.modPartsYoutube {
	max-width:560px;
	margin-left:auto;
	margin-right:auto;

	@include tabletP{
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		iframe {
		  position: absolute;
		  top: 0;
		  right: 0;
		  width: 100% !important;
		  height: 100% !important;
		}
	
	}
}

.modPartsTxt{

}
