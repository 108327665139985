@charset "utf-8";

/* font
------------------------------------------------------------*/
.fontSt_1 { font-family: 'Lato', sans-serif; }

/* btn
------------------------------------------------------------*/
.btnSt_1 {
	display:inline-block;
	padding:3px 20px 3px 10px;
	background-color:$mainColor;
	border-radius:5px;
	color:#fff;
	text-decoration:none;
	
	&:hover {
		color:#fff;
        opacity: 0.8;
	}

	&:before {
	  content: "\f138";
	  font-family: FontAwesome;
	  color:#fff;
	  margin-right:10px;
	}
	&.btnSt_1big {
		text-align:center;
		display:block;
		padding:15px 10px;
		background-color:$mainColorB;
		@include fz(15);	

	}
}

.modBtn01{
	display:block;
	width:100%;
	background-color:$mainColorB;
	border-radius:5px;
	padding:10px;
	border:none;
	box-sizing:border-box;
	box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.7) inset;
	color:#fff;
	text-decoration:none;
	font-weight:bold;
	text-align:center;
	text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.4);
	@include fz(15);
	@include tabletP {
		padding:10px;
	}
	&:hover{
		background-color: darken($mainColor, 5%);
		box-shadow: 0 0 0  rgba(0, 0, 0, 0.7) inset;
        color: #fff;
	}
	
	&_out{
		background-color:#999;
		&:hover{
			background-color: darken(#999, 5%);
		}
	}
	&_large {
		width: 400px;
		margin: auto auto 10px;
		@include fz(18);
		@include tabletP{
			width: 100%;
		}
	}
	&_xlarge {
		display: inline-block;
		width: auto;
		margin: auto;
		padding:10px 30px;
		@include fz(24);
		@include tabletP{
			width: 100%;
		}
	}
}
.modBtn02{
	display:block;
	width:100%;
	background-color:#fff;
	border-radius:5px;
	padding:9px 10px;
	border:solid 1px $mainColor;
	box-sizing:border-box;
	box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.7) ;
	color:$mainColor;
	text-decoration:none;
	font-weight:bold;
	text-align:center;
	@include fz(15);
	@include tabletP {
		padding:8px;
	}
	&:hover{
		background-color:$mainColor;
		color:#fff;
	}
	
	&_small{
		padding:3px 10px;
		@include fz(14);
		font-weight:normal;
		@include tabletP {
			padding:8px;
		}
	}
	
	&_edit{
		&:before{
			margin:0 4px 0 0;
		}
	}
}


.modBtnList{
	li{
		width:230px;
		margin:0 auto;
	}
	
	
	&_large{
		margin: 30px auto;
		li {
			width: auto;
			text-align: center;
		}
	}
	
	&_column02{
		position:relative;
		max-width:370px;
		margin:0 auto;
		@include tabletP{
			max-width:none;
		}
		
		li{
			width:120px;
			max-width:none;
			margin:0;
			
			@include tabletP{
				width:auto;
			}
		}
		.right{
			position:absolute;
			top:0;
			right:0;
			width:230px;
			@include tabletP{
				position:static;
				width:auto;
				margin-bottom:10px;
			}
		}
	}
}

