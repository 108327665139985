@charset "UTF-8";
@import url(../font_flat/flaticon.css);
@import url(font-awesome.min.css);
/* Reset
------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Lato:700);
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

address,
em {
  font-style: normal; }

th {
  font-weight: normal; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

th {
  text-align: left; }

hr,
legend {
  display: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal; }

img,
fieldset {
  border: 0; }

img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto; }

li {
  list-style-type: none; }

input[type="submit"], button, label, select {
  cursor: pointer; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

/* link
------------------------------------------------------------*/
a {
  outline: none;
  color: #333;
  text-decoration: underline; }
  a:hover {
    text-decoration: none;
    color: #333; }

/* Fonts
------------------------------------------------------------*/
html {
  font-size: 62.5%; }

/* 10px base */
body {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word; }

body > div, input, button, textarea, select {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "メイリオ", Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
  line-height: 1.5;
  color: #333; }

/* form
------------------------------------------------------------*/
input::placeholder {
  color: #ccc; }

/* IE */
input:-ms-input-placeholder {
  color: #ccc; }

/* Edge */
input::-ms-input-placeholder {
  color: #ccc; }

.inputTxt, textarea, select {
  padding: 3px 10px;
  border: 1px solid #CCC;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    .inputTxt, textarea, select {
      border-radius: 3px;
      padding: 3px 5px; } }

.inputTxt, textarea {
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.2) inset;
  background-color: #fafafa;
  width: 100%;
  -webkit-appearance: none;
  vertical-align: top; }
  .inputTxt.error, textarea.error {
    background-color: #FFF8F9;
    border-color: #e60012;
    border-width: 2px; }

.inputTxt {
  max-width: 400px;
  height: 44px;
  line-height: 44px; }
  @media only screen and (max-width: 768px) {
    .inputTxt {
      height: 33px;
      line-height: 33px; } }

select {
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.2) inset;
  background-color: #F9F9F9;
  font-size: 14px;
  font-size: 1.4rem;
  /*
	padding: 8px 15px;
	@include tabletP {
		width:100%;
	}
	*/ }

input[type="radio"], input[type="checkbox"] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  margin-right: 0.5em; }

/* layout */
#contents:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.container {
  position: relative;
  width: 1000px;
  margin-left: auto;
  margin-right: auto; }

#main {
  float: left;
  width: 73%;
  box-sizing: border-box;
  margin-bottom: 70px; }
  @media only screen and (max-width: 580px) {
    #main {
      float: none;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 40px; }
      #main * {
        box-sizing: border-box; } }

#side {
  float: right;
  width: 23%; }
  @media only screen and (max-width: 580px) {
    #side {
      float: none;
      width: 100%;
      box-sizing: border-box; }
      #side * {
        box-sizing: border-box; } }
  #side .sideBanner {
    text-align: center; }
    #side .sideBanner li {
      margin-bottom: 15px; }
      @media only screen and (max-width: 580px) {
        #side .sideBanner li {
          display: inline-block;
          width: 45%;
          text-align: center;
          margin: 0 1% 10px; } }
  #side .sideNavi {
    background: #fff;
    border: 8px solid #ededed;
    border-top: 11px solid #ededed;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px; }
    #side .sideNavi:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    #side .sideNavi .title {
      background-color: #3e7ec2;
      color: #fff;
      padding: 8px;
      text-align: center;
      margin: -12px -10px 5px;
      border-radius: 5px 5px 0 0;
      font-size: 15px;
      font-size: 1.5rem; }
    #side .sideNavi .title2 {
      border-bottom: 1px solid #3e7ec2;
      font-weight: bold;
      color: #0069ce;
      padding: 5px 10px 2px;
      text-align: center;
      margin: 0 -10px 10px;
      font-size: 15px;
      font-size: 1.5rem; }
      #side .sideNavi .title2 a {
        text-decoration: none; }
      #side .sideNavi .title2 a:hover {
        text-decoration: underline; }
    #side .sideNavi .title3 {
      background: #ededed;
      border-bottom: 1px solid #ddd;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
      padding: 10px;
      margin: 15px -10px 0;
      text-align: center;
      font-weight: bold; }
    #side .sideNavi .txtDownload {
      display: block;
      position: relative;
      background: #f4a31a;
      font-weight: normal;
      border-radius: 5px;
      text-shadow: none;
      margin-top: 5px;
      padding: 5px 5px 5px 30px;
      text-align: left; }
      #side .sideNavi .txtDownload:before {
        position: absolute;
        height: 1em;
        line-height: 1;
        left: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        content: "\f06a";
        font-family: FontAwesome;
        color: #fff;
        font-size: 16px;
        font-size: 1.6rem; }
    #side .sideNavi .naviList li {
      padding: 10px 0 10px 15px;
      border-bottom: 1px dotted #d2d2d2; }
      #side .sideNavi .naviList li:before {
        content: "\f0da";
        font-family: FontAwesome;
        color: #0b5cb2;
        margin-left: -10px;
        margin-right: 8px; }
      #side .sideNavi .naviList li:last-child {
        border-bottom: none;
        padding-bottom: 0; }
    #side .sideNavi .naviList .mark {
      display: inline-block;
      font-weight: normal;
      border-radius: 5px;
      text-shadow: none;
      padding: 3px 5px;
      text-align: left;
      font-weight: bold;
      text-decoration: none; }
      #side .sideNavi .naviList .mark:before {
        margin-right: 5px; }
      #side .sideNavi .naviList .mark_movie:before {
        display: inline-block;
        content: "\f03d";
        font-family: FontAwesome;
        color: #333;
        font-size: 12px;
        font-size: 1.2rem; }
      #side .sideNavi .naviList .mark_document:before {
        display: inline-block;
        content: "\f15b";
        font-family: FontAwesome;
        color: #333;
        font-size: 12px;
        font-size: 1.2rem; }
    #side .sideNavi .naviList_old {
      padding: 0px 10px 10px;
      margin: 0px -10px -10px;
      background: #f6f6f6; }
    #side .sideNavi .naviList_tenants {
      margin: -10px; }
      #side .sideNavi .naviList_tenants a {
        text-decoration: none; }
      #side .sideNavi .naviList_tenants .title_link {
        display: block;
        padding: 10px;
        border-bottom: 1px solid #3e7ec2;
        font-weight: bold; }
        #side .sideNavi .naviList_tenants .title_link i {
          margin-left: -20px; }
      #side .sideNavi .naviList_tenants .list_link li {
        margin-bottom: 0;
        border-bottom: 1px solid #ededed;
        /*
						&:last-child {
							border-bottom:none;
						}
						*/ }
        #side .sideNavi .naviList_tenants .list_link li a {
          display: block;
          position: relative;
          padding: 10px; }
          #side .sideNavi .naviList_tenants .list_link li a:after {
            content: "\f105";
            font-family: FontAwesome;
            position: absolute;
            top: -8px;
            right: 10px;
            color: #ededed;
            font-size: 36px; }
    #side .sideNavi .newsList .list:last-child {
      border-bottom: none;
      padding-bottom: 0; }

#bottomBanner {
  border-top: 2px solid #ededed;
  padding-top: 20px; }
  #bottomBanner .ulBanner {
    margin-bottom: 20px;
    text-align: center; }
    #bottomBanner .ulBanner li {
      display: inline-block;
      width: 20%;
      text-align: center;
      margin: 0 1%; }
      @media only screen and (max-width: 580px) {
        #bottomBanner .ulBanner li {
          width: 45%;
          margin-bottom: 10px; } }

/*----------------------------------------*/
#header {
  height: 190px;
  border-bottom: 3px solid #3e7ec2; }
  @media only screen and (max-width: 768px) {
    #header {
      height: 80px; } }
  #header .siteTitle {
    position: absolute;
    top: 45px;
    left: 0; }
    @media only screen and (max-width: 979px) {
      #header .siteTitle {
        top: 10px; } }
    @media only screen and (max-width: 768px) {
      #header .siteTitle {
        left: 10px;
        top: 15px; } }
    @media only screen and (max-width: 580px) {
      #header .siteTitle {
        top: 20px;
        margin-right: 110px; }
        #header .siteTitle img {
          min-width: 195px; } }
  #header .sitecatch {
    position: absolute;
    top: 15px;
    color: #8c8c8c;
    font-size: 12px;
    font-size: 1.2rem; }
  #header .telAddress {
    position: absolute;
    top: 16px;
    right: 0px; }
    #header .telAddress:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    #header .telAddress .bnrTel {
      float: left; }
    #header .telAddress .bnrGyosha {
      float: right;
      margin-left: 10px; }
      @media only screen and (max-width: 580px) {
        #header .telAddress .bnrGyosha {
          display: none; } }
    @media only screen and (max-width: 768px) {
      #header .telAddress {
        top: 10px;
        right: 65px; }
        #header .telAddress img {
          height: 55px; } }
    @media only screen and (max-width: 580px) {
      #header .telAddress {
        top: 13px;
        right: 50px; }
        #header .telAddress img {
          height: auto; } }
  #header .bnrHps {
    position: absolute;
    top: 16px;
    right: 0px; }
    @media only screen and (max-width: 768px) {
      #header .bnrHps {
        display: none; } }

#sitemapBtn {
  position: absolute;
  top: 13px;
  right: 0px; }

#sitemapBtn.on {
  background: url(../img/navi/bgHover.png) repeat top left;
  border-radius: 5px; }

#gNav {
  position: absolute;
  top: 120px;
  width: 100%; }
  #gNav ul {
    display: table;
    width: 100%; }
    #gNav ul li {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding: 0px 10px;
      background: url(../img/navi/bgLine.png) no-repeat center left; }
      @media only screen and (max-width: 979px) {
        #gNav ul li {
          padding: 0px 5px; } }
      @media only screen and (max-width: 768px) {
        #gNav ul li {
          padding: 0; }
          #gNav ul li img {
            width: 100%; } }
    #gNav ul li:last-child {
      background: url(../img/navi/bgLine.png) no-repeat center left, url(../img/navi/bgLine.png) no-repeat center right; }
    #gNav ul a {
      display: block;
      padding: 5px 10px;
      border-radius: 5px; }
      #gNav ul a:hover {
        background: url(../img/navi/bgHover.png) repeat top left; }
      @media only screen and (max-width: 768px) {
        #gNav ul a {
          padding: 5px; } }
  @media only screen and (max-width: 768px) {
    #gNav {
      width: auto;
      padding-right: 10px; } }
  @media only screen and (max-width: 768px) {
    #gNav {
      display: none; } }

/*----------------------------------------*/
#pankuzu_navi {
  padding: 10px 0;
  margin-bottom: 20px;
  background: url(../img/mainvisual/bg.jpg) repeat top left; }
  @media only screen and (max-width: 580px) {
    #pankuzu_navi {
      margin-bottom: 0; } }
  #pankuzu_navi ul li {
    display: inline; }
    #pankuzu_navi ul li a {
      text-decoration: none; }
      #pankuzu_navi ul li a:after {
        content: "＞";
        margin: 0 5px; }

/*----------------------------------------*/
#mainvisual {
  padding: 15px 0;
  background: url(../img/mainvisual/bg.jpg) repeat top left; }
  #mainvisual .visual_value .inner {
    position: relative; }
    #mainvisual .visual_value .inner .txt {
      position: absolute;
      bottom: 30px;
      left: 165px; }
      @media only screen and (max-width: 768px) {
        #mainvisual .visual_value .inner .txt {
          bottom: 2vw;
          left: 17vw; } }
      #mainvisual .visual_value .inner .txt .value {
        display: block;
        letter-spacing: -0.05em;
        font-size: 68px;
        font-size: 6.8rem;
        color: #2c3792;
        font-weight: bold;
        line-height: 1; }
        @media only screen and (max-width: 768px) {
          #mainvisual .visual_value .inner .txt .value {
            font-size: 7vw; } }
        @media only screen and (max-width: 580px) {
          #mainvisual .visual_value .inner .txt .value {
            font-size: 6vw;
            margin-bottom: -2.5vw; } }
        #mainvisual .visual_value .inner .txt .value span {
          font-size: 130px;
          font-size: 13rem; }
          @media only screen and (max-width: 768px) {
            #mainvisual .visual_value .inner .txt .value span {
              font-size: 13vw; } }
          @media only screen and (max-width: 580px) {
            #mainvisual .visual_value .inner .txt .value span {
              font-size: 11.5vw; } }
      #mainvisual .visual_value .inner .txt .data {
        line-height: 1;
        font-size: 16px;
        font-size: 1.6rem; }
        @media only screen and (max-width: 580px) {
          #mainvisual .visual_value .inner .txt .data {
            font-size: 1.5vw; } }
  #mainvisual .visual_news .inner {
    position: relative; }
    #mainvisual .visual_news .inner .txt {
      position: absolute;
      top: 42%;
      left: 5%;
      width: 69%; }
      @media only screen and (max-width: 768px) {
        #mainvisual .visual_news .inner .txt {
          /*
					bottom: 2vw;
					left: 17vw;
					*/ } }
    #mainvisual .visual_news .inner .head {
      margin-bottom: 10px;
      color: #f07100; }
      @media only screen and (max-width: 768px) {
        #mainvisual .visual_news .inner .head {
          margin-bottom: 0.5vw; } }
      #mainvisual .visual_news .inner .head .news {
        font-size: 24px;
        font-size: 2.4rem; }
        @media only screen and (max-width: 979px) {
          #mainvisual .visual_news .inner .head .news {
            font-size: 2.3vw; } }
      #mainvisual .visual_news .inner .head .data {
        font-size: 20px;
        font-size: 2rem;
        margin-left: 10px; }
        @media only screen and (max-width: 979px) {
          #mainvisual .visual_news .inner .head .data {
            font-size: 2.3vw; } }
    #mainvisual .visual_news .inner .body {
      overflow: hidden;
      width: 100%; }
      #mainvisual .visual_news .inner .body p {
        font-size: 2vw;
        height: 12vw;
        line-height: 1.5;
        position: relative; }
        @media only screen and (min-width: 1400px) {
          #mainvisual .visual_news .inner .body p {
            font-size: 1.5vw;
            height: 9vw;
            line-height: 1.5; } }
        @media only screen and (max-width: 979px) {
          #mainvisual .visual_news .inner .body p {
            font-size: 2vw;
            height: 9vw;
            line-height: 1.5; } }
        @media only screen and (max-width: 768px) {
          #mainvisual .visual_news .inner .body p {
            font-size: 4vw;
            height: 12vw;
            line-height: 1.5; } }
        #mainvisual .visual_news .inner .body p:before, #mainvisual .visual_news .inner .body p:after {
          background: #fff;
          position: absolute; }
        #mainvisual .visual_news .inner .body p:before {
          content: "...";
          top: 9vw;
          right: 0; }
          @media only screen and (max-width: 979px) {
            #mainvisual .visual_news .inner .body p:before {
              top: 6vw; } }
          @media only screen and (max-width: 768px) {
            #mainvisual .visual_news .inner .body p:before {
              top: 6vw; } }
        #mainvisual .visual_news .inner .body p:after {
          content: "";
          height: 100%;
          width: 100%; }
      @media only screen and (max-width: 768px) {
        #mainvisual .visual_news .inner .body .honbun {
          display: none; } }
      #mainvisual .visual_news .inner .body .title {
        display: none; }
        @media only screen and (max-width: 768px) {
          #mainvisual .visual_news .inner .body .title {
            display: block; } }

.slider-center {
  opacity: 0;
  transition: opacity .3s linear; }

.slider-center.slick-initialized {
  opacity: 1; }

.mainvisual_slider li {
  margin-left: 1vw;
  margin-right: 1vw; }
  .mainvisual_slider li img {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }

.mainvisual_slider .slick-prev,
.mainvisual_slider .slick-next {
  width: 100px;
  height: 100px;
  z-index: 100; }

.mainvisual_slider .slick-prev:before,
.mainvisual_slider .slick-next:before {
  font-size: 50px; }

.mainvisual_slider .slick-prev {
  left: 2vw; }

.mainvisual_slider .slick-next {
  right: 2vw; }

.mainvisual_slider .slick-dots li {
  margin: 0 3px; }
  .mainvisual_slider .slick-dots li button:before {
    font-size: 13px; }

/*----------------------------------------*/
#contents {
  padding: 15px 0 70px;
  overflow: hidden; }
  @media only screen and (max-width: 580px) {
    #contents {
      padding-bottom: 30px; } }
  #contents .topContentsArea1 {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #contents .topContentsArea1-banner {
      width: 66%; }
      @media only screen and (max-width: 580px) {
        #contents .topContentsArea1-banner {
          width: 100%; } }
    #contents .topContentsArea1-column {
      width: 33%; }
      @media only screen and (max-width: 580px) {
        #contents .topContentsArea1-column {
          width: 100%; } }
  #contents .topBanner:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  #contents .topBanner li {
    float: left;
    margin: 0 15px 15px 0; }
    @media only screen and (max-width: 580px) {
      #contents .topBanner li.pc {
        display: none; } }
    #contents .topBanner li.sp {
      display: none; }
      @media only screen and (max-width: 580px) {
        #contents .topBanner li.sp {
          display: block; } }
  #contents .topBanner__storeinfo {
    margin-right: 0 !important; }
    @media only screen and (max-width: 580px) {
      #contents .topBanner__storeinfo {
        width: 100% !important; } }
  #contents .topBanner_1 {
    width: 100%; }
    #contents .topBanner_1 li:nth-child(3n) {
      margin-right: 0; }
  #contents .topBanner_2 {
    width: 100%; }
    #contents .topBanner_2 li:nth-child(2n) {
      margin-right: 0; }
    #contents .topBanner_2 li:last-child {
      margin-right: 0; }
  #contents .topBanner_3 {
    float: left;
    width: 67.4%; }
    #contents .topBanner_3 li:nth-child(2n) {
      margin-right: 0; }
    @media only screen and (max-width: 580px) {
      #contents .topBanner_3 {
        float: none;
        width: 100%; } }
  #contents .topBanner_4 {
    float: right;
    width: 32.5%; }
    @media only screen and (max-width: 580px) {
      #contents .topBanner_4 {
        float: none;
        width: 100%; } }
    #contents .topBanner_4 .column1 li {
      margin-right: 0; }
      @media only screen and (max-width: 580px) {
        #contents .topBanner_4 .column1 li {
          width: 48%;
          margin: 0 1.5% 10px 0; }
          #contents .topBanner_4 .column1 li:nth-child(2n) {
            margin-right: 0; }
          #contents .topBanner_4 .column1 li:last-child {
            margin-right: 0; } }
    @media only screen and (max-width: 580px) {
      #contents .topBanner_4 .column1 {
        margin-bottom: 10px; }
        #contents .topBanner_4 .column1:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; } }
    #contents .topBanner_4 .column2 li:nth-child(2n) {
      margin-right: 0; }
      @media only screen and (max-width: 580px) {
        #contents .topBanner_4 .column2 li:nth-child(2n) {
          margin: 0 1.5% 10px 0; } }
    @media only screen and (max-width: 580px) {
      #contents .topBanner_4 .column2 li {
        width: 23.5%;
        margin: 0 1.5% 10px 0; }
        #contents .topBanner_4 .column2 li:last-child {
          margin-right: 0; } }
  #contents .topBanner_5-1 li {
    width: 48%; }
    #contents .topBanner_5-1 li:nth-child(2n) {
      margin-right: 0; }
  @media only screen and (max-width: 979px) {
    #contents .topBanner li {
      margin: 0 1.5% 10px 0; }
    #contents .topBanner_1 li {
      width: 32.3%; }
    #contents .topBanner_2 li {
      width: 32.3%; }
    #contents .topBanner_2 li.col2 {
      width: 66%; }
    #contents .topBanner_3 li {
      width: 48%; }
    #contents .topBanner_4 li {
      width: 49%; } }
  @media only screen and (max-width: 580px) {
    #contents .topBanner_2 li {
      width: 49.2%; }
    #contents .topBanner_2 li.col2 {
      width: 100%;
      margin-right: 0; }
    #contents .topBanner_2 li:nth-child(2n) {
      margin-right: 1.5%; }
    #contents .topBanner_2 li:nth-child(3n) {
      margin-right: 0; }
    #contents .topBanner_2 li.coltempo {
      margin-right: 1.5%; }
    #contents .topBanner_2 li.colcompany {
      width: 100%;
      margin-right: 0; } }
  @media (max-width: 400px) {
    #contents .topBanner_1 li {
      width: 100%;
      text-align: center; } }
  #contents .topColumn_inner {
    border: 3px solid #EDEDED;
    position: relative; }
    #contents .topColumn_inner:before, #contents .topColumn_inner:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      bottom: -3px;
      right: -3px; }
    #contents .topColumn_inner:before {
      border-width: 30px 30px 0 0;
      border-color: #EDEDED transparent transparent transparent; }
    #contents .topColumn_inner:after {
      border-width: 0 0 30px 30px;
      border-color: transparent transparent #fff transparent; }
  #contents .topColumn_head {
    background: #E4F2FA;
    padding: 10px; }
    #contents .topColumn_head .en {
      display: inline-block;
      margin-right: 15px;
      color: #3e7ec2;
      font-size: 22px;
      font-size: 2.2rem; }
      @media only screen and (max-width: 979px) {
        #contents .topColumn_head .en {
          display: block;
          font-size: 18px;
          font-size: 1.8rem; } }
      @media only screen and (max-width: 580px) {
        #contents .topColumn_head .en {
          display: inline-block; } }
    #contents .topColumn_head .txt {
      font-weight: bold;
      font-size: 16px;
      font-size: 1.6rem; }
  #contents .topColumn_contents {
    padding: 10px; }
    #contents .topColumn_contents .entry_inner {
      text-decoration: none; }
      #contents .topColumn_contents .entry_inner:hover {
        text-decoration: underline; }
    #contents .topColumn_contents .entry_img {
      margin-bottom: 10px;
      text-align: center; }
    #contents .topColumn_contents .entry_summary {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
    #contents .topColumn_contents .entry_date {
      width: 60px;
      text-align: center; }
      #contents .topColumn_contents .entry_date .year {
        display: block;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: bold; }
      #contents .topColumn_contents .entry_date .date {
        display: block;
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #0088D8; }
    #contents .topColumn_contents .entry_title {
      width: calc(100% - 70px); }
      #contents .topColumn_contents .entry_title .title {
        font-weight: bold;
        font-size: 16px;
        font-size: 1.6rem; }
    #contents .topColumn_contents_btn {
      border-top: 1px dotted #ccc;
      margin-top: 10px;
      margin-bottom: 5px;
      padding-top: 20px; }
  #contents .areaInfomation {
    padding: 15px;
    background: #ededed;
    border-radius: 10px;
    margin-bottom: 10px; }
    #contents .areaInfomation:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    #contents .areaInfomation .areaNewsBlog {
      float: left;
      width: 66.5%; }
      #contents .areaInfomation .areaNewsBlog .areaEntry {
        background: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        box-sizing: border-box; }
        #contents .areaInfomation .areaNewsBlog .areaEntry:last-child {
          margin-bottom: 0; }
        #contents .areaInfomation .areaNewsBlog .areaEntry .title {
          font-size: 25px;
          font-size: 2.5rem;
          color: #575757;
          border-bottom: 2px solid #3e7ec2;
          margin-bottom: 5px; }
          @media only screen and (max-width: 580px) {
            #contents .areaInfomation .areaNewsBlog .areaEntry .title {
              font-size: 20px;
              font-size: 2rem; } }
        #contents .areaInfomation .areaNewsBlog .areaEntry .cosTxtRight {
          margin-top: 10px; }
      #contents .areaInfomation .areaNewsBlog .areaBlog .list dd:before {
        content: "\f0da";
        font-family: FontAwesome;
        color: #0b5cb2; }
      @media only screen and (max-width: 580px) {
        #contents .areaInfomation .areaNewsBlog {
          float: none;
          width: 100%; } }
    #contents .areaInfomation .areaFacebook {
      float: right;
      width: 31.8%; }
      @media only screen and (max-width: 580px) {
        #contents .areaInfomation .areaFacebook {
          margin: 10px auto 0;
          text-align: center;
          float: none;
          width: 100%; } }

@media only screen and (max-width: 768px) {
  #topContents {
    padding-left: 10px;
    padding-right: 10px; } }

#topContents .bnrHps {
  margin-bottom: 20px;
  text-align: center; }

/*----------------------------------------*/
#footer {
  background: #1b1b1b;
  overflow: hidden;
  color: #fff; }
  #footer a {
    color: #fff; }
  #footer .container:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  #footer .container .footer_sitemap {
    float: right;
    width: 66%;
    display: table;
    table-layout: fixed;
    padding: 35px 0; }
    #footer .container .footer_sitemap .bnrGyosha {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center; }
      #footer .container .footer_sitemap .bnrGyosha a {
        position: relative;
        display: block;
        padding: 15px;
        border: 2px solid #c9c9c9;
        border-radius: 5px;
        background: #fff;
        color: #333;
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
        font-size: 1.4rem; }
        #footer .container .footer_sitemap .bnrGyosha a:before {
          width: 50px;
          height: 45px;
          content: "";
          background: url(../img/foot/logoGyosha.png) no-repeat;
          background-size: 100% auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 10px;
          margin: auto; }
      #footer .container .footer_sitemap .bnrGyosha img {
        height: 50px; }
    #footer .container .footer_sitemap .unit {
      display: table-cell;
      border-left: 1px solid #484848;
      padding: 0 0 0 10px; }
      @media only screen and (max-width: 580px) {
        #footer .container .footer_sitemap .unit {
          border-left: none;
          display: block;
          padding: 0; }
          #footer .container .footer_sitemap .unit .first {
            margin-bottom: 0; } }
      #footer .container .footer_sitemap .unit a {
        text-decoration: none; }
      #footer .container .footer_sitemap .unit ul {
        margin-bottom: 25px; }
        @media only screen and (max-width: 580px) {
          #footer .container .footer_sitemap .unit ul:after {
            content: ".";
            display: block;
            clear: both;
            height: 0;
            visibility: hidden; } }
        #footer .container .footer_sitemap .unit ul li {
          margin-bottom: 15px;
          font-weight: bold; }
          @media only screen and (max-width: 580px) {
            #footer .container .footer_sitemap .unit ul li {
              margin: 0; } }
          #footer .container .footer_sitemap .unit ul li a:before {
            content: "\f138";
            font-family: FontAwesome;
            color: #0b5cb2;
            margin-right: 5px; }
          @media only screen and (max-width: 580px) {
            #footer .container .footer_sitemap .unit ul li a {
              width: 100%;
              float: none;
              display: block;
              padding: 10px;
              font-size: 14px;
              font-size: 1.4rem;
              border-bottom: 1px solid rgba(72, 72, 72, 0.56); } }
          #footer .container .footer_sitemap .unit ul li ul {
            margin-top: 5px; }
            @media only screen and (max-width: 580px) {
              #footer .container .footer_sitemap .unit ul li ul {
                border-bottom: 1px solid rgba(72, 72, 72, 0.56); } }
            #footer .container .footer_sitemap .unit ul li ul li {
              margin-left: 20px;
              margin-bottom: 5px;
              font-weight: normal; }
              @media only screen and (max-width: 580px) {
                #footer .container .footer_sitemap .unit ul li ul li a {
                  padding: 5px;
                  border-bottom: none; } }
              #footer .container .footer_sitemap .unit ul li ul li a:before {
                content: "\f0da";
                font-family: FontAwesome; }
        #footer .container .footer_sitemap .unit ul:last-child {
          margin-bottom: 0; }
    @media only screen and (max-width: 768px) {
      #footer .container .footer_sitemap {
        float: none;
        width: 100%; } }
    @media only screen and (max-width: 580px) {
      #footer .container .footer_sitemap {
        padding-top: 0px; } }
  #footer .container .footer_address {
    float: left;
    width: 30%;
    padding: 15px 0; }
    #footer .container .footer_address .sitelogo {
      padding: 50px 0; }
      @media only screen and (max-width: 580px) {
        #footer .container .footer_address .sitelogo {
          padding: 20px;
          text-align: center; }
          #footer .container .footer_address .sitelogo img {
            height: 25px; } }
    @media only screen and (max-width: 768px) {
      #footer .container .footer_address {
        float: none;
        width: 100%; }
        #footer .container .footer_address .sitelogo {
          padding: 20px 0; } }
  #footer .footer_copyright {
    padding: 30px 0;
    background: #000000;
    color: #fff; }
  #footer #pagetopBtn {
    position: fixed;
    z-index: 10;
    bottom: 30px;
    right: 30px; }
    @media only screen and (max-width: 580px) {
      #footer #pagetopBtn {
        position: static;
        text-align: center;
        padding-top: 20px; }
        #footer #pagetopBtn img {
          height: 60px; } }

/* CSS Document */
.container {
  width: auto;
  max-width: 1000px; }
  @media only screen and (min-width: 1000px) {
    .container {
      max-width: 1000px; } }
  @media only screen and (max-width: 979px) {
    .container {
      max-width: 768px; } }
  @media only screen and (max-width: 768px) {
    .container {
      width: auto;
      padding-right: 10px;
      padding-left: 10px; } }

@media only screen and (max-width: 768px) {
  .modPcOnly {
    display: none !important; } }

@media only screen and (max-width: 979px) {
  .modPcOnly_L {
    display: none !important; } }

@media only screen and (max-width: 580px) {
  .modPcOnly_S {
    display: none !important; } }

@media only screen and (max-width: 580px) {
  .modPcOnly_tel {
    display: none !important; } }

.modSpOnly {
  display: none !important; }
  @media only screen and (max-width: 768px) {
    .modSpOnly {
      display: block !important; } }

.modSpOnly_L {
  display: none !important; }
  @media only screen and (max-width: 979px) {
    .modSpOnly_L {
      display: block !important; } }

.modSpOnly_S {
  display: none !important; }
  @media only screen and (max-width: 580px) {
    .modSpOnly_S {
      display: block !important; } }

.modSpOnly_tel {
  display: none !important; }
  @media only screen and (max-width: 580px) {
    .modSpOnly_tel {
      display: inline !important; } }

/* font
------------------------------------------------------------*/
.fontSt_1 {
  font-family: 'Lato', sans-serif; }

/* block
------------------------------------------------------------*/
.section {
  margin-bottom: 60px; }
  .section:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  @media only screen and (max-width: 580px) {
    .section {
      margin-bottom: 40px; } }
  .section p {
    margin-bottom: 30px; }
    @media only screen and (max-width: 580px) {
      .section p {
        margin-bottom: 20px; } }

.section_S {
  margin-bottom: 40px; }
  .section_S:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  @media only screen and (max-width: 580px) {
    .section_S {
      margin-bottom: 20px; } }
  .section_S p {
    margin-bottom: 30px; }
    @media only screen and (max-width: 580px) {
      .section_S p {
        margin-bottom: 20px; } }

.pageDetail .section {
  margin-bottom: 60px; }
  .pageDetail .section:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }

.pageDetail .photo img {
  border-radius: 5px; }

@media only screen and (max-width: 580px) {
  .pageDetail .photo {
    width: 100% !important;
    float: none !important;
    text-align: center;
    margin-bottom: 10px; } }

@media only screen and (max-width: 580px) {
  .pageDetail .text {
    width: 100% !important;
    float: none !important; } }

.pageDetail .area_photoText_1 .photo {
  width: 35%;
  float: left; }
  .pageDetail .area_photoText_1 .photo img {
    border-radius: 10px; }

.pageDetail .area_photoText_1 .text {
  width: 60%;
  float: right; }
  @media only screen and (max-width: 580px) {
    .pageDetail .area_photoText_1 .text {
      text-align: center; } }

.pageDetail .area_photoText_1 .spec li {
  margin-bottom: 5px;
  font-weight: bold; }
  .pageDetail .area_photoText_1 .spec li strong {
    color: #0069ce; }

.pageDetail .area_photoText_1 .spec .fa-facebook-square {
  color: #00479d; }

.pageDetail .area_photoText_2 .photo {
  width: 30%;
  float: left; }

.pageDetail .area_photoText_2 .text {
  width: 65%;
  float: right; }
  @media only screen and (max-width: 580px) {
    .pageDetail .area_photoText_2 .text {
      text-align: center; } }

.pageDetail .area_photoText_2 .text_nophoto {
  width: 100%;
  float: none; }
  .pageDetail .area_photoText_2 .text_nophoto p {
    margin-bottom: 0; }

.pageDetail .area_message {
  border: 8px solid #ededed;
  border-radius: 10px;
  padding: 20px 50px; }
  @media only screen and (max-width: 580px) {
    .pageDetail .area_message {
      padding: 10px; } }
  .pageDetail .area_message .hl {
    font-size: 20px;
    font-size: 2rem;
    text-align: center;
    color: #3e7ec2;
    margin-bottom: 15px;
    padding: 5px 10px;
    /*
			background:url(../img/layout/line.png) repeat-x bottom left;
			*/
    background: url(../img/layout/bg.png) repeat top left; }
  .pageDetail .area_message p {
    margin-bottom: 0; }

.pageDetail .area_photoText_3 .photo {
  float: left;
  width: 60%; }
  .pageDetail .area_photoText_3 .photo ul {
    margin: 10px 0; }
    .pageDetail .area_photoText_3 .photo ul:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    .pageDetail .area_photoText_3 .photo ul li:nth-child(odd) {
      float: left;
      width: 48%; }
    .pageDetail .area_photoText_3 .photo ul li:nth-child(even) {
      float: right;
      width: 48%; }

.pageDetail .area_photoText_3 .text {
  float: right;
  width: 35%; }
  .pageDetail .area_photoText_3 .text dl dt {
    font-weight: bold;
    color: #3e7ec2; }
  .pageDetail .area_photoText_3 .text dl dd {
    margin-bottom: 15px; }

.pageDetail .area_point {
  /*
		.point {
			display:table-cell;
			.photo {
				margin:0 auto 20px;
				text-align:center;
				img {
					border-radius:10px;
				}
				
				@include tabletP{
				}
				@include sphoneP{
				}
			}
			.text {
			}
			@include sphoneP{
				display:block;
				border-bottom:1px dotted #ccc;
				margin-bottom:20px;
				padding-bottom:10px;
				@include clearfix();
				li:last-child {
					
				}
			}

		}
*/ }
  .pageDetail .area_point .modTitle03 {
    font-size: 18px;
    font-size: 1.8rem;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 10px; }
    .pageDetail .area_point .modTitle03 .fontSt_1 {
      font-size: 26px;
      font-size: 2.6rem;
      vertical-align: baseline; }
  .pageDetail .area_point .content {
    display: table;
    table-layout: fixed;
    border-spacing: 15px 0;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .pageDetail .area_point .content {
        border-spacing: 15px 0; } }
    @media only screen and (max-width: 580px) {
      .pageDetail .area_point .content {
        display: block; } }
  .pageDetail .area_point .point {
    margin-bottom: 10px; }
    .pageDetail .area_point .point:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    .pageDetail .area_point .point .photo {
      float: left;
      width: 40%; }
    .pageDetail .area_point .point .text {
      float: right;
      width: 55%; }

.section_modCompany {
  border: 1px dotted #5b9ad6;
  padding: 30px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 580px) {
    .section_modCompany {
      padding: 20px; } }
  .section_modCompany:last-child {
    margin-bottom: 50px; }
  .section_modCompany .modTitle01 {
    margin: -20px -20px 20px;
    text-align: center;
    border-top: none; }
  .section_modCompany .modTitle02 {
    background: none;
    padding: 0;
    color: #0069ce;
    font-size: 18px;
    font-size: 1.8rem; }
    @media only screen and (max-width: 580px) {
      .section_modCompany .modTitle02 {
        font-size: 16px;
        font-size: 1.6rem; } }
  .section_modCompany p {
    font-size: 16px;
    font-size: 1.6rem; }
    @media only screen and (max-width: 580px) {
      .section_modCompany p {
        font-size: 14px;
        font-size: 1.4rem; } }
  .section_modCompany .modCompany_bold {
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.9;
    margin-bottom: 0; }
    @media only screen and (max-width: 580px) {
      .section_modCompany .modCompany_bold {
        font-size: 18px;
        font-size: 1.8rem; } }
  .section_modCompany .modCompany_bold2 {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.9;
    margin-bottom: 0; }
    @media only screen and (max-width: 580px) {
      .section_modCompany .modCompany_bold2 {
        font-size: 14px;
        font-size: 1.4rem; } }
  .section_modCompany .modCompany_ul li {
    font-size: 16px;
    font-size: 1.6rem;
    position: relative;
    font-weight: bold;
    margin-bottom: 18px;
    padding-left: 20px; }
    @media only screen and (max-width: 580px) {
      .section_modCompany .modCompany_ul li {
        font-size: 14px;
        font-size: 1.4rem; } }
  .section_modCompany .modCompany_ul li:before {
    content: "\f0da";
    font-family: FontAwesome;
    position: absolute;
    top: -3px;
    left: 0px;
    color: #0069ce; }
  .section_modCompany .modCompany_ul li:last-child {
    margin-bottom: 0; }

.section_modMessage {
  background: url(../img/contents/company/messageBack.png) repeat top left; }
  .section_modMessage .text {
    float: right;
    width: 75%; }
    @media only screen and (max-width: 580px) {
      .section_modMessage .text {
        float: none;
        width: 100%; } }
  .section_modMessage .photo {
    float: left;
    width: 25%; }
    @media only screen and (max-width: 580px) {
      .section_modMessage .photo {
        float: none;
        width: 50%;
        margin: 0px auto 10px; } }
  .section_modMessage .modTitle06 {
    font-size: 18px;
    font-size: 1.8rem; }
  .section_modMessage .lineText {
    background: url(../img/contents/company/line.png) repeat bottom left;
    font-size: 15px;
    line-height: 35px;
    padding: 0 10px;
    margin-bottom: 0; }
    @media only screen and (max-width: 580px) {
      .section_modMessage .lineText {
        padding: 5px; } }

.boxContact {
  border: 7px solid #3e7ec2;
  background: #fff;
  padding: 20px;
  margin-bottom: 50px;
  text-align: center; }
  @media only screen and (max-width: 580px) {
    .boxContact br {
      display: none; } }
  .boxContact .title {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 20px; }
  .boxContact .add {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #0069ce; }
    .boxContact .add strong {
      font-size: 22px;
      display: block; }
  .boxContact_side {
    border-radius: 3px; }

.recruitList .title {
  font-size: 24px;
  border-top: none;
  padding-left: 0;
  margin-bottom: 10px; }
  @media only screen and (max-width: 580px) {
    .recruitList .title {
      font-size: 18px;
      font-size: 1.8rem; } }

.recruitList .modTxt {
  margin-bottom: 30px; }

.area_mainvisual {
  margin-bottom: 40px; }
  @media only screen and (max-width: 580px) {
    .area_mainvisual {
      margin-bottom: 20px; } }
  .area_mainvisual .visual {
    margin-bottom: 20px; }
  .area_mainvisual .catch {
    text-align: center;
    margin: 50px 0;
    font-size: 25px;
    font-size: 2.5rem; }
    @media only screen and (max-width: 580px) {
      .area_mainvisual .catch {
        margin: 20px 0;
        font-size: 18px;
        font-size: 1.8rem; } }

.area_tenatsList:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.area_tenatsList li {
  counter-increment: num;
  width: 31%;
  margin: 0 3% 15px 0;
  float: left;
  border: 2px solid #c9c9c9;
  border-radius: 5px;
  box-sizing: border-box; }
  .area_tenatsList li:nth-child(3n) {
    margin-right: 0;
    float: right; }
  @media only screen and (max-width: 580px) {
    .area_tenatsList li {
      width: 100%;
      margin: 0 0 15px 0; } }

.area_tenatsList a {
  text-decoration: none; }

.area_tenatsList dl dt {
  border-radius: 3px 3px 0 0;
  background: #3e7ec2;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold; }
  .area_tenatsList dl dt a {
    display: block;
    color: #fff;
    padding: 20px 5px; }
    @media only screen and (max-width: 580px) {
      .area_tenatsList dl dt a {
        padding: 10px 5px;
        font-size: 18px;
        font-size: 1.8rem; } }

.area_tenatsList dl i:before {
  display: block;
  font-size: 30px;
  font-weight: normal; }
  @media only screen and (max-width: 580px) {
    .area_tenatsList dl i:before {
      font-size: 20px;
      display: inline; } }

.area_tenatsList dl dd {
  position: relative;
  border-bottom: 1px dotted #ccc; }
  .area_tenatsList dl dd span {
    color: #ccc;
    font-weight: bold; }
  .area_tenatsList dl dd img {
    float: left;
    margin-right: 10px; }
  .area_tenatsList dl dd a {
    display: block;
    padding: 10px; }
    .area_tenatsList dl dd a:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  .area_tenatsList dl dd a:hover {
    text-decoration: underline; }

.area_tenatsList dl dd:after {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  top: -3px;
  right: 10px;
  color: #ededed;
  font-size: 36px; }

.area_tenatsList dl dd:last-child {
  border-bottom: none; }

.area_newSeminar {
  border: 2px solid #ef7c00;
  padding: 20px; }
  .area_newSeminar .title {
    font-size: 18px;
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px; }
    .area_newSeminar .title i {
      margin-right: 5px;
      color: #ef7c00; }
      @media only screen and (max-width: 580px) {
        .area_newSeminar .title i {
          display: block; } }
    .area_newSeminar .title i:before {
      font-size: 25px; }
  .area_newSeminar .list li {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee; }
    .area_newSeminar .list li:last-child {
      border-bottom: none; }

.area_sunPoint {
  margin-bottom: 40px; }
  .area_sunPoint .modTitle06 {
    font-size: 30px;
    font-size: 3rem; }
    @media only screen and (max-width: 580px) {
      .area_sunPoint .modTitle06 {
        font-size: 20px;
        font-size: 2rem; } }
  .area_sunPoint .txt {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 30px; }
    @media only screen and (max-width: 580px) {
      .area_sunPoint .txt {
        font-size: 18px;
        font-size: 1.8rem; } }
  .area_sunPoint .list_point {
    background: #3e7ec2;
    padding: 20px 0; }
    .area_sunPoint .list_point:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    .area_sunPoint .list_point li {
      position: relative;
      float: left;
      width: 22%;
      margin-right: 3.5%;
      margin-bottom: 20px;
      background: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      text-align: center; }
      @media only screen and (max-width: 580px) {
        .area_sunPoint .list_point li {
          width: 47%;
          margin-right: 5%; } }
      .area_sunPoint .list_point li a {
        display: block;
        padding: 20px 20px 40px;
        text-decoration: none; }
        .area_sunPoint .list_point li a .num {
          font-size: 30px;
          display: inline-block;
          padding: 0 10px 5px;
          margin-bottom: 20px;
          color: #0069ce;
          font-weight: bold;
          border-bottom: 2px solid #0069ce; }
          @media only screen and (max-width: 580px) {
            .area_sunPoint .list_point li a .num {
              font-size: 20px; } }
        .area_sunPoint .list_point li a .title {
          display: block;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.4; }
          @media only screen and (max-width: 580px) {
            .area_sunPoint .list_point li a .title {
              font-size: 15px; } }
        .area_sunPoint .list_point li a i {
          position: absolute;
          bottom: 10px;
          color: #d7e1f0;
          font-size: 20px;
          margin-left: -10px; }
    .area_sunPoint .list_point li:nth-child(4n) {
      margin-right: 0; }
      @media only screen and (max-width: 580px) {
        .area_sunPoint .list_point li:nth-child(4n) {
          margin-right: 3.5%; } }
    @media only screen and (max-width: 580px) {
      .area_sunPoint .list_point li:nth-child(2n) {
        margin-right: 0; } }

.area_sunPoint_section section {
  margin-bottom: 30px;
  border-bottom: 2px solid #d7e1f0;
  padding-bottom: 20px;
  font-size: 15px;
  font-size: 1.5rem; }
  .area_sunPoint_section section:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }

.area_sunPoint_section .title {
  font-weight: bold;
  margin-bottom: 10px; }
  @media only screen and (max-width: 580px) {
    .area_sunPoint_section .title {
      text-align: center; } }
  .area_sunPoint_section .title .num {
    display: inline-block;
    border-bottom: 2px solid #0069ce;
    color: #3e7ec2;
    font-size: 40px;
    text-align: center;
    margin: 0 10px 0 0; }
    @media only screen and (max-width: 580px) {
      .area_sunPoint_section .title .num {
        margin-bottom: 10px; } }
  .area_sunPoint_section .title .hl {
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    font-size: 30px;
    font-size: 3rem; }
    @media only screen and (max-width: 580px) {
      .area_sunPoint_section .title .hl {
        display: block;
        font-size: 20px;
        font-size: 2rem; } }

.area_sunPoint_section .txt {
  width: 60%;
  float: left; }
  @media only screen and (max-width: 580px) {
    .area_sunPoint_section .txt {
      width: 100%;
      float: none; } }

.area_sunPoint_section .photo {
  width: 35%;
  float: right; }
  @media only screen and (max-width: 580px) {
    .area_sunPoint_section .photo {
      width: 100%;
      float: none;
      text-align: center; } }

.area_sunPoint_section .txt_center {
  text-align: center; }

.area_sunPoint_section .point02_blue {
  display: inline-block;
  background: #3e7ec2;
  vertical-align: text-bottom;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-size: 25px;
  font-size: 2.5rem; }
  @media only screen and (max-width: 580px) {
    .area_sunPoint_section .point02_blue {
      vertical-align: bottom;
      font-size: 20px;
      font-size: 2rem; } }

.area_sunPoint_section .point02_percent {
  display: inline-block;
  font-size: 45px;
  font-size: 4.5rem; }
  .area_sunPoint_section .point02_percent span {
    font-size: 75px;
    font-size: 7.5rem; }
  @media only screen and (max-width: 580px) {
    .area_sunPoint_section .point02_percent {
      display: block;
      font-size: 70px;
      font-size: 7rem; } }

.area_sunPoint_section .point02_rate {
  position: relative;
  text-align: center; }
  .area_sunPoint_section .point02_rate p {
    margin-bottom: 0; }
  .area_sunPoint_section .point02_rate .inner {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
    width: 180px; }
    @media only screen and (max-width: 768px) {
      .area_sunPoint_section .point02_rate .inner {
        line-height: 1.2; } }
    @media only screen and (max-width: 580px) {
      .area_sunPoint_section .point02_rate .inner {
        line-height: 1.5; } }
  .area_sunPoint_section .point02_rate .label {
    font-weight: bold; }
  .area_sunPoint_section .point02_rate .value {
    color: #4a4a4a;
    font-size: 30px;
    font-size: 3rem; }
    .area_sunPoint_section .point02_rate .value span {
      font-size: 50px;
      font-size: 5rem; }
  .area_sunPoint_section .point02_rate .sunhome {
    border-bottom: 1px solid #959595;
    margin-bottom: 10px;
    padding-bottom: 5px; }
    .area_sunPoint_section .point02_rate .sunhome .label {
      font-size: 20px;
      font-size: 2rem;
      color: #3e7ec2; }
  .area_sunPoint_section .point02_rate .okayama .label {
    font-size: 15px;
    font-size: 1.5rem;
    color: #549040; }

#area_recruitTop {
  padding: 50px 0;
  background: url(../img/contents/recruit/back.jpg) repeat top left; }
  #area_recruitTop .section {
    border-radius: 10px;
    background: #fff;
    padding: 30px 20px; }
  #area_recruitTop .area_title {
    margin: 0 -20px 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #3e7ec2;
    text-align: center;
    color: #3e7ec2;
    font-size: 30px;
    font-size: 3rem; }
    @media only screen and (max-width: 580px) {
      #area_recruitTop .area_title {
        font-size: 25px;
        font-size: 2.5rem; } }
    #area_recruitTop .area_title .hl {
      font-weight: bold; }
  #area_recruitTop .modContentsNavi {
    margin: -30px -20px 20px; }
    #area_recruitTop .modContentsNavi ul {
      box-shadow: none;
      table-layout: fixed; }

.workList {
  margin: 0 30px 60px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media only screen and (max-width: 768px) {
    .workList {
      margin: 0 0 30px; } }
  .workList .title {
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.58);
    font-size: 25px;
    font-size: 2.5rem;
    border-bottom: 3px solid; }
    .workList .title .hl {
      font-weight: bold; }
  .workList .photo {
    float: left;
    width: 30%; }
    @media only screen and (max-width: 580px) {
      .workList .photo {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px; } }
  .workList .text {
    /*
		float:right;
		width:65%;
		*/ }
    @media only screen and (max-width: 580px) {
      .workList .text {
        float: none;
        width: 100%; } }
  .workList .back {
    margin-bottom: 20px; }
    .workList .back:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  .workList .staff {
    position: relative;
    padding: 20px 20px 10px 20px;
    margin: 0 auto;
    width: 80%;
    border-radius: 10px;
    border: 3px solid;
    box-sizing: border-box; }
    .workList .staff:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    @media only screen and (max-width: 768px) {
      .workList .staff {
        width: 100%; } }
    .workList .staff:before {
      content: "\f0de";
      font-family: FontAwesome;
      position: absolute;
      top: 3px;
      left: 50%;
      font-size: 50px;
      margin-left: -25px;
      line-height: 0; }
    .workList .staff .photo {
      float: left;
      width: 24%;
      text-align: center; }
      @media only screen and (max-width: 580px) {
        .workList .staff .photo {
          float: none;
          width: 50%;
          margin: 0 auto 10px; } }
    .workList .staff .text {
      float: right;
      width: 73%; }
      @media only screen and (max-width: 580px) {
        .workList .staff .text {
          float: none;
          width: 100%; } }
      .workList .staff .text .hl {
        font-weight: bold;
        font-size: 18px;
        font-size: 1.8rem;
        margin-bottom: 10px; }
        @media only screen and (max-width: 580px) {
          .workList .staff .text .hl {
            text-align: center; } }
      .workList .staff .text p {
        margin-bottom: 10px; }
      .workList .staff .text p.cosTxtRight {
        margin-bottom: 0;
        font-weight: bold; }
  .workList_cat01 .title {
    border-color: #b6ca58;
    background: rgba(182, 202, 88, 0.3); }
  .workList_cat01 .staff {
    border-color: #b6ca58;
    background: rgba(182, 202, 88, 0.4); }
  .workList_cat01 .staff:before {
    color: #b6ca58; }
  .workList_cat02 .title {
    border-color: #e9aebf;
    background: rgba(233, 174, 191, 0.3); }
  .workList_cat02 .staff {
    border-color: #e9aebf;
    background: rgba(233, 174, 191, 0.4); }
  .workList_cat02 .staff:before {
    color: #e9aebf; }
  .workList_cat03 .title {
    border-color: #eed46c;
    background: rgba(238, 212, 108, 0.3); }
  .workList_cat03 .staff {
    border-color: #eed46c;
    background: rgba(238, 212, 108, 0.4); }
  .workList_cat03 .staff:before {
    color: #eed46c; }
  .workList_cat04 .title {
    border-color: #96cbdc;
    background: rgba(150, 203, 220, 0.3); }
  .workList_cat04 .staff {
    border-color: #96cbdc;
    background: rgba(150, 203, 220, 0.4); }
  .workList_cat04 .staff:before {
    color: #96cbdc; }

.section_renotta {
  border: 1px solid #000; }
  .section_renotta .unit {
    padding: 50px;
    border-bottom: 1px dashed #666; }
    @media only screen and (max-width: 580px) {
      .section_renotta .unit {
        padding: 20px; } }
    .section_renotta .unit:last-child {
      border-bottom: none; }

.akichi_block {
  border: 2px solid #3e7ec2;
  padding: 20px;
  margin-bottom: 40px; }
  .akichi_block h4 {
    text-align: center;
    margin: 20px 0; }
  .akichi_block p {
    margin-bottom: 20px; }
  .akichi_block .img {
    margin: -20px -20px 0; }

.modCaution {
  border: 2px solid #e60012;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #e60012;
  font-size: 16px;
  font-size: 1.6rem; }

.modCaution2 {
  border: 2px solid #0069ce;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #0069ce;
  font-size: 16px;
  font-size: 1.6rem; }

.modTxtBlock {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media only screen and (max-width: 580px) {
    .modTxtBlock {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-size: 1.4rem; } }

.modTxtBlock2 {
  background: #eee;
  padding: 20px;
  margin: 20px 0;
  font-size: 14px;
  font-size: 1.4rem; }

.modBlockTel {
  border: 7px solid #3e7ec2;
  background: #fff;
  padding: 20px;
  margin-bottom: 50px;
  text-align: center; }
  .modBlockTel .title {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 15px; }
  .modBlockTel .add {
    color: #0069ce;
    font-size: 22px;
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 10px; }
    .modBlockTel .add .label:before {
      display: inline-block;
      font-size: 25px;
      font-size: 2.5rem;
      content: "\f095";
      font-family: FontAwesome;
      color: #0b5cb2;
      margin: 0 10px 0px 0; }
    .modBlockTel .add .label:after {
      content: "："; }
    .modBlockTel .add .number {
      font-size: 26px;
      font-size: 2.6rem; }
  .modBlockTel .txt {
    color: #0069ce;
    font-size: 14px;
    font-size: 1.4rem; }
  .modBlockTel_bottom {
    margin-bottom: 0; }
    @media only screen and (max-width: 580px) {
      .modBlockTel_bottom {
        display: none; } }
  .modBlockTel_side {
    padding: 15px 10px;
    border-radius: 5px; }
    .modBlockTel_side .title {
      font-size: 14px;
      font-size: 1.4rem;
      margin-bottom: 10px; }
      @media only screen and (max-width: 580px) {
        .modBlockTel_side .title span {
          display: block; } }
    .modBlockTel_side .add {
      font-size: 16px;
      font-size: 1.6rem; }
      .modBlockTel_side .add .label {
        display: block; }
        .modBlockTel_side .add .label:before {
          font-size: 16px;
          font-size: 1.6rem;
          margin-right: 5px; }
        .modBlockTel_side .add .label:after {
          display: none; }
      .modBlockTel_side .add .number {
        font-size: 22px;
        font-size: 2.2rem; }
    .modBlockTel_side .txt {
      font-size: 12px;
      font-size: 1.2rem; }

.recruitList + .modBlockTel_bottom {
  margin-top: 30px; }

.modLoginBlock form {
  max-width: 400px;
  margin: 0 auto; }

.modLoginBlock .modTbl02 {
  margin-top: 20px;
  margin-bottom: 20px; }
  .modLoginBlock .modTbl02 th {
    width: 100px;
    vertical-align: middle; }
  .modLoginBlock .modTbl02 tr th,
  .modLoginBlock .modTbl02 tr td {
    padding: 10px 10px;
    border-bottom: none; }
  @media only screen and (max-width: 580px) {
    .modLoginBlock .modTbl02 tr th {
      padding-bottom: 0; } }

.modLoginBlock .txt {
  margin-top: 20px;
  text-align: center; }

.seminar_loginBlock {
  background: url(../img/contents/owners/seminar/loginBack.jpg) no-repeat bottom right;
  border-bottom: 3px solid #d1e7f4;
  margin-bottom: 20px; }
  @media only screen and (max-width: 580px) {
    .seminar_loginBlock {
      background: url(../img/contents/owners/seminar/loginBack02.jpg) repeat top left; } }
  .seminar_loginBlock .inner {
    padding: 50px 10px;
    text-align: center; }
    @media only screen and (max-width: 580px) {
      .seminar_loginBlock .inner {
        padding: 20px 20px 10px; } }
  .seminar_loginBlock .head {
    display: inline-block;
    position: relative;
    padding-left: 120px;
    text-align: left;
    margin-bottom: 20px;
    font-weight: bold; }
    @media only screen and (max-width: 580px) {
      .seminar_loginBlock .head {
        padding-left: 0;
        margin-bottom: 10px; } }
    .seminar_loginBlock .head:before {
      position: absolute;
      top: -10px;
      left: 0;
      content: "";
      display: block;
      background: url(../img/contents/owners/seminar/mark.png) no-repeat top left;
      width: 99px;
      height: 99px; }
      @media only screen and (max-width: 580px) {
        .seminar_loginBlock .head:before {
          top: -5px;
          width: 50px;
          height: 50px;
          background-size: 100% auto; } }
    .seminar_loginBlock .head .title {
      color: #ef5400;
      font-weight: bold;
      font-size: 22px;
      font-size: 2.2rem;
      margin-bottom: 5px; }
      @media only screen and (max-width: 580px) {
        .seminar_loginBlock .head .title {
          font-size: 16px;
          font-size: 1.6rem;
          padding-left: 60px; } }
    .seminar_loginBlock .head .txt {
      font-size: 16px;
      font-size: 1.6rem; }
      @media only screen and (max-width: 580px) {
        .seminar_loginBlock .head .txt {
          font-size: 14px;
          font-size: 1.4rem;
          text-align: left; } }
      .seminar_loginBlock .head .txt span {
        display: block; }
        @media only screen and (max-width: 580px) {
          .seminar_loginBlock .head .txt span {
            display: inline; } }
  .seminar_loginBlock .body .btn {
    margin-bottom: 20px; }
  .seminar_loginBlock .body .txt {
    font-size: 14px;
    font-size: 1.4rem; }
    @media only screen and (max-width: 580px) {
      .seminar_loginBlock .body .txt {
        font-size: 12px;
        font-size: 1.2rem; } }
  .seminar_loginBlock .loginBtn {
    max-width: 250px;
    display: block;
    background: #ef5400;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.7); }
    .seminar_loginBlock .loginBtn:hover {
      opacity: 0.8; }
    @media only screen and (max-width: 580px) {
      .seminar_loginBlock .loginBtn {
        padding: 15px 10px; } }

.seminar_videoBlock {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%; }
  @media only screen and (max-width: 580px) {
    .seminar_videoBlock {
      margin-bottom: 30px; } }
  .seminar_videoBlock video {
    margin: auto; }
    @media only screen and (max-width: 768px) {
      .seminar_videoBlock video {
        width: 100%;
        height: auto; } }
  .seminar_videoBlock video,
  .seminar_videoBlock iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.seminar_documentBlock {
  margin-bottom: 30px;
  border-top: 1px solid #bfbfbf; }
  .seminar_documentBlock th, .seminar_documentBlock td {
    padding: 12px 0;
    border-bottom: 1px solid #bfbfbf;
    font-size: 14px;
    font-size: 1.4rem; }
    @media only screen and (max-width: 580px) {
      .seminar_documentBlock th, .seminar_documentBlock td {
        display: block; } }
  .seminar_documentBlock th .file {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px; }
    .seminar_documentBlock th .file:before {
      content: "";
      display: inline-block;
      height: 22px;
      width: 20px;
      margin-right: 5px;
      margin-bottom: -5px;
      background: url(../img/layout/file_default.png) no-repeat top left;
      background-size: 100% auto; }
    .seminar_documentBlock th .file_excel:before {
      background: url(../img/layout/file_excel.png) no-repeat top left;
      background-size: 100% auto; }
    .seminar_documentBlock th .file_pdf:before {
      background: url(../img/layout/file_pdf.png) no-repeat top left;
      background-size: 100% auto; }
    .seminar_documentBlock th .file_ppt:before {
      background: url(../img/layout/file_ppt.png) no-repeat top left;
      background-size: 100% auto; }
    .seminar_documentBlock th .file_word:before {
      background: url(../img/layout/file_word.png) no-repeat top left;
      background-size: 100% auto; }
    .seminar_documentBlock th .file_zip:before {
      background: url(../img/layout/file_zip.png) no-repeat top left;
      background-size: 100% auto; }
  @media only screen and (max-width: 580px) {
    .seminar_documentBlock th {
      padding-bottom: 0;
      border-bottom: none; } }
  .seminar_documentBlock .btn {
    width: 200px;
    text-align: right; }
    @media only screen and (max-width: 580px) {
      .seminar_documentBlock .btn {
        width: 100%;
        padding-top: 10px; }
        .seminar_documentBlock .btn a {
          padding: 5px;
          display: block;
          text-align: center; } }

/* #parts_CMS_css
------------------------------------------------------------*/
#parts_CMS_css .modTitle03 {
  font-weight: bold;
  color: #0069ce;
  border-top: none;
  padding: 0;
  margin-bottom: 15px;
  font-size: 19px;
  font-size: 1.9rem; }
  @media only screen and (max-width: 580px) {
    #parts_CMS_css .modTitle03 {
      font-size: 16px;
      font-size: 1.6rem; } }

#parts_CMS_css .modTitle03_02 {
  background: #3e7ec2;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #fff;
  font-size: 17px;
  font-size: 1.7rem; }
  @media only screen and (max-width: 580px) {
    #parts_CMS_css .modTitle03_02 {
      font-size: 15px;
      font-size: 1.5rem; } }

#parts_CMS_css .modTitle03_03 {
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 2px solid #0069ce;
  font-size: 16px;
  font-size: 1.6rem; }
  @media only screen and (max-width: 580px) {
    #parts_CMS_css .modTitle03_03 {
      font-size: 14px;
      font-size: 1.4rem; } }

#news_parts_CMS_css #parts_CMS_css .modTitle03 {
  font-weight: bold;
  color: #0069ce;
  border-top: none;
  padding: 0;
  margin-bottom: 15px;
  font-size: 25px;
  font-size: 2.5rem; }
  @media only screen and (max-width: 580px) {
    #news_parts_CMS_css #parts_CMS_css .modTitle03 {
      font-size: 18px;
      font-size: 1.8rem; } }

#news_parts_CMS_css #parts_CMS_css .modTitle03_02 {
  background: none;
  font-weight: bold;
  border-top: none;
  padding: 0;
  color: #333;
  margin-bottom: 15px;
  font-size: 19px;
  font-size: 1.9rem; }
  @media only screen and (max-width: 580px) {
    #news_parts_CMS_css #parts_CMS_css .modTitle03_02 {
      font-size: 16px;
      font-size: 1.6rem; } }

#news_parts_CMS_css #parts_CMS_css .modTitle03_03 {
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 2px solid #0069ce;
  font-size: 15px;
  font-size: 1.5rem; }
  @media only screen and (max-width: 580px) {
    #news_parts_CMS_css #parts_CMS_css .modTitle03_03 {
      font-size: 14px;
      font-size: 1.4rem; } }

@media only screen and (max-width: 768px) {
  #parts_CMS_css table tbody,
  #parts_CMS_css table tr,
  #parts_CMS_css table th,
  #parts_CMS_css table td {
    display: block; } }

#parts_CMS_css table th, #parts_CMS_css table td {
  border: solid 1px #CCCCCC;
  padding: 10px; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css table th, #parts_CMS_css table td {
      padding: 10px 10px; } }

#parts_CMS_css table th {
  width: 100px;
  color: #fff;
  font-weight: bold;
  background: #3e7ec2;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.34); }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css table th {
      width: auto; } }

#parts_CMS_css iframe {
  width: 100%;
  height: 400px; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css iframe {
      height: 250px; } }

#parts_CMS_css ul {
  margin-left: 2em; }
  #parts_CMS_css ul li {
    list-style: disc; }
    #parts_CMS_css ul li:before {
      display: inline-block;
      content: "・";
      text-indent: -1em; }

#parts_CMS_css ol {
  margin-left: 2.2em; }
  #parts_CMS_css ol li {
    list-style: decimal; }

#parts_CMS_css strong,
#parts_CMS_css th {
  font-weight: bold; }

#parts_CMS_css .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

#parts_CMS_css .modItBlock_imgL,
#parts_CMS_css .modItBlock_imgR {
  margin-bottom: 20px; }
  #parts_CMS_css .modItBlock_imgL:after,
  #parts_CMS_css .modItBlock_imgR:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }

#parts_CMS_css .modItBlock_imgL .imageBlock,
#parts_CMS_css .modItBlock_imgR .imageBlock {
  max-width: 400px; }

#parts_CMS_css .modItBlock_imgR .imageBlock img {
  padding: 4px;
  margin: 0 0 2px 7px;
  display: inline; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css .modItBlock_imgR .imageBlock img {
      display: block; } }

#parts_CMS_css .modItBlock_imgL .imageBlock img {
  padding: 4px;
  margin: 0 7px 2px 0;
  display: inline; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css .modItBlock_imgL .imageBlock img {
      display: block; } }

#parts_CMS_css .modItBlock_imgR .imageBlock {
  float: right;
  margin-left: 20px; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css .modItBlock_imgR .imageBlock {
      float: none;
      text-align: center; } }

#parts_CMS_css .modItBlock_imgL .imageBlock {
  float: left;
  margin-right: 20px; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css .modItBlock_imgL .imageBlock {
      float: none;
      text-align: center; } }

#parts_CMS_css strong {
  font-weight: bold; }

#parts_CMS_css .modTxt {
  margin-bottom: 20px; }

#parts_CMS_css .modImg {
  margin-bottom: 20px;
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

#parts_CMS_css .btn {
  display: block;
  width: 100%;
  background-color: #0069ce;
  border-radius: 5px;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.7) inset;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.4);
  font-size: 15px;
  font-size: 1.5rem; }
  @media only screen and (max-width: 768px) {
    #parts_CMS_css .btn {
      padding: 10px; } }
  #parts_CMS_css .btn:hover {
    background-color: #3771af;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.7) inset; }
  #parts_CMS_css .btn_out {
    background-color: #999; }
    #parts_CMS_css .btn_out:hover {
      background-color: #8c8c8c; }

#parts_CMS_css .modBtn01 {
  margin: 10px auto; }
  #parts_CMS_css .modBtn01 + * {
    margin-top: 50px; }
    @media only screen and (max-width: 768px) {
      #parts_CMS_css .modBtn01 + * {
        margin-top: 30px; } }

/* wysiwyg
------------------------------------------------------------*/
.modParts {
  margin-bottom: 20px; }

.modPartsImage {
  text-align: center;
  color: #959595;
  font-size: 11px;
  font-size: 1.1rem; }
  .modPartsImage .caption {
    margin: 5px 0 0; }
  .modPartsImage a:hover {
    opacity: 0.8; }

.modPartsYoutube {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (max-width: 768px) {
    .modPartsYoutube {
      position: relative;
      width: 100%;
      padding-top: 56.25%; }
      .modPartsYoutube iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important; } }

/* font
------------------------------------------------------------*/
.fontSt_1 {
  font-family: 'Lato', sans-serif; }

/* btn
------------------------------------------------------------*/
.btnSt_1 {
  display: inline-block;
  padding: 3px 20px 3px 10px;
  background-color: #3e7ec2;
  border-radius: 5px;
  color: #fff;
  text-decoration: none; }
  .btnSt_1:hover {
    color: #fff;
    opacity: 0.8; }
  .btnSt_1:before {
    content: "\f138";
    font-family: FontAwesome;
    color: #fff;
    margin-right: 10px; }
  .btnSt_1.btnSt_1big {
    text-align: center;
    display: block;
    padding: 15px 10px;
    background-color: #0069ce;
    font-size: 15px;
    font-size: 1.5rem; }

.modBtn01 {
  display: block;
  width: 100%;
  background-color: #0069ce;
  border-radius: 5px;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.7) inset;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.4);
  font-size: 15px;
  font-size: 1.5rem; }
  @media only screen and (max-width: 768px) {
    .modBtn01 {
      padding: 10px; } }
  .modBtn01:hover {
    background-color: #3771af;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.7) inset;
    color: #fff; }
  .modBtn01_out {
    background-color: #999; }
    .modBtn01_out:hover {
      background-color: #8c8c8c; }
  .modBtn01_large {
    width: 400px;
    margin: auto auto 10px;
    font-size: 18px;
    font-size: 1.8rem; }
    @media only screen and (max-width: 768px) {
      .modBtn01_large {
        width: 100%; } }
  .modBtn01_xlarge {
    display: inline-block;
    width: auto;
    margin: auto;
    padding: 10px 30px;
    font-size: 24px;
    font-size: 2.4rem; }
    @media only screen and (max-width: 768px) {
      .modBtn01_xlarge {
        width: 100%; } }

.modBtn02 {
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 9px 10px;
  border: solid 1px #3e7ec2;
  box-sizing: border-box;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.7);
  color: #3e7ec2;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  font-size: 1.5rem; }
  @media only screen and (max-width: 768px) {
    .modBtn02 {
      padding: 8px; } }
  .modBtn02:hover {
    background-color: #3e7ec2;
    color: #fff; }
  .modBtn02_small {
    padding: 3px 10px;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: normal; }
    @media only screen and (max-width: 768px) {
      .modBtn02_small {
        padding: 8px; } }
  .modBtn02_edit:before {
    margin: 0 4px 0 0; }

.modBtnList li {
  width: 230px;
  margin: 0 auto; }

.modBtnList_large {
  margin: 30px auto; }
  .modBtnList_large li {
    width: auto;
    text-align: center; }

.modBtnList_column02 {
  position: relative;
  max-width: 370px;
  margin: 0 auto; }
  @media only screen and (max-width: 768px) {
    .modBtnList_column02 {
      max-width: none; } }
  .modBtnList_column02 li {
    width: 120px;
    max-width: none;
    margin: 0; }
    @media only screen and (max-width: 768px) {
      .modBtnList_column02 li {
        width: auto; } }
  .modBtnList_column02 .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px; }
    @media only screen and (max-width: 768px) {
      .modBtnList_column02 .right {
        position: static;
        width: auto;
        margin-bottom: 10px; } }

/* modForm
------------------------------------------------------------*/
.modFormBlock {
  border: 15px solid #ededed;
  border-radius: 10px;
  padding: 30px; }
  @media only screen and (max-width: 580px) {
    .modFormBlock {
      border: 10px solid #ededed;
      padding: 15px; } }
  .modFormBlock .modTitle01 {
    margin-bottom: 0; }

.modFormErr {
  margin: 5px 0 0;
  font-size: 14px;
  font-size: 1.4rem;
  color: #e60012;
  font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .modFormErr {
      font-size: 13px;
      font-size: 1.3rem; } }

.modFormErrTop {
  margin-bottom: 30px;
  border: 3px solid #e60012;
  border-radius: 10px; }
  .modFormErrTop .title {
    display: block;
    background: #e60012;
    font-weight: bold;
    font-size: 16px;
    font-size: 1.6rem;
    color: #fff;
    text-align: center; }
    .modFormErrTop .title:before {
      content: "\f071";
      display: inline-block;
      font-family: FontAwesome;
      margin-right: 5px;
      font-size: 20px;
      font-size: 2rem; }
  .modFormErrTop .list {
    padding: 10px 20px; }
    .modFormErrTop .list a:before {
      content: "\f0da";
      display: inline-block;
      font-family: FontAwesome;
      margin-right: 5px;
      font-size: 16px;
      font-size: 1.6rem; }

.modFormRequired {
  display: inline-block;
  background-color: #e60012;
  border-radius: 2px;
  padding: 0 8px;
  margin: 0 0 0 8px;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  vertical-align: middle; }
  @media only screen and (max-width: 768px) {
    .modFormRequired {
      padding: 1px 8px;
      display: inline-block;
      *display: inline;
      *zoom: 1; } }

.modFormName:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.modFormName li {
  float: left;
  width: 40%;
  max-width: 210px;
  margin: 0 20px 0 0; }
  @media only screen and (max-width: 768px) {
    .modFormName li {
      width: 48%;
      min-width: 160px;
      margin: 0 4% 0 0; }
      .modFormName li:last-child {
        margin: 0; } }

.modFormName .txt {
  width: 2em;
  margin: 0 10px 0 0;
  height: 44px;
  line-height: 44px; }
  @media only screen and (max-width: 768px) {
    .modFormName .txt {
      min-width: 0;
      margin: 0 10px 10px 0;
      height: 33px;
      line-height: 33px;
      clear: both; } }

.modFormDay:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.modFormDay li {
  float: left;
  margin: 0 10px 0 0; }
  @media only screen and (max-width: 768px) {
    .modFormDay li {
      margin: 0 1% 0 0; }
      .modFormDay li:last-child {
        margin: 0; } }
  .modFormDay li selest {
    width: 100%; }

.modFormDay .title {
  width: 4em;
  margin: 0 10px 0 0;
  height: 44px;
  line-height: 44px;
  font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .modFormDay .title {
      min-width: 0;
      margin: 0 10px 10px 0;
      height: 33px;
      line-height: 33px;
      clear: both; } }
  @media only screen and (max-width: 580px) {
    .modFormDay .title {
      float: none;
      height: auto;
      line-height: 1;
      margin-bottom: 5px; } }

.modFormDay .txt {
  margin: 0 10px 0 0;
  height: 44px;
  line-height: 44px; }
  @media only screen and (max-width: 768px) {
    .modFormDay .txt {
      margin: 0 5px 10px 0;
      height: 33px;
      line-height: 33px; } }

.modFormChk:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.modFormChk li {
  display: inline-block;
  margin-right: 15px; }
  .modFormChk li input {
    margin-right: 5px; }
  @media only screen and (max-width: 768px) {
    .modFormChk li {
      display: block;
      margin: 3px 0; } }

.modFormChk_block li {
  display: block; }
  .modFormChk_block li:not(:last-child) {
    margin-bottom: 5px; }

.modFormZip:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.modFormZip li {
  float: left; }

.modFormZip .input {
  width: 20%; }
  @media only screen and (max-width: 768px) {
    .modFormZip .input {
      width: 25%; } }

.modFormZip .txt {
  padding: 0.5em 8px 0;
  width: 1em;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .modFormZip .txt {
      padding-right: 4px;
      padding-left: 4px; } }

.modFormZip .btn {
  width: 20%;
  padding: 0 0 0 10px; }
  @media only screen and (max-width: 768px) {
    .modFormZip .btn {
      width: 35%; } }

.modFormAddr li {
  margin-bottom: 12px;
  height: 44px;
  line-height: 44px; }
  @media only screen and (max-width: 768px) {
    .modFormAddr li {
      height: 33px;
      line-height: 33px; } }

.modFormAddr .zip span {
  margin: 0 10px 0 0; }

.modFormAddr .zip input {
  max-width: 120px; }

.modFormTel:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.modFormTel li {
  float: left; }

.modFormTel .input {
  width: 20%;
  max-width: 100px; }
  @media only screen and (max-width: 768px) {
    .modFormTel .input {
      width: 27%; } }

.modFormTel .txt {
  padding: 0 4px 0;
  width: 1em;
  height: 44px;
  line-height: 44px;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .modFormTel .txt {
      height: 33px;
      line-height: 33px;
      padding-left: 4px;
      padding-right: 4px; } }

.modFormPolicy {
  margin-bottom: 40px;
  padding: 0 0 0 32px; }
  .modFormPolicy a {
    color: #333; }
  .modFormPolicy input {
    margin: 0 5px 0 0;
    display: none; }
  .modFormPolicy label {
    position: relative; }
    .modFormPolicy label:before {
      content: "";
      position: absolute;
      top: 0;
      left: -32px;
      margin: auto;
      background-color: #F9F9F9;
      border: solid 1px #CCCCCC;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      vertical-align: middle; }
  .modFormPolicy input:checked + label:after {
    content: "\f00c";
    font-family: FontAwesome;
    color: #e60012;
    position: absolute;
    left: -31px;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    font-size: 2rem; }

.modFormTxtComp {
  margin-bottom: 14px;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #e60012; }
  @media only screen and (max-width: 768px) {
    .modFormTxtComp {
      font-size: 18px;
      font-size: 1.8rem; } }

.modFormTxtSub {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
  font-size: 1.2rem; }

.modFormCheckList {
  margin: 20px 0;
  font-size: 14px;
  font-size: 1.4rem; }
  .modFormCheckList .txt {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px;
    font-size: 1.4rem; }
  .modFormCheckList .list {
    font-size: 14px;
    font-size: 1.4rem;
    border-top: 1px solid #eee; }
    .modFormCheckList .list li {
      padding: 15px 0 15px 30px;
      border-bottom: 1px solid #eee; }
      .modFormCheckList .list li input {
        margin-left: -25px; }

.modFormCopyBtn {
  margin: 15px 0 0px;
  padding-bottom: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  color: #999; }
  .modFormCopyBtn span {
    display: inline-block;
    width: 350px;
    margin-right: 10px; }
    @media only screen and (max-width: 580px) {
      .modFormCopyBtn span {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px; } }

.modFormSame_check {
  border: 1px solid #EAEAEA;
  background: rgba(62, 126, 194, 0.1);
  margin: 15px 0 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold; }
  .modFormSame_check label {
    display: block;
    padding: 10px 15px; }
    .modFormSame_check label input {
      margin-right: 10px; }

.mark_chusha .img {
  text-align: center;
  margin-bottom: 30px; }

.mark_chusha .selectList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media only screen and (max-width: 580px) {
    .mark_chusha .selectList {
      display: block; }
      .mark_chusha .selectList .label {
        width: 6em;
        display: inline-block; }
      .mark_chusha .selectList .list {
        margin-bottom: 10px; }
        .mark_chusha .selectList .list:not(:last-child) {
          padding-bottom: 10px;
          border-bottom: 1px dotted #ddd; } }

/* table
------------------------------------------------------------*/
.modTbl01, .modTbl05 {
  border: solid 1px #CCCCCC;
  table-layout: fixed;
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .modTbl01, .modTbl05 {
      display: block;
      margin-bottom: 15px;
      border-bottom: none; } }
  @media only screen and (max-width: 768px) {
    .modTbl01 tbody, .modTbl05 tbody,
    .modTbl01 tr,
    .modTbl05 tr,
    .modTbl01 th,
    .modTbl05 th,
    .modTbl01 td,
    .modTbl05 td {
      display: block; } }
  
  .modTbl01 th,
  .modTbl05 th,
  .modTbl01 td,
  .modTbl05 td {
    border-bottom: solid 1px #CCCCCC;
    padding: 18px 30px; }
    @media only screen and (max-width: 768px) {
      
      .modTbl01 th,
      .modTbl05 th,
      .modTbl01 td,
      .modTbl05 td {
        padding: 10px; } }
  
  .modTbl01 th,
  .modTbl05 th {
    background-color: #F4F4F4;
    width: 170px; }
    @media only screen and (max-width: 768px) {
      
      .modTbl01 th,
      .modTbl05 th {
        width: auto; } }

.modTbl02 {
  table-layout: fixed;
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .modTbl02 {
      display: block;
      margin-bottom: 15px; } }
  @media only screen and (max-width: 768px) {
    .modTbl02 tbody,
    .modTbl02 tr,
    .modTbl02 th,
    .modTbl02 td {
      display: block; } }
  .modTbl02 th, .modTbl02 td {
    border-bottom: solid 2px #EAEAEA;
    padding: 20px 15px; }
    @media only screen and (max-width: 768px) {
      .modTbl02 th, .modTbl02 td {
        padding: 10px 0; } }
  .modTbl02 th {
    width: 220px;
    vertical-align: top;
    font-weight: bold;
    font-size: 15px;
    font-size: 1.5rem; }
    @media only screen and (max-width: 768px) {
      .modTbl02 th {
        width: auto;
        border-bottom-style: dotted; } }
  .modTbl02 td {
    font-size: 14px;
    font-size: 1.4rem; }
  .modTbl02_title th {
    width: 100%;
    padding: 0; }
  .modTbl02_title .modTitle02 {
    margin-bottom: 0; }
  .modTbl02_title02 th {
    width: 100%;
    padding: 0; }
  .modTbl02_title02 .modTitle02 {
    margin-bottom: 0; }
  .modTbl02_title_bottom {
    border-bottom: 5px solid #3e7ec2; }

@media only screen and (max-width: 768px) {
  .modTbl03 tbody,
  .modTbl03 tr,
  .modTbl03 th,
  .modTbl03 td {
    display: block; } }

.modTbl03 th, .modTbl03 td {
  border-bottom: solid 1px #CCCCCC;
  padding: 15px 0px; }
  @media only screen and (max-width: 768px) {
    .modTbl03 th, .modTbl03 td {
      padding: 10px 0; } }

.modTbl03 th {
  width: 170px;
  color: #0069ce;
  font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .modTbl03 th {
      width: auto; } }
  @media only screen and (max-width: 580px) {
    .modTbl03 th {
      border-bottom: none;
      padding-bottom: 0; } }

.modTbl03 tr:last-child th, .modTbl03 tr:last-child td {
  border-bottom: none; }

@media only screen and (max-width: 768px) {
  .modTbl04 tbody,
  .modTbl04 tr,
  .modTbl04 th,
  .modTbl04 td {
    display: block; } }

.modTbl04 th, .modTbl04 td {
  border: solid 1px #CCCCCC;
  padding: 10px; }
  @media only screen and (max-width: 768px) {
    .modTbl04 th, .modTbl04 td {
      padding: 10px 10px; } }

.modTbl04 th {
  width: 100px;
  color: #fff;
  font-weight: bold;
  background: #3e7ec2;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.34); }
  @media only screen and (max-width: 768px) {
    .modTbl04 th {
      width: auto; } }

@media only screen and (max-width: 768px) {
  .modTbl04_sublease thead {
    display: none; } }

.modTbl04_sublease tbody td span {
  display: none; }
  @media only screen and (max-width: 768px) {
    .modTbl04_sublease tbody td span {
      display: inline-block;
      width: 7em;
      color: #3e7ec2;
      font-weight: bold; } }

@media only screen and (max-width: 768px) {
  .modTbl04_sublease tbody td .remarks {
    display: block;
    color: #575757;
    margin-bottom: 5px; } }

.modTbl05 th, .modTbl05 td {
  padding: 10px 10px !important;
  font-size: 13px;
  font-size: 1.3rem; }
  @media only screen and (max-width: 768px) {
    .modTbl05 th, .modTbl05 td {
      padding: 5px; } }

/* title
------------------------------------------------------------*/
.modPageTitle {
  text-align: center;
  border-bottom: 1px solid #0088d8;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  .modPageTitle:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  .modPageTitle .num {
    font-size: 40px;
    font-size: 4rem;
    float: left;
    color: rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-right: 20px;
    padding-right: 15px; }
    @media only screen and (max-width: 768px) {
      .modPageTitle .num {
        font-size: 30px;
        font-size: 3rem;
        margin-right: 5px; } }
  .modPageTitle .title {
    float: left; }
    @media only screen and (max-width: 768px) {
      .modPageTitle .title {
        float: none; } }
  .modPageTitle h2 {
    font-size: 26px;
    font-size: 2.6rem;
    color: #4a4a4a;
    font-weight: bold; }
    @media only screen and (max-width: 580px) {
      .modPageTitle h2 {
        font-size: 20px;
        font-size: 2rem; } }
  .modPageTitle span {
    font-size: 18px;
    font-size: 1.8rem;
    color: #227dd5;
    font-family: 'Lato', sans-serif; }
    @media only screen and (max-width: 580px) {
      .modPageTitle span {
        font-size: 16px;
        font-size: 1.6rem; } }

#main .modPageTitle {
  text-align: left;
  padding-bottom: 10px;
  /*
		h2,span {
			display:inline;
			@include sphoneP{
				display:block;
			}
		}
		h2 {
			margin-right:10px;
			@include sphoneP{
				margin-right:0;				
			}
		}
		*/ }
  @media only screen and (max-width: 580px) {
    #main .modPageTitle {
      margin-left: -10px;
      margin-right: -10px;
      text-align: center;
      padding-bottom: 20px; } }

.pageDetail .modPageTitle span {
  font-size: 15px;
  font-size: 1.5rem;
  color: #227dd5;
  font-family: "メイリオ", Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif; }
  @media only screen and (max-width: 580px) {
    .pageDetail .modPageTitle span {
      font-size: 13px;
      font-size: 1.3rem; } }

.modTitle01 {
  padding: 10px;
  margin-bottom: 30px;
  border-top: 2px solid #0069ce;
  background: url(../img/layout/bg.png) repeat top left;
  font-weight: bold;
  color: #0069ce;
  font-size: 17px;
  font-size: 1.7rem; }
  @media only screen and (max-width: 580px) {
    .modTitle01 {
      font-size: 16px;
      font-size: 1.6rem;
      margin-bottom: 20px; } }
  .modTitle01.title {
    padding: 10px; }

.modTitle02 {
  background: #3e7ec2;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }

.modTitle03 {
  border-top: 1px solid #3e7ec2;
  padding: 10px;
  color: #0069ce;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem; }

.-first {
  border-top: none; }

.modTitle04 {
  font-weight: bold;
  margin-bottom: 5px; }

.modTitle05 {
  background: #3e7ec2;
  color: #fff;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px; }

.modTitle05.fontSt_1 {
  font-size: 15px;
  font-size: 1.5rem; }

.modTitle06 {
  color: #0069ce;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media only screen and (max-width: 580px) {
    .modTitle06 {
      font-size: 14px;
      font-size: 1.4rem; } }

.modTitle07 {
  position: relative;
  background: #3e7ec2;
  padding: 20px;
  border-radius: 7px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px; }
  .modTitle07:after {
    content: "";
    position: absolute;
    bottom: -28px;
    left: 20px;
    border: 20px solid transparent;
    border-top: 20px solid #3e7ec2; }

.modTitle08 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #e60012;
  font-size: 18px;
  font-size: 1.8rem; }

.recruitTitle01 {
  margin-bottom: 20px;
  font-weight: bold;
  color: #377deb;
  text-align: center;
  font-size: 42px;
  font-size: 4.2rem; }
  @media only screen and (max-width: 768px) {
    .recruitTitle01 {
      font-size: 35px;
      font-size: 3.5rem; } }
  @media only screen and (max-width: 580px) {
    .recruitTitle01 {
      font-size: 20px;
      font-size: 2rem; } }

.recruitTitle02 {
  margin-bottom: 20px;
  color: #535353;
  text-align: center;
  line-height: 1.7;
  font-weight: bold;
  font-size: 25px;
  font-size: 2.5rem; }
  @media only screen and (max-width: 580px) {
    .recruitTitle02 {
      font-size: 18px;
      font-size: 1.8rem; }
      .recruitTitle02 br {
        display: none; } }

.renottaTitle01 {
  font-size: 28px;
  font-size: 2.8rem;
  padding: 20px 0;
  text-align: center; }
  @media only screen and (max-width: 580px) {
    .renottaTitle01 {
      font-size: 20px;
      font-size: 2rem;
      font-weight: bold; } }
  .renottaTitle01:after {
    content: "___";
    display: block; }
    @media only screen and (max-width: 580px) {
      .renottaTitle01:after {
        font-weight: normal; } }

.renottaTitle02 {
  font-size: 22px;
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-weight: bold; }
  @media only screen and (max-width: 580px) {
    .renottaTitle02 {
      font-size: 18px;
      font-size: 1.8rem; } }

.modLine01 {
  background: url(../img/contents/sublease/line.png) no-repeat bottom left;
  padding-bottom: 40px;
  margin-bottom: 30px; }

.modTitle09 {
  padding: 10px;
  padding-left: 0;
  color: #0069ce;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem; }

/* list
------------------------------------------------------------*/
.modList01 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .modList01 li {
    width: 24%;
    margin-right: 1.3%;
    margin-bottom: 20px; }
    .modList01 li:nth-child(4n) {
      margin-right: 0%; }
    .modList01 li a {
      text-decoration: none;
      display: block;
      border: 2px solid #3e7ec2;
      border-radius: 5px;
      height: 100%; }
    .modList01 li .icon,
    .modList01 li .title {
      display: block;
      text-align: center;
      min-height: 48px; }
    .modList01 li .icon {
      padding: 20px 0;
      background: #3e7ec2; }
    .modList01 li .title {
      padding: 10px 5px;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 0; }
    .modList01 li .sub {
      font-weight: normal;
      font-size: 14px;
      font-size: 1.4rem; }
      @media only screen and (max-width: 768px) {
        .modList01 li .sub {
          display: block; } }
  @media only screen and (max-width: 580px) {
    .modList01 {
      display: block; }
      .modList01 li {
        width: 100%;
        margin-bottom: 10px;
        padding: 0;
        display: table; }
        .modList01 li .icon,
        .modList01 li .title {
          display: table-cell;
          vertical-align: middle; }
          .modList01 li .icon br,
          .modList01 li .title br {
            display: none; }
        .modList01 li .icon {
          padding: 10px;
          width: 50px;
          text-align: center; }
          .modList01 li .icon img {
            height: 30px; }
        .modList01 li .title {
          padding: 10px;
          font-size: 14px;
          font-size: 1.4rem;
          text-align: left; } }

.modList02 {
  margin-bottom: 20px; }
  .modList02 li {
    margin-bottom: 5px;
    background: #f8f8f5;
    padding: 10px 15px;
    font-size: 16px;
    font-size: 1.6rem; }
    @media only screen and (max-width: 580px) {
      .modList02 li {
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 7px 10px; } }
    .modList02 li:before {
      content: "\f046";
      font-family: FontAwesome;
      margin-right: 10px;
      color: #e60012; }
    .modList02 li:last-child {
      margin-bottom: 0; }

.modList03 li {
  margin-bottom: 5px; }
  .modList03 li:before {
    content: "\f0da";
    font-family: FontAwesome;
    margin-right: 10px;
    color: #3e7ec2; }
  .modList03 li:last-child {
    margin-bottom: 0; }

.modShopList {
  width: 100%; }
  .modShopList:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  .modShopList .unit {
    width: 49%;
    border: 2px solid #c9c9c9;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 18px;
    box-sizing: border-box; }
    .modShopList .unit:nth-child(odd) {
      float: left; }
    .modShopList .unit:nth-child(even) {
      float: right; }
    @media only screen and (max-width: 580px) {
      .modShopList .unit {
        width: 100%;
        padding: 15px; } }
  .modShopList .hl {
    margin-bottom: 10px;
    color: #0069ce;
    font-size: 17px;
    font-size: 1.7rem;
    font-weight: bold; }
    @media only screen and (max-width: 580px) {
      .modShopList .hl {
        font-size: 15px;
        font-size: 1.5rem;
        margin-bottom: 5px; } }
  .modShopList .address th {
    font-weight: bold;
    padding: 2px 0;
    width: 5em; }
  .modShopList .address td {
    padding: 2px 0; }

.modPager {
  margin-top: 40px; }
  .modPager ul {
    font-size: 0;
    text-align: center; }
  .modPager li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    text-align: center;
    margin: 0 10px 10px 0; }
    .modPager li:last-child {
      margin-right: 0; }
  .modPager .num a, .modPager .prev a, .modPager .next a {
    border: solid 1px #B4B4B4;
    background-color: #fff;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box; }
  .modPager a {
    text-decoration: none; }
  .modPager a:hover {
    background-color: #F6F1E7; }
  .modPager .num a {
    display: block;
    width: 30px;
    color: #929292;
    font-size: 16px;
    font-size: 1.6rem; }
  .modPager .num.active a {
    color: #fff;
    background-color: #3e7ec2;
    border-color: #0069ce; }
  .modPager .prev a, .modPager .next a {
    display: block;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 16px;
    font-size: 1.6rem;
    color: #3e7ec2; }

.newsList .list {
  padding: 10px 0;
  border-bottom: 1px dotted #d2d2d2; }
  .newsList .list:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }

.newsList dt {
  float: left; }
  @media only screen and (max-width: 580px) {
    .newsList dt {
      float: none; } }

@media only screen and (max-width: 580px) {
  .newsList dd {
    margin-top: 5px;
    margin-left: 0; } }

.newsList .day {
  display: inline-block;
  color: #999;
  width: 8em;
  margin-right: 10px; }

.newsList .hl {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 10px; }

.newsList .text {
  width: 65%;
  float: right; }
  @media only screen and (max-width: 580px) {
    .newsList .text {
      width: 100%;
      float: none;
      margin-bottom: 10px; } }

.newsList .photo {
  width: 30%;
  float: left; }
  @media only screen and (max-width: 580px) {
    .newsList .photo {
      width: 100%;
      float: none;
      text-align: center; } }

.newsList .category {
  font-size: 10px;
  font-size: 1rem;
  display: inline-block;
  width: 8em;
  border-radius: 3px;
  margin-right: 10px;
  text-align: center;
  color: #fff; }
  .newsList .category_blue {
    background: #0088d8; }
  .newsList .category_orange {
    background: #f29022; }

.newsList ul {
  margin: 0 0 10px; }
  .newsList ul .day {
    width: auto; }
  .newsList ul li {
    display: inline-block; }

.staffList:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.staffList li {
  width: 23.8%;
  margin: 0 1.5% 10px 0;
  float: left;
  box-shadow: 0.618px 1.902px 2.97px 0.03px rgba(0, 0, 0, 0.1); }
  .staffList li:nth-child(4n) {
    margin-right: 0; }
  @media only screen and (max-width: 580px) {
    .staffList li {
      width: 48.8%; }
      .staffList li:nth-child(4n) {
        margin-right: 1.5%; }
      .staffList li:nth-child(even) {
        margin-right: 0; } }
  .staffList li a {
    display: block;
    padding: 25px 15px;
    text-align: center; }
    .staffList li a img {
      border-radius: 5px; }
    .staffList li a .photo,
    .staffList li a .shop,
    .staffList li a .name {
      display: block; }
    .staffList li a .photo {
      margin-bottom: 10px; }
    .staffList li a .shop {
      background-color: #ededed;
      border-radius: 5px;
      position: relative;
      margin: 5px 0 10px;
      padding: 2px 10px;
      display: inline-block; }
    .staffList li a .shop:after {
      border: 7px solid transparent;
      border-bottom-color: #ededed;
      border-top-width: 0;
      top: -7px;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      width: 0;
      margin-left: -7px; }
    .staffList li a .name {
      font-weight: bold;
      font-size: 13px;
      font-size: 1.3rem; }

.shopList:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

.shopList li {
  counter-increment: num;
  width: 48.6%;
  margin: 0 1.5% 15px 0;
  float: left;
  border: 2px solid #c9c9c9;
  border-radius: 5px;
  box-sizing: border-box; }
  .shopList li:nth-child(even) {
    margin-right: 0;
    float: right; }
  @media only screen and (max-width: 580px) {
    .shopList li {
      width: 100%;
      margin: 0 0 15px 0; } }

.shopList a {
  text-decoration: none; }

.shopList .title {
  background: #3e7ec2;
  color: #fff;
  padding: 10px 15px; }
  .shopList .title:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }
  .shopList .title h3 {
    font-size: 17px;
    font-size: 1.7rem;
    font-weight: bold;
    padding-top: 5px; }
    @media only screen and (max-width: 580px) {
      .shopList .title h3 {
        font-size: 15px;
        font-size: 1.5rem; } }
  .shopList .title h3:before {
    float: left;
    content: "0" counter(num);
    color: #FFF;
    padding-right: 10px;
    margin-right: 10px;
    margin-top: -4px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-size: 2.2rem; }
    @media only screen and (max-width: 580px) {
      .shopList .title h3:before {
        font-size: 17px;
        font-size: 1.7rem; } }

.shopList .photo {
  height: 170px;
  background-position: center center;
  background-size: 100% auto; }
  @media only screen and (max-width: 580px) {
    .shopList .photo {
      height: auto;
      float: left;
      width: 45%; } }

.shopList .address {
  margin: 10px 15px; }
  .shopList .address th {
    font-weight: bold; }
  @media only screen and (max-width: 580px) {
    .shopList .address {
      display: block;
      float: right;
      width: 54%;
      margin: 0;
      padding: 10px;
      box-sizing: border-box; }
      .shopList .address th, .shopList .address td {
        display: block; } }

.modContentsNavi {
  margin-bottom: 40px; }
  .modContentsNavi ul {
    display: table;
    width: 100%;
    -moz-box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.49);
    -webkit-box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.49);
    box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.49); }
  .modContentsNavi li {
    display: table-cell;
    text-align: center;
    border-right: 1px solid #DDDDDD; }
    .modContentsNavi li:last-child {
      border-right: none; }
  .modContentsNavi a {
    display: block;
    background: url(../img/mainvisual/bg.jpg) repeat top left;
    padding: 25px;
    font-size: 17px;
    font-size: 1.7rem; }
    @media only screen and (max-width: 768px) {
      .modContentsNavi a {
        padding: 15px 2px;
        font-size: 15px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 580px) {
      .modContentsNavi a {
        font-size: 13px;
        font-size: 1.3rem; } }
    .modContentsNavi a:hover {
      background: url(../img/mainvisual/bg_hover.jpg) repeat top left; }
  .modContentsNavi .on {
    position: relative; }
    .modContentsNavi .on a {
      background: none;
      background-color: #3e7ec2;
      color: #fff;
      font-weight: bold; }

.modListStep {
  text-align: center;
  margin-bottom: 30px; }
  .modListStep ul {
    letter-spacing: -.4em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 100%; }
    @media only screen and (max-width: 979px) {
      .modListStep ul {
        display: flex; } }
  .modListStep li {
    position: relative;
    letter-spacing: 0;
    background: #ddd;
    padding: 10px 5px 10px 5px;
    min-width: 100px;
    text-align: center; }
    @media only screen and (max-width: 979px) {
      .modListStep li {
        width: 25%;
        min-width: 50px;
        padding: 10px 10px 10px 20px;
        font-size: 12px;
        font-size: 1.2rem; } }
    .modListStep li::before, .modListStep li::after {
      content: " ";
      position: absolute;
      margin: 0 auto;
      top: -3px;
      bottom: 0;
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 0 23px 15px;
      letter-spacing: 0; }
      @media only screen and (max-width: 979px) {
        .modListStep li::before, .modListStep li::after {
          border-width: 23.5px 0 23.5px 12px; } }
    .modListStep li::before {
      right: -14px;
      border-color: transparent transparent transparent #fff; }
    .modListStep li::after {
      right: -12px;
      border-color: transparent transparent transparent #ddd; }
    .modListStep li[aria-selected="true"] {
      background: #3e7ec2;
      color: #FFF; }
      .modListStep li[aria-selected="true"]::after {
        border-color: transparent transparent transparent #3e7ec2; }
    .modListStep li:first-child {
      border-radius: 100px 0 0 100px; }
    .modListStep li:last-child {
      border-radius: 0 100px 100px 0;
      min-width: 105px; }
      @media only screen and (max-width: 979px) {
        .modListStep li:last-child {
          min-width: 50px; } }
      .modListStep li:last-child::before, .modListStep li:last-child::after {
        display: none; }

.modList-btn li {
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #c9c9c9;
  border-radius: 5px;
  box-sizing: border-box; }
  .modList-btn li a {
    text-decoration: none; }

.modList-btn .title {
  margin-bottom: 10px;
  color: #3e7ec2;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem; }

.modList-btn .txt {
  margin-bottom: 10px; }

.modList04 li {
  border-bottom: 1px solid #3e7ec2;
  padding: 20px 0;
  line-height: 1.8;
  padding-left: 2em;
  text-indent: -2em; }
  .modList04 li:last-child {
    border-bottom: none;
    margin-bottom: 30px; }
    @media only screen and (max-width: 580px) {
      .modList04 li:last-child {
        margin-bottom: 0; } }

.modList04__num {
  color: #0069ce;
  font-weight: bold;
  padding-right: 5px; }

/* width
------------------------------------------------------------*/
.cosW5, .cosW10, .cosW15, .cosW20, .cosW25, .cosW30, .cosW35, .cosW40, .cosW45, .cosW50, .cosW55, .cosW60, .cosW65, .cosW70, .cosW75, .cosW80, .cosW85, .cosW90, .cosW95, .cosW100, .cosW105, .cosW110, .cosW115, .cosW120, .cosW125, .cosW130, .cosW135, .cosW140, .cosW145, .cosW150, .cosW155, .cosW160, .cosW165, .cosW170, .cosW175, .cosW180, .cosW185, .cosW190, .cosW195, .cosW200, .cosW205, .cosW210, .cosW215, .cosW220, .cosW225, .cosW230, .cosW235, .cosW240, .cosW245, .cosW250, .cosW255, .cosW260, .cosW265, .cosW270, .cosW275, .cosW280, .cosW285, .cosW290, .cosW295, .cosW300, .cosW305, .cosW310, .cosW315, .cosW320, .cosW325, .cosW330, .cosW335, .cosW340, .cosW345, .cosW350, .cosW355, .cosW360, .cosW365, .cosW370, .cosW375, .cosW380, .cosW385, .cosW390, .cosW395, .cosW400, .cosW405, .cosW410, .cosW415, .cosW420, .cosW425, .cosW430, .cosW435, .cosW440, .cosW445, .cosW450, .cosW455, .cosW460, .cosW465, .cosW470, .cosW475, .cosW480, .cosW485, .cosW490, .cosW495, .cosW500, .cosW505, .cosW510, .cosW515, .cosW520, .cosW525, .cosW530, .cosW535, .cosW540, .cosW545, .cosW550, .cosW555, .cosW560, .cosW565, .cosW570, .cosW575, .cosW580, .cosW585, .cosW590, .cosW595, .cosW600, .cosW605, .cosW610, .cosW615, .cosW620, .cosW625, .cosW630, .cosW635, .cosW640, .cosW645, .cosW650, .cosW655, .cosW660, .cosW665, .cosW670, .cosW675, .cosW680, .cosW685, .cosW690, .cosW695, .cosW700, .cosW705, .cosW710, .cosW715, .cosW720, .cosW725, .cosW730, .cosW735, .cosW740, .cosW745, .cosW750, .cosW755, .cosW760, .cosW765, .cosW770, .cosW775, .cosW780, .cosW785, .cosW790, .cosW795, .cosW800, .cosW805, .cosW810, .cosW815, .cosW820, .cosW825, .cosW830, .cosW835, .cosW840, .cosW845, .cosW850, .cosW855, .cosW860, .cosW865, .cosW870, .cosW875, .cosW880, .cosW885, .cosW890, .cosW895 {
  max-width: 100%; }

.cosColorB {
  color: #0069ce; }
  .cosColorB a {
    color: #0069ce; }

.cosColorR {
  color: #e60012; }
  .cosColorR a {
    color: #e60012; }

.cosW100per {
  width: 100% !important; }

.cosW5 {
  width: 5px !important; }

.cosW10 {
  width: 10px !important; }

.cosW15 {
  width: 15px !important; }

.cosW20 {
  width: 20px !important; }

.cosW25 {
  width: 25px !important; }

.cosW30 {
  width: 30px !important; }

.cosW35 {
  width: 35px !important; }

.cosW40 {
  width: 40px !important; }

.cosW45 {
  width: 45px !important; }

.cosW50 {
  width: 50px !important; }

.cosW55 {
  width: 55px !important; }

.cosW60 {
  width: 60px !important; }

.cosW65 {
  width: 65px !important; }

.cosW70 {
  width: 70px !important; }

.cosW75 {
  width: 75px !important; }

.cosW80 {
  width: 80px !important; }

.cosW85 {
  width: 85px !important; }

.cosW90 {
  width: 90px !important; }

.cosW95 {
  width: 95px !important; }

.cosW100 {
  width: 100px !important; }

.cosW105 {
  width: 105px !important; }

.cosW110 {
  width: 110px !important; }

.cosW115 {
  width: 115px !important; }

.cosW120 {
  width: 120px !important; }

.cosW125 {
  width: 125px !important; }

.cosW130 {
  width: 130px !important; }

.cosW135 {
  width: 135px !important; }

.cosW140 {
  width: 140px !important; }

.cosW145 {
  width: 145px !important; }

.cosW150 {
  width: 150px !important; }

.cosW155 {
  width: 155px !important; }

.cosW160 {
  width: 160px !important; }

.cosW165 {
  width: 165px !important; }

.cosW170 {
  width: 170px !important; }

.cosW175 {
  width: 175px !important; }

.cosW180 {
  width: 180px !important; }

.cosW185 {
  width: 185px !important; }

.cosW190 {
  width: 190px !important; }

.cosW195 {
  width: 195px !important; }

.cosW200 {
  width: 200px !important; }

.cosW205 {
  width: 205px !important; }

.cosW210 {
  width: 210px !important; }

.cosW215 {
  width: 215px !important; }

.cosW220 {
  width: 220px !important; }

.cosW225 {
  width: 225px !important; }

.cosW230 {
  width: 230px !important; }

.cosW235 {
  width: 235px !important; }

.cosW240 {
  width: 240px !important; }

.cosW245 {
  width: 245px !important; }

.cosW250 {
  width: 250px !important; }

.cosW255 {
  width: 255px !important; }

.cosW260 {
  width: 260px !important; }

.cosW265 {
  width: 265px !important; }

.cosW270 {
  width: 270px !important; }

.cosW275 {
  width: 275px !important; }

.cosW280 {
  width: 280px !important; }

.cosW285 {
  width: 285px !important; }

.cosW290 {
  width: 290px !important; }

.cosW295 {
  width: 295px !important; }

.cosW300 {
  width: 300px !important; }

.cosW305 {
  width: 305px !important; }

.cosW310 {
  width: 310px !important; }

.cosW315 {
  width: 315px !important; }

.cosW320 {
  width: 320px !important; }

.cosW325 {
  width: 325px !important; }

.cosW330 {
  width: 330px !important; }

.cosW335 {
  width: 335px !important; }

.cosW340 {
  width: 340px !important; }

.cosW345 {
  width: 345px !important; }

.cosW350 {
  width: 350px !important; }

.cosW355 {
  width: 355px !important; }

.cosW360 {
  width: 360px !important; }

.cosW365 {
  width: 365px !important; }

.cosW370 {
  width: 370px !important; }

.cosW375 {
  width: 375px !important; }

.cosW380 {
  width: 380px !important; }

.cosW385 {
  width: 385px !important; }

.cosW390 {
  width: 390px !important; }

.cosW395 {
  width: 395px !important; }

.cosW400 {
  width: 400px !important; }

.cosW405 {
  width: 405px !important; }

.cosW410 {
  width: 410px !important; }

.cosW415 {
  width: 415px !important; }

.cosW420 {
  width: 420px !important; }

.cosW425 {
  width: 425px !important; }

.cosW430 {
  width: 430px !important; }

.cosW435 {
  width: 435px !important; }

.cosW440 {
  width: 440px !important; }

.cosW445 {
  width: 445px !important; }

.cosW450 {
  width: 450px !important; }

.cosW455 {
  width: 455px !important; }

.cosW460 {
  width: 460px !important; }

.cosW465 {
  width: 465px !important; }

.cosW470 {
  width: 470px !important; }

.cosW475 {
  width: 475px !important; }

.cosW480 {
  width: 480px !important; }

.cosW485 {
  width: 485px !important; }

.cosW490 {
  width: 490px !important; }

.cosW495 {
  width: 495px !important; }

.cosW500 {
  width: 500px !important; }

.cosW505 {
  width: 505px !important; }

.cosW510 {
  width: 510px !important; }

.cosW515 {
  width: 515px !important; }

.cosW520 {
  width: 520px !important; }

.cosW525 {
  width: 525px !important; }

.cosW530 {
  width: 530px !important; }

.cosW535 {
  width: 535px !important; }

.cosW540 {
  width: 540px !important; }

.cosW545 {
  width: 545px !important; }

.cosW550 {
  width: 550px !important; }

.cosW555 {
  width: 555px !important; }

.cosW560 {
  width: 560px !important; }

.cosW565 {
  width: 565px !important; }

.cosW570 {
  width: 570px !important; }

.cosW575 {
  width: 575px !important; }

.cosW580 {
  width: 580px !important; }

.cosW585 {
  width: 585px !important; }

.cosW590 {
  width: 590px !important; }

.cosW595 {
  width: 595px !important; }

.cosW600 {
  width: 600px !important; }

.cosW605 {
  width: 605px !important; }

.cosW610 {
  width: 610px !important; }

.cosW615 {
  width: 615px !important; }

.cosW620 {
  width: 620px !important; }

.cosW625 {
  width: 625px !important; }

.cosW630 {
  width: 630px !important; }

.cosW635 {
  width: 635px !important; }

.cosW640 {
  width: 640px !important; }

.cosW645 {
  width: 645px !important; }

.cosW650 {
  width: 650px !important; }

.cosW655 {
  width: 655px !important; }

.cosW660 {
  width: 660px !important; }

.cosW665 {
  width: 665px !important; }

.cosW670 {
  width: 670px !important; }

.cosW675 {
  width: 675px !important; }

.cosW680 {
  width: 680px !important; }

.cosW685 {
  width: 685px !important; }

.cosW690 {
  width: 690px !important; }

.cosW695 {
  width: 695px !important; }

.cosW700 {
  width: 700px !important; }

.cosW705 {
  width: 705px !important; }

.cosW710 {
  width: 710px !important; }

.cosW715 {
  width: 715px !important; }

.cosW720 {
  width: 720px !important; }

.cosW725 {
  width: 725px !important; }

.cosW730 {
  width: 730px !important; }

.cosW735 {
  width: 735px !important; }

.cosW740 {
  width: 740px !important; }

.cosW745 {
  width: 745px !important; }

.cosW750 {
  width: 750px !important; }

.cosW755 {
  width: 755px !important; }

.cosW760 {
  width: 760px !important; }

.cosW765 {
  width: 765px !important; }

.cosW770 {
  width: 770px !important; }

.cosW775 {
  width: 775px !important; }

.cosW780 {
  width: 780px !important; }

.cosW785 {
  width: 785px !important; }

.cosW790 {
  width: 790px !important; }

.cosW795 {
  width: 795px !important; }

.cosW800 {
  width: 800px !important; }

.cosW805 {
  width: 805px !important; }

.cosW810 {
  width: 810px !important; }

.cosW815 {
  width: 815px !important; }

.cosW820 {
  width: 820px !important; }

.cosW825 {
  width: 825px !important; }

.cosW830 {
  width: 830px !important; }

.cosW835 {
  width: 835px !important; }

.cosW840 {
  width: 840px !important; }

.cosW845 {
  width: 845px !important; }

.cosW850 {
  width: 850px !important; }

.cosW855 {
  width: 855px !important; }

.cosW860 {
  width: 860px !important; }

.cosW865 {
  width: 865px !important; }

.cosW870 {
  width: 870px !important; }

.cosW875 {
  width: 875px !important; }

.cosW880 {
  width: 880px !important; }

.cosW885 {
  width: 885px !important; }

.cosW890 {
  width: 890px !important; }

.cosW895 {
  width: 895px !important; }

/* margin,padding
------------------------------------------------------------*/
.mRLAuto {
  margin-left: auto;
  margin-right: auto; }

.cosMl0 {
  margin-left: 0px !important; }

.cosMl5 {
  margin-left: 5px !important; }

.cosMl10 {
  margin-left: 10px !important; }

.cosMl20 {
  margin-left: 20px !important; }

.cosMl30 {
  margin-left: 30px !important; }

.cosMl40 {
  margin-left: 40px !important; }

.cosMl50 {
  margin-left: 50px !important; }

.cosMl60 {
  margin-left: 60px !important; }

.cosMl70 {
  margin-left: 70px !important; }

.cosMl80 {
  margin-left: 80px !important; }

.cosMl90 {
  margin-left: 90px !important; }

.cosMl100 {
  margin-left: 100px !important; }

.cosMl110 {
  margin-left: 110px !important; }

.cosMl120 {
  margin-left: 120px !important; }

.cosMl130 {
  margin-left: 130px !important; }

.cosMl140 {
  margin-left: 140px !important; }

.cosMl150 {
  margin-left: 150px !important; }

.cosMl160 {
  margin-left: 160px !important; }

.cosMl170 {
  margin-left: 170px !important; }

.cosMl180 {
  margin-left: 180px !important; }

.cosMl190 {
  margin-left: 190px !important; }

.cosMl200 {
  margin-left: 200px !important; }

.cosMl210 {
  margin-left: 210px !important; }

.cosMl220 {
  margin-left: 220px !important; }

.cosMl230 {
  margin-left: 230px !important; }

.cosMl240 {
  margin-left: 240px !important; }

.cosMl250 {
  margin-left: 250px !important; }

.cosMl260 {
  margin-left: 260px !important; }

.cosMl270 {
  margin-left: 270px !important; }

.cosMl280 {
  margin-left: 280px !important; }

.cosMl290 {
  margin-left: 290px !important; }

.cosMl300 {
  margin-left: 300px !important; }

.cosMt0 {
  margin-top: 0px !important; }

.cosMt5 {
  margin-top: 5px !important; }

.cosMt10 {
  margin-top: 10px !important; }

.cosMt15 {
  margin-top: 15px !important; }

.cosMt20 {
  margin-top: 20px !important; }

.cosMt30 {
  margin-top: 30px !important; }

.cosMt40 {
  margin-top: 40px !important; }

.cosMt50 {
  margin-top: 50px !important; }

.cosMt60 {
  margin-top: 60px !important; }

.cosMt70 {
  margin-top: 70px !important; }

.cosMt80 {
  margin-top: 80px !important; }

.cosMt90 {
  margin-top: 90px !important; }

.cosMt100 {
  margin-top: 100px !important; }

.cosMt110 {
  margin-top: 110px !important; }

.cosMt120 {
  margin-top: 120px !important; }

.cosMt130 {
  margin-top: 130px !important; }

.cosMt140 {
  margin-top: 140px !important; }

.cosMt150 {
  margin-top: 150px !important; }

.cosMt160 {
  margin-top: 160px !important; }

.cosMt170 {
  margin-top: 170px !important; }

.cosMt180 {
  margin-top: 180px !important; }

.cosMt190 {
  margin-top: 190px !important; }

.cosMt200 {
  margin-top: 200px !important; }

.cosMt210 {
  margin-top: 210px !important; }

.cosMt220 {
  margin-top: 220px !important; }

.cosMt230 {
  margin-top: 230px !important; }

.cosMt240 {
  margin-top: 240px !important; }

.cosMt250 {
  margin-top: 250px !important; }

.cosMt260 {
  margin-top: 260px !important; }

.cosMt270 {
  margin-top: 270px !important; }

.cosMt280 {
  margin-top: 280px !important; }

.cosMt290 {
  margin-top: 290px !important; }

.cosMt300 {
  margin-top: 300px !important; }

.cosMr0 {
  margin-right: 0px !important; }

.cosMr5 {
  margin-right: 5px !important; }

.cosMr10 {
  margin-right: 10px !important; }

.cosMr20 {
  margin-right: 20px !important; }

.cosMr30 {
  margin-right: 30px !important; }

.cosMr40 {
  margin-right: 40px !important; }

.cosMr50 {
  margin-right: 50px !important; }

.cosMr60 {
  margin-right: 60px !important; }

.cosMr70 {
  margin-right: 70px !important; }

.cosMr80 {
  margin-right: 80px !important; }

.cosMr90 {
  margin-right: 90px !important; }

.cosMr100 {
  margin-right: 100px !important; }

.cosMr110 {
  margin-right: 110px !important; }

.cosMr120 {
  margin-right: 120px !important; }

.cosMr130 {
  margin-right: 130px !important; }

.cosMr140 {
  margin-right: 140px !important; }

.cosMr150 {
  margin-right: 150px !important; }

.cosMr160 {
  margin-right: 160px !important; }

.cosMr170 {
  margin-right: 170px !important; }

.cosMr180 {
  margin-right: 180px !important; }

.cosMr190 {
  margin-right: 190px !important; }

.cosMr200 {
  margin-right: 200px !important; }

.cosMr210 {
  margin-right: 210px !important; }

.cosMr220 {
  margin-right: 220px !important; }

.cosMr230 {
  margin-right: 230px !important; }

.cosMr240 {
  margin-right: 240px !important; }

.cosMr250 {
  margin-right: 250px !important; }

.cosMr260 {
  margin-right: 260px !important; }

.cosMr270 {
  margin-right: 270px !important; }

.cosMr280 {
  margin-right: 280px !important; }

.cosMr290 {
  margin-right: 290px !important; }

.cosMr300 {
  margin-right: 300px !important; }

.cosMb0 {
  margin-bottom: 0px !important; }

.cosMb5 {
  margin-bottom: 5px !important; }

.cosMb10 {
  margin-bottom: 10px !important; }

.cosMb15 {
  margin-bottom: 15px !important; }

.cosMb20 {
  margin-bottom: 20px !important; }

.cosMb30 {
  margin-bottom: 30px !important; }

.cosMb40 {
  margin-bottom: 40px !important; }

.cosMb50 {
  margin-bottom: 50px !important; }

.cosMb60 {
  margin-bottom: 60px !important; }

.cosMb70 {
  margin-bottom: 70px !important; }

.cosMb80 {
  margin-bottom: 80px !important; }

.cosMb90 {
  margin-bottom: 90px !important; }

.cosMb100 {
  margin-bottom: 100px !important; }

.cosMb110 {
  margin-bottom: 110px !important; }

.cosMb120 {
  margin-bottom: 120px !important; }

.cosMb130 {
  margin-bottom: 130px !important; }

.cosMb140 {
  margin-bottom: 140px !important; }

.cosMb150 {
  margin-bottom: 150px !important; }

.cosMb160 {
  margin-bottom: 160px !important; }

.cosMb170 {
  margin-bottom: 170px !important; }

.cosMb180 {
  margin-bottom: 180px !important; }

.cosMb190 {
  margin-bottom: 190px !important; }

.cosMb200 {
  margin-bottom: 200px !important; }

.cosMb210 {
  margin-bottom: 210px !important; }

.cosMb220 {
  margin-bottom: 220px !important; }

.cosMb230 {
  margin-bottom: 230px !important; }

.cosMb240 {
  margin-bottom: 240px !important; }

.cosMb250 {
  margin-bottom: 250px !important; }

.cosMb260 {
  margin-bottom: 260px !important; }

.cosMb270 {
  margin-bottom: 270px !important; }

.cosMb280 {
  margin-bottom: 280px !important; }

.cosMb290 {
  margin-bottom: 290px !important; }

.cosMb300 {
  margin-bottom: 300px !important; }

.cosPl0 {
  padding-left: 0px !important; }

.cosPl5 {
  padding-left: 5px !important; }

.cosPl10 {
  padding-left: 10px !important; }

.cosPl20 {
  padding-left: 20px !important; }

.cosPl30 {
  padding-left: 30px !important; }

.cosPl40 {
  padding-left: 40px !important; }

.cosPl50 {
  padding-left: 50px !important; }

.cosPl60 {
  padding-left: 60px !important; }

.cosPl70 {
  padding-left: 70px !important; }

.cosPl80 {
  padding-left: 80px !important; }

.cosPl90 {
  padding-left: 90px !important; }

.cosPl100 {
  padding-left: 100px !important; }

.cosPl110 {
  padding-left: 110px !important; }

.cosPl120 {
  padding-left: 120px !important; }

.cosPl130 {
  padding-left: 130px !important; }

.cosPl140 {
  padding-left: 140px !important; }

.cosPl150 {
  padding-left: 150px !important; }

.cosPl160 {
  padding-left: 160px !important; }

.cosPl170 {
  padding-left: 170px !important; }

.cosPl180 {
  padding-left: 180px !important; }

.cosPl190 {
  padding-left: 190px !important; }

.cosPl200 {
  padding-left: 200px !important; }

.cosPl210 {
  padding-left: 210px !important; }

.cosPl220 {
  padding-left: 220px !important; }

.cosPl230 {
  padding-left: 230px !important; }

.cosPl240 {
  padding-left: 240px !important; }

.cosPl250 {
  padding-left: 250px !important; }

.cosPl260 {
  padding-left: 260px !important; }

.cosPl270 {
  padding-left: 270px !important; }

.cosPl280 {
  padding-left: 280px !important; }

.cosPl290 {
  padding-left: 290px !important; }

.cosPl300 {
  padding-left: 300px !important; }

.cosPt0 {
  padding-top: 0px !important; }

.cosPt5 {
  padding-top: 5px !important; }

.cosPt10 {
  padding-top: 10px !important; }

.cosPt20 {
  padding-top: 20px !important; }

.cosPt30 {
  padding-top: 30px !important; }

.cosPt40 {
  padding-top: 40px !important; }

.cosPt50 {
  padding-top: 50px !important; }

.cosPt60 {
  padding-top: 60px !important; }

.cosPt70 {
  padding-top: 70px !important; }

.cosPt80 {
  padding-top: 80px !important; }

.cosPt90 {
  padding-top: 90px !important; }

.cosPt100 {
  padding-top: 100px !important; }

.cosPt110 {
  padding-top: 110px !important; }

.cosPt120 {
  padding-top: 120px !important; }

.cosPt130 {
  padding-top: 130px !important; }

.cosPt140 {
  padding-top: 140px !important; }

.cosPt150 {
  padding-top: 150px !important; }

.cosPt160 {
  padding-top: 160px !important; }

.cosPt170 {
  padding-top: 170px !important; }

.cosPt180 {
  padding-top: 180px !important; }

.cosPt190 {
  padding-top: 190px !important; }

.cosPt200 {
  padding-top: 200px !important; }

.cosPt210 {
  padding-top: 210px !important; }

.cosPt220 {
  padding-top: 220px !important; }

.cosPt230 {
  padding-top: 230px !important; }

.cosPt240 {
  padding-top: 240px !important; }

.cosPt250 {
  padding-top: 250px !important; }

.cosPt260 {
  padding-top: 260px !important; }

.cosPt270 {
  padding-top: 270px !important; }

.cosPt280 {
  padding-top: 280px !important; }

.cosPt290 {
  padding-top: 290px !important; }

.cosPt300 {
  padding-top: 300px !important; }

.cosPr0 {
  padding-right: 0px !important; }

.cosPr5 {
  padding-right: 5px !important; }

.cosPr10 {
  padding-right: 10px !important; }

.cosPr20 {
  padding-right: 20px !important; }

.cosPr30 {
  padding-right: 30px !important; }

.cosPr40 {
  padding-right: 40px !important; }

.cosPr50 {
  padding-right: 50px !important; }

.cosPr60 {
  padding-right: 60px !important; }

.cosPr70 {
  padding-right: 70px !important; }

.cosPr80 {
  padding-right: 80px !important; }

.cosPr90 {
  padding-right: 90px !important; }

.cosPr100 {
  padding-right: 100px !important; }

.cosPr110 {
  padding-right: 110px !important; }

.cosPr120 {
  padding-right: 120px !important; }

.cosPr130 {
  padding-right: 130px !important; }

.cosPr140 {
  padding-right: 140px !important; }

.cosPr150 {
  padding-right: 150px !important; }

.cosPr160 {
  padding-right: 160px !important; }

.cosPr170 {
  padding-right: 170px !important; }

.cosPr180 {
  padding-right: 180px !important; }

.cosPr190 {
  padding-right: 190px !important; }

.cosPr200 {
  padding-right: 200px !important; }

.cosPr210 {
  padding-right: 210px !important; }

.cosPr220 {
  padding-right: 220px !important; }

.cosPr230 {
  padding-right: 230px !important; }

.cosPr240 {
  padding-right: 240px !important; }

.cosPr250 {
  padding-right: 250px !important; }

.cosPr260 {
  padding-right: 260px !important; }

.cosPr270 {
  padding-right: 270px !important; }

.cosPr280 {
  padding-right: 280px !important; }

.cosPr290 {
  padding-right: 290px !important; }

.cosPr300 {
  padding-right: 300px !important; }

.cosPb0 {
  padding-bottom: 0px !important; }

.cosPb5 {
  padding-bottom: 5px !important; }

.cosPb10 {
  padding-bottom: 10px !important; }

.cosPb20 {
  padding-bottom: 20px !important; }

.cosPb30 {
  padding-bottom: 30px !important; }

.cosPb40 {
  padding-bottom: 40px !important; }

.cosPb50 {
  padding-bottom: 50px !important; }

.cosPb60 {
  padding-bottom: 60px !important; }

.cosPb70 {
  padding-bottom: 70px !important; }

.cosPb80 {
  padding-bottom: 80px !important; }

.cosPb90 {
  padding-bottom: 90px !important; }

.cosPb100 {
  padding-bottom: 100px !important; }

.cosPb110 {
  padding-bottom: 110px !important; }

.cosPb120 {
  padding-bottom: 120px !important; }

.cosPb130 {
  padding-bottom: 130px !important; }

.cosPb140 {
  padding-bottom: 140px !important; }

.cosPb150 {
  padding-bottom: 150px !important; }

.cosPb160 {
  padding-bottom: 160px !important; }

.cosPb170 {
  padding-bottom: 170px !important; }

.cosPb180 {
  padding-bottom: 180px !important; }

.cosPb190 {
  padding-bottom: 190px !important; }

.cosPb200 {
  padding-bottom: 200px !important; }

.cosPb210 {
  padding-bottom: 210px !important; }

.cosPb220 {
  padding-bottom: 220px !important; }

.cosPb230 {
  padding-bottom: 230px !important; }

.cosPb240 {
  padding-bottom: 240px !important; }

.cosPb250 {
  padding-bottom: 250px !important; }

.cosPb260 {
  padding-bottom: 260px !important; }

.cosPb270 {
  padding-bottom: 270px !important; }

.cosPb280 {
  padding-bottom: 280px !important; }

.cosPb290 {
  padding-bottom: 290px !important; }

.cosPb300 {
  padding-bottom: 300px !important; }

/* text
------------------------------------------------------------*/
.cosTxtBold {
  font-weight: bold !important; }

.cosTxtRight {
  text-align: right !important; }

.cosTxtCenter {
  text-align: center !important; }

.cosTxtLeft {
  text-align: left !important; }

.cosTxt10 {
  font-size: 10px !important;
  font-size: 1rem !important; }

.cosTxt11 {
  font-size: 11px !important;
  font-size: 1.1rem !important; }

.cosTxt12 {
  font-size: 12px !important;
  font-size: 1.2rem !important; }

.cosTxt13 {
  font-size: 13px !important;
  font-size: 1.3rem !important; }

.cosTxt14 {
  font-size: 14px !important;
  font-size: 1.4rem !important; }

.cosTxt16 {
  font-size: 16px !important;
  font-size: 1.6rem !important; }

.cosTxt18 {
  font-size: 18px !important;
  font-size: 1.8rem !important; }

.cosTxt20 {
  font-size: 20px !important;
  font-size: 2rem !important; }

.cosTxt30 {
  font-size: 30px !important;
  font-size: 3rem !important; }

/* grid
------------------------------------------------------------*/
.row {
  width: 100%;
  *zoom: 1;
  margin-bottom: 20px; }

.row:before,
.row:after {
  display: table;
  line-height: 0;
  content: ""; }

.row:after {
  clear: both; }

.row [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.row [class*="span"]:first-child {
  margin-left: 0; }

.row .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.127659574468085%; }

.row .span12 {
  width: 100%;
  *width: 99.94680851063829%; }

.row .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%; }

.row .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%; }

.row .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%; }

.row .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%; }

.row .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%; }

.row .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%; }

.row .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%; }

.row .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%; }

.row .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%; }

.row .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%; }

.row .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%; }

.row .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%; }

.row .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%; }

.row .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%; }

.row .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%; }

.row .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%; }

.row .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%; }

.row .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%; }

.row .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%; }

.row .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%; }

.row .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%; }

.row .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%; }

.row .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%; }

.row .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%; }

.row .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%; }

.row .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%; }

.row .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%; }

.row .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%; }

.row .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%; }

.row .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%; }

.row .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%; }

.row .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%; }

.row .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%; }

.row .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%; }

.row .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%; }

@media only screen and (max-width: 768px) {
  .row [class*="span"] {
    display: block;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }

@media only screen and (max-width: 768px) {
  .row .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }

@media only screen and (max-width: 768px) {
  .row [class*="offset"]:first-child {
    margin-left: 0; } }

.rowXs {
  width: 100%;
  *zoom: 1; }

.rowXs:before,
.rowXs:after {
  display: table;
  line-height: 0;
  content: ""; }

.rowXs:after {
  clear: both; }

.rowXs [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.rowXs [class*="span"]:first-child {
  margin-left: 0; }

.rowXs .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.127659574468085%; }

.rowXs .span12 {
  width: 100%;
  *width: 99.94680851063829%; }

.rowXs .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%; }

.rowXs .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%; }

.rowXs .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%; }

.rowXs .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%; }

.rowXs .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%; }

.rowXs .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%; }

.rowXs .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%; }

.rowXs .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%; }

.rowXs .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%; }

.rowXs .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%; }

.rowXs .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%; }

.rowXs .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%; }

.rowXs .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%; }

.rowXs .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%; }

.rowXs .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%; }

.rowXs .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%; }

.rowXs .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%; }

.rowXs .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%; }

.rowXs .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%; }

.rowXs .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%; }

.rowXs .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%; }

.rowXs .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%; }

.rowXs .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%; }

.rowXs .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%; }

.rowXs .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%; }

.rowXs .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%; }

.rowXs .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%; }

.rowXs .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%; }

.rowXs .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%; }

.rowXs .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%; }

.rowXs .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%; }

.rowXs .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%; }

.rowXs .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%; }

.rowXs .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%; }

.rowXs .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%; }

/* etc
------------------------------------------------------------*/
.cosHide {
  display: none; }

/*googleFont*/
