@charset "utf-8";

/* title
------------------------------------------------------------*/
.modPageTitle {
	@include clearfix;
	text-align:center;
	border-bottom:1px solid #0088d8;
	padding-bottom:20px;
	margin-bottom:20px;
	
	.num {
		@include fz(40);
		float:left;
		color:rgba(0, 0, 0, 0.2);
		border-right:1px solid rgba(0, 0, 0, 0.3);
		font-family: 'Lato', sans-serif;
		font-weight:bold;
		margin-right:20px;
		padding-right:15px;
		@include tabletP{
			@include fz(30);
			margin-right:5px;
		}				
	}
	.title {
		float:left;
		@include tabletP{
			float:none;
		}				
	}
	h2 {
		@include fz(26);
		color:#4a4a4a;
		font-weight:bold;
		@include sphoneP{
			@include fz(20)
		}
	}
	span {
		@include fz(18);
		color:#227dd5;
		font-family: 'Lato', sans-serif;
		@include sphoneP{
			@include fz(16)
		}
	}	
}
#main {
	.modPageTitle {
		text-align:left;
		padding-bottom:10px;
		@include sphoneP{
			margin-left:-10px;
			margin-right:-10px;			
			text-align:center;
			padding-bottom:20px;
		}
		/*
		h2,span {
			display:inline;
			@include sphoneP{
				display:block;
			}
		}
		h2 {
			margin-right:10px;
			@include sphoneP{
				margin-right:0;				
			}
		}
		*/
	}
}
.pageDetail {
	.modPageTitle {
		span {
			@include fz(15);
			color:#227dd5;
			font-family:$baseFontFamily;
			@include sphoneP{
				@include fz(13)
			}
		}	
	}
}
.modTitle01 {
	padding:10px;
	margin-bottom:30px;
	border-top:2px solid $mainColorB;
	background:url(../img/layout/bg.png) repeat top left;
	font-weight:bold;
	color:$mainColorB;
	@include fz(17);
	@include sphoneP{
		@include fz(16);
		margin-bottom:20px;
	}
	&.title {
		padding: 10px;
	}
}
.modTitle02 {
	background:#3e7ec2;
	padding:10px 15px;
	margin-bottom:15px;
	font-weight:bold;
	color:#fff;
	@include fz(14);
}

.modTitle03 {
	border-top:1px solid #3e7ec2;
	padding:10px;
	color:$mainColorB;
	font-weight:bold;
	@include fz(14);
}
.-first{
	border-top: none;
}

.modTitle04 {
	font-weight:bold;
	margin-bottom:5px;
}

.modTitle05 {
	background:rgb(62, 126, 194);
	color:#fff;
	padding:5px;
	border-radius:5px 5px 0 0 ;
	text-align:center;
	font-weight:bold;
	margin-bottom:10px;
}
.modTitle05.fontSt_1 {
	@include fz(15);
}
.modTitle06 {
	color:$mainColorB;
	font-weight:bold;
	margin-top:10px;
	margin-bottom:10px;
	@include fz(16);
	@include sphoneP{
		@include fz(14)
	}
}
.modTitle07 {
	position:relative;
	background:$mainColor;
	padding:20px;
	border-radius:7px;
	@include fz(16);
	font-weight:bold;
	color:#fff;
	margin-bottom:20px;

	&:after {
		content:"";
		position:absolute;
		bottom:-28px;
		left:20px;
		border:20px solid transparent;
		border-top:20px solid $mainColor;
	}
}
.modTitle08 {
	margin-bottom:10px;
	font-weight:bold;
	color:$mainColorR;
	@include fz(18);	
}

.recruitTitle01 {
	margin-bottom:20px;
	font-weight:bold;
  color: rgb(55, 125, 235);
	text-align:center;
	@include fz(42);
	@include tabletP{
		@include fz(35)
	}
	@include sphoneP{
		@include fz(20)
	}
}
.recruitTitle02 {
	margin-bottom:20px;
	color:#535353;
	text-align:center;
	line-height:1.7;
	font-weight:bold;
	@include fz(25);
	@include sphoneP{
		@include fz(18);
		br { display:none; }
	}
}

.renottaTitle01 {
	@include fz(28);
	padding:20px 0;
	text-align:center;
	@include sphoneP{
		@include fz(20);
		font-weight:bold;
	}
	&:after {
		content:"___";
		display:block;
		@include sphoneP{
			font-weight:normal;
		}
	}
}
.renottaTitle02 {
	@include fz(22);
	margin-bottom:20px;
	font-weight:bold;
	@include sphoneP{
		@include fz(18)
	}	
}

.modLine01 {
	background:url(../img/contents/sublease/line.png) no-repeat bottom left;
	padding-bottom:40px;
	margin-bottom:30px;
}
.modTitle09 {
	padding:10px;
	padding-left: 0;
	color:$mainColorB;
	font-weight:bold;
	@include fz(14);
}
