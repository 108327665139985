@charset "utf-8";

/* table
------------------------------------------------------------*/
.modTbl01{
	border:solid 1px #CCCCCC;
	table-layout:fixed;
	margin-bottom:30px;
	
	@include tabletP {
		display:block;
		margin-bottom:15px;
		border-bottom:none;
	}
	
	tbody,
	tr,
	th,
	td{
		@include tabletP {
			display:block;
		}
	}
	
	th,td{
		border-bottom:solid 1px #CCCCCC;
		padding:18px 30px;
		@include tabletP {
			padding:10px;
		}
	}
	th{
		background-color:#F4F4F4;
		width:170px;
		@include tabletP {
			width:auto;
		}
	}
	td{
	}
}


.modTbl02{
	table-layout:fixed;
	margin-bottom:30px;
	
	@include tabletP {
		display:block;
		margin-bottom:15px;
	}
	
	tbody,
	tr,
	th,
	td{
		@include tabletP {
			display:block;
		}
	}
	
	th,td{
		border-bottom:solid 2px #EAEAEA;
		padding:20px 15px;
		@include tabletP {
			padding:10px 0;
		}
	}
	th{
		width:220px;
		vertical-align:top;
		font-weight:bold;
		@include fz(15)	;
		@include tabletP {
			width:auto;
			border-bottom-style:dotted;
		}
	}
	
	td{
		@include fz(14);
	}
	&_title {
		th {
			width: 100%;
			padding: 0;
		}
		.modTitle02 {
			margin-bottom: 0;
		}			
	}
	&_title02 {
		th {
			width: 100%;
			padding: 0;
		}
		.modTitle02 {
			margin-bottom: 0;
		}			
	}
	&_title_bottom {
		border-bottom: 5px solid #3e7ec2;
	}
}

.modTbl03{
		
	tbody,
	tr,
	th,
	td{
		@include tabletP {
			display:block;
		}
	}
	
	th,td{
		border-bottom:solid 1px #CCCCCC;
		padding:15px 0px;
		@include tabletP {
			padding:10px 0;
		}
	}
	th{
		width:170px;
		color:$mainColorB;
		font-weight:bold;
		@include tabletP {
			width:auto;
		}
		@include sphoneP{
			border-bottom:none;
			padding-bottom:0;
		}
	}
	td{
	}
	tr:last-child {
		th,td {
			border-bottom:none;
		}
	}
}

.modTbl04{
		
	tbody,
	tr,
	th,
	td{
		@include tabletP {
			display:block;
		}
	}
	
	th,td{
		border:solid 1px #CCCCCC;
		padding:10px;
		@include tabletP {
			padding:10px 10px;
		}
	}
	th{
		width:100px;
		color:#fff;
		font-weight:bold;
		background:$mainColor;
		text-shadow:0 0 1px rgba(0,0,0,0.34);
		@include tabletP {
			width:auto;
		}
		@include sphoneP{
		}
	}
	td{
	}
}
.modTbl04_sublease {
	thead {
		@include tabletP{
			display:none;
		}
	}
	tbody {
		td {
			span {
				display:none;
				
				@include tabletP{
					display:inline-block;
					width:7em;
					color:$mainColor;
					font-weight:bold;
				}
			}
			.remarks{
				@include tabletP{
					display:block;
					color:rgba(87,87,87,1.00);
					margin-bottom:5px;
				}
			}
		}
			
	}
}

.modTbl05 {
	@extend .modTbl01;
	th,td{
		padding:10px 10px !important;
		@include fz(13);
		@include tabletP {
			padding:5px;
		}
	}
}