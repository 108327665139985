@charset "utf-8";
@import "../font_flat/flaticon.css";
@import "font-awesome.min.css";
@import "_setting";
@import "_mixin";
@import "_base";
@import "_layout";
@import "_responsive";
@import "_module";
@import "_mod_cms";
@import "_mod_btn";
@import "_mod_form";
@import "_mod_table";
@import "_mod_title";
@import "_mod_list";
@import "_cosmetic";

/*googleFont*/
@import url(https://fonts.googleapis.com/css?family=Lato:700);